export enum AdditionalClassesEnum {
	startImageView = 'startImageView',
	legacyTextView = 'legacyTextView',
	logoPosition = 'logoPosition',
	addLogoVisibility = 'addLogoVisibility',
	startTitlePlace = 'startTitlePlace',
	startSubtitlePlace = 'startSubtitlePlace',
	resultMediaPosition = 'resultMediaPosition',
	mainLogoVisibility = 'mainLogoVisibility',
}
