import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { RoutingService } from 'src/app/services/routing.service';
import { EventActions } from '../../../../../../models/event-data.model';
import { selectActiveTheme } from '../../../../../../store/selectors/active-theme.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GeneralPropsEnum } from '../../../../../../models/general-props.enum';
import { StepsEnum } from '../../../../../../enums/steps.enum';
import { EventsService } from 'src/app/services/events.service';

@UntilDestroy()
@Component({
	templateUrl: './state-not-allowed.component.html',
})
export class StateNotAllowedComponent implements OnInit {
	EventActions = EventActions;
	subtitle: string;
	title: string;
	button: string;
	url: string;
	phoneNumber: string;
	callAgentButtonOn: boolean;

	constructor(private readonly store: Store, readonly routingService: RoutingService, private eventsService: EventsService) { }

	ngOnInit(): void {
		this.store
			.select(selectActiveTheme)
			.pipe(untilDestroyed(this))
			.subscribe((theme) => {
				this.phoneNumber = theme[GeneralPropsEnum.agentPhoneNumber];
				this.callAgentButtonOn = theme[GeneralPropsEnum.texts][StepsEnum.STATE_NOT_ALLOWED].callAgentButtonOn;
				this.url = theme[GeneralPropsEnum.texts][StepsEnum.STATE_NOT_ALLOWED].callAgentButtonUrl;
			});
	}

	handleClick(): void {
		if (this.url?.length) {
			this.routingService.navigateToExternal(this.url, true);
			return;
		}

		this.routingService.navigateToExternal(`tel:${this.phoneNumber}`);
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, { label: 'call-to-agent', button: 'call-to-agent', module: 'call-agent-module' });
	}
}
