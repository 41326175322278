import { Component } from '@angular/core';
import { RepeatedTextModel } from '../../../../../../../models/landing-section.model';
import { GeneralPropsEnum } from '../../../../../../../models/general-props.enum';
import { SectionBaseComponent } from '../section-base.component';

@Component({
	selector: 'app-statistic',
	templateUrl: './statistic.component.html',
	styleUrls: ['./statistic.component.scss'],
	inputs: ['section'],
})
export class StatisticComponent extends SectionBaseComponent {
	constructor() {
		super();
	}

	get statList(): RepeatedTextModel[] {
		return this.section[GeneralPropsEnum.repeatedTexts];
	}

	getText(item: { text: string }): string {
		if (item.hasOwnProperty(`text${this.lang}`)) {
			return item[`text${this.lang}`];
		}

		return item.text;
	}
}
