<section class="main-content main-content-vehicle-cover">
	<form #myform="ngForm" [formGroup]="formVehicle" class="applicant-form stage">
		<div class="questions-container">
			<div class="header-vehicle">
				<img
					alt="{{ form?.value?.PLMake }} logo"
					class="car-logo"
					src="assets/images/car-logos/thumb/{{ getVehicleLogoName(formVehicle?.value?.PLMake) }}.png"
				/>
				<p class="name">
					{{ formVehicle.value.PLYear }} {{ formVehicle.value.PLMake }} {{ formVehicle.value.PLModel }}
				</p>
			</div>

			<app-question-wrapper
				[show]="form.get('CompDeductible') && form.get('CompDeductible').enabled"
				class="CompDeductible"
			>
				<app-dropdown
					[options]="FieldOptions.CompDeductibleOptions"
					[reAlphabeticalOrder]="false"
					[labelInsideField]="'labels.comprehensiveDeductible' | translate"
					name="CompDeductible"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get('CollDeductible') && form.get('CollDeductible').enabled"
				class="CollisionDed"
			>
				<app-dropdown
					[disabled]="form.get('CompDeductible').value == 'NoCoverage'"
					[options]="FieldOptions.CollDeductibleOptions"
					[reAlphabeticalOrder]="false"
					[labelInsideField]="'labels.collisionDeductible' | translate"
					name="CollDeductible"
				></app-dropdown>
			</app-question-wrapper>
		</div>
	</form>
</section>
