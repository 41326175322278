import { Component, Injector, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { ControlsType } from 'src/app/constants/controls-type';
import { ActionApiService } from 'src/app/services/action-api.service';
import { NgOnDestroyService } from 'src/app/services/ng-on-destroy.service';
import { IAppState } from 'src/app/store/states/app.state';
import { ISingleAddressClient } from 'src/entities/address-response.interface';
import { BaseFormControlComponent } from '../base-form-control.component';
import { IDropdownOption } from '../form-control-interfaces';
import { EventsService } from 'src/app/services/events.service';

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss'],
})
export class AddressComponent extends BaseFormControlComponent implements OnInit {
	@Input() addressLine1: string;
	@Input() addressLine2: string;
	@Input() city: string;
	@Input() state: string;
	@Input() zipCode: string;
	@Input() county: string;
	@Input() label: string;

	hiddenAddrErrors: string[] = [];
	addressFields: string[] = [];
	suggestedAddresses: ISingleAddressClient[];

	focus$: Subject<boolean> = new Subject<boolean>();
	focus: boolean;

	constructor(
		injector: Injector,
		private actionApi: ActionApiService,
		protected store: Store<IAppState>,
		private destroy: NgOnDestroyService,
		eventsService: EventsService
	) {
		super(injector, store, eventsService);
	}

	ngOnInit(): void {
		this.controlType = ControlsType.address;
		this.addressFields.push(this.addressLine1);
		this.addressFields.push(this.addressLine2);
		this.addressFields.push(this.city);
		this.addressFields.push(this.state);
		this.addressFields.push(this.zipCode);
	}

	focusChange(focus: boolean) {
		this.focus$.next(focus);
		this.focus = focus;
	}
}
