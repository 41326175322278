<ng-container *ngFor="let rate of rates">
	<ng-container *ngIf="rate.lob !== 'Flood'">
		<ng-container *ngTemplateOutlet="rateSection; context: { rate: rate }"></ng-container>
	</ng-container>
</ng-container>

<ng-template #rateSection let-rate="rate">
	<div *ngIf="rate.isSelected" class="coverages-wrapper">
		<h3 class="coverage-section-title">{{ "lobs." + rate.lob | translate }} {{ "labels.coverage" | translate }}</h3>

		<ng-container *ngIf="!!rate.coverages.policyLevelCoverages">
			<div class="table">
				<ul [attr.aria-label]="'labels.policyCoverages' | translate" class="tr">
					<ng-container
						*ngFor="let coverage of filterCoverages(reArrangeArray(rate.coverages.policyLevelCoverages), rate.lob)"
					>
						<ng-container
							*ngTemplateOutlet="
								coverageline;
								context: {
									coverage,
									coverageName: getCoverageTexts(coverage, rate.lob),
									coveragePrice: getCoveragePrice(coverage)
								}
							"
						></ng-container>
					</ng-container>
				</ul>
			</div>
		</ng-container>

		<!--  Additional Coverages Section -->

		<ng-container *featureFlag="'homeownersAdditionalCoverage'">
			<ng-container
				*ngIf="
					showAdditionalCoveragesSection &&
					!!rate.coverages.policyLevelCoverages &&
					filterAdditionalCoverages(rate.coverages.policyLevelCoverages, rate.lob).length &&
					filterAdditionalCoverages(rate.coverages.policyLevelCoverages, rate.lob)
				"
			>
				<h3 class="coverage-section-title">{{ "titles.additionalCoverages" | translate }}</h3>
				<div class="table">
					<ul [attr.aria-label]="'labels.policyCoverages' | translate" class="tr">
						<ng-container
							*ngFor="let coverage of filterAdditionalCoverages(rate.coverages.policyLevelCoverages, rate.lob)"
						>
							<ng-container
								*ngTemplateOutlet="
									coverageline;
									context: {
										coverage,
										coverageName: getCoverageTexts(coverage, rate.lob),
										coveragePrice: getCoveragePrice(coverage),
										additional: true
									}
								"
							></ng-container>
						</ng-container>
					</ul>
				</div>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="!!rate.coverages.entityCoverages">
			<br />
			<div
				*ngFor="
					let coveragesForEntity of getEntityCoveragesArray(rate.lob, rate.coverages.entityCoverages);
					let index = index
				"
				class="table entity-coverages-wrapper"
			>
				<ul attr.aria-label="{{ 'lobs.' + rate.lob | translate }} {{ 'labels.coverage' | translate }}" class="tr">
					<div class="entity-header">
						<img
							*ngIf="rate.lob === 'PersonalAuto'"
							alt="{{ cars[index].PLMake }} logo"
							class="car-logo"
							src="assets/images/car-logos/thumb/{{ getVehicleLogoName(cars[index].PLMake) }}.png"
						/>
						<img
							*ngIf="rate.lob === 'Pets'"
							alt="{{ pets[index].PLPetSpecies }}"
							class="pet-logo"
							src="assets/icons/pets-{{ pets[index].PLPetSpecies?.toLowerCase() }}-small-checked.svg"
						/>
						<div class="entity-title">
							<div [class.pet-name]="rate.lob === 'pets'" class="entity-name">
								{{ getEntityTitle(rate.lob, index) }}
							</div>
							<div *ngIf="rate.lob === 'PersonalAuto' && cars[index].VIN" class="vin">
								{{ cars[index].VIN }}
							</div>
						</div>
					</div>

					<ng-container *ngFor="let coverage of coveragesForEntity">
						<ng-container
							*ngTemplateOutlet="
								coverageline;
								context: {
									coverage,
									coverageName: getCoverageTexts(coverage, rate.lob),
									coveragePrice: getCoveragePrice(coverage)
								}
							"
						></ng-container>
					</ng-container>
				</ul>
			</div>
		</ng-container>
		<ng-template
			#coverageline
			let-coverage="coverage"
			let-coverageName="coverageName"
			let-coveragePrice="coveragePrice"
		>
			<li *ngIf="coverage.value !== undefined && coverageName" class="td">
				<div class="name">{{ coverageName.displayName | translate }}</div>
				<div class="dots"></div>
				<div class="price">
					{{ isValueNaN(coveragePrice) ? coveragePrice : (coveragePrice | currency: "USD":"symbol":"1.0-0") }}
				</div>

				<button
					[attr.aria-label]="(coverageName.displayName | translate) + ' description:'"
					[class.hide]="!(coverageName.description | translate)"
					[class.invisible-icon]="!coverageName.description"
					[matTooltip]="coverageName.description | translate"
					appHiddenWhenCssDisabled
					class="help-icon material-icons"
					appToggleTooltip
				>
					<span aria-hidden="true">help_outline</span>
				</button>
			</li>
		</ng-template>

		<ng-container *ngIf="rate.discounts">
			<ng-container *ngTemplateOutlet="discountsSection"></ng-container>
		</ng-container>
	</div>
</ng-template>

<ng-template #discountsSection>
	<app-discounts *ngIf="discounts" [discounts]="discounts"></app-discounts>
</ng-template>
