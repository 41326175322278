<app-error imageName="noRates" subtitleKey="no-rates.subTitle" titleKey="no-rates.title">
	<a
		*ngIf="callAgentButtonOn ? true : isMobile"
		[eventData]="{
			action: eventActions.BUTTON_CLICK,
			label: 'call-agent',
			button: 'call-agent',
			modal: 'no-rates-error'
		}"
		[href]="callAgentButtonUrl ? callAgentButtonUrl : 'tel:' + agentPhoneNumber"
		appClickEvent
		class="app-button call-agent-button"
		tabindex="0"
	>
		<span class="title">
			<span appTranslation="no-rates.callAgentButtonText"></span>
			<i appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
		</span>
	</a>
</app-error>
