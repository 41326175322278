<app-error
	[showFriendlyId]="true"
	imageName="stateNotAllowed"
	subtitleKey="state-not-allowed.subTitle"
	titleKey="state-not-allowed.title"
>
	<section [class.showOnlyOnMobile]="!callAgentButtonOn">
		<app-button (clicked)="handleClick()" *ngIf="callAgentButtonOn" titleKey="state-not-allowed.callAgentButtonText">
		</app-button>
	</section>
</app-error>
