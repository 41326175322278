<form [formGroup]="controlContainer.control" class="yes-no-wrapper" [ngClass]="{ oneline: vertical }">
	<mat-button-toggle-group
		(focusout)="markAsTouched()"
		[attr.aria-labelledby]="name"
		[attr.id]="name"
		[attr.name]="name"
		[disabled]="tabindex == -1 || disabled"
		[formControlName]="name"
		[ngClass]="{ disabled: disabled, error: isInvalid() && !(isFocus$ | async) }"
		[hideSingleSelectionIndicator]="true"
	>
		<mat-button-toggle
			[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
			[attr.aria-invalid]="isInvalid() || null"
			[value]="trueValue"
		>
			<span
				*ngIf="controlContainer.control.controls[name].value === trueValue"
				class="no-css-button-indicator"
				role="presentation"
			>
				&#10004;&nbsp;
			</span>
			{{ "labels.yes" | translate }}
		</mat-button-toggle>

		<mat-button-toggle
			[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
			[attr.aria-invalid]="isInvalid() || null"
			[value]="falseValue"
		>
			<span
				*ngIf="controlContainer.control.controls[name].value === falseValue"
				class="no-css-button-indicator"
				role="presentation"
			>
				&#10004;&nbsp;
			</span>
			{{ "labels.no" | translate }}
		</mat-button-toggle>
	</mat-button-toggle-group>
</form>

<error-message
	[describedByErrorId]="describedByErrorId"
	[fieldDisplayName]="postfix || name"
	[field]="controlContainer.control.controls[name]"
	[isFocus$]="isFocus$"
>
</error-message>
