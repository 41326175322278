<section class="preview {{ section.name }}">
	<div class="content">
		<app-image
			*ngIf="section.images.carrierTitleImage"
			[landingSection]="section"
			alt="null"
			class="title-image"
			name="carrierTitleImage"
			role="presentation"
		></app-image>

		<h1 appTranslation="carriers.title"></h1>
		<p appTranslation="carriers.subTitle"></p>

		<ul class="car-list">
			<li *ngFor="let image of carList">
				<ng-template #appImage>
					<app-image
						[alt]="image.alt"
						[name]="image.name"
						classNames="presentation-image"
						role="presentation"
					></app-image>
				</ng-template>

				<a *ngIf="image.url" [attr.href]="image.url">
					<ng-container *ngTemplateOutlet="appImage"></ng-container>
				</a>

				<ng-container *ngIf="!image.url">
					<ng-container *ngTemplateOutlet="appImage"></ng-container>
				</ng-container>
			</li>
		</ul>
	</div>
</section>
