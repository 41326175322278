export enum CssPropertiesEnum {
	fontFamily = '--font-family',
	background = '--background',
	primaryColor = '--primary-color',
	secondaryColor = '--secondary-color',

	headerHeight = '--header-height',
	headerBoxShadow = '--header-box-shadow',
	headerBackground = '--header-background',
	headerButtonBackground = '--header-button-background',
	headerButtonColor = '--header-button-color',
	headerButtonBorder = '--header-button-border',
	headerAgentColor = '--header-agent-color',

	progressBarColor = '--progress-bar-color',
	progressBarBackground = '--progress-bar-background',
	progressBarHeight = '--progress-bar-height',

	logoHeight = '--logo-height',
	logoWidth = '--logo-width',

	loaderColor = '--loader-color',
	loaderTextColor = '--loader-text-color',

	footerCopyright = '--footer-copyright',
	errorColor = '--error',

	mainTitleColor = '--main-title-color',
	mainTitleColor2 = '--main-title2-color',
	mainTitleWeight = '--main-title-weight',
	mainTitleFont = '--main-title-font-family',

	buttonBorderRadius = '--button-border-radius',
	buttonFontSize = '--button-font-size',
	buttonIconVisibility = '--button-icon-visibility',
	buttonHeight = '--button-height',
	buttonWidth = '--button-width',
	buttonBackground = '--button-background',
	buttonBackgroundHover = '--button-background-hover',
	buttonColor = '--button-color',
	buttonColorHover = '--button-color-hover',
	buttonIconColor = '--button-icon-color',
	buttonIconColorHover = '--button-icon-color-hover',
	buttonBorderColor = '--button-border-color',
	button2Background = '--button2-background',
	button2BackgroundHover = '--button2-background-hover',
	button2Color = '--button2-color',
	button2ColorHover = '--button2-color-hover',
	button2IconColor = '--button2-icon-color',
	button2IconColorHover = '--button2-icon-color-hover',
	button2BorderColor = '--button2-border-color',

	linkColor = '--link-color',

	inputBackground = '--input-background',
	inputBackgroundFocus = '--input-background-focus',
	inputBackgroundSelect = '--input-background-select',
	inputColor = '--input-color',
	inputColorSelect = '--input-color-select',
	inputColorDisabled = '--input-color-disabled',
	inputBorderRadius = '--input-border-radius',
	inputBorderColor = '--input-border-color',
	inputBorderWidth = '--input-border-width',
	inputShadowFocus = '--input-shadow-focus',
	inputIconColor = '--input-icon-color',
	inputSecondaryColor = '--input-secondary-color',
	inputSecondaryColorHover = '--input-secondary-color-hover',
	inputPlaceholder = '--input-placeholder',
	inputLabel = '--input-label',

	questionShadow = '--question-shadow',
	questionPadding = '--question-padding',
	questionRadius = '--question-radius',

	selectOptionColor = '--select-option-color',
	selectOptionColorSelected = '--select-option-color-selected',
	selectCheckColor = '--select-check-color',
	selectOptionBackground = '--select-option-background',
	selectOptionBackgroundHover = '--select-option-background-hover',

	radioSelectColor = '--radio-select-color',
	radioImageBorderWidth = '--radio-image-border-width',
	radioImageBorderRadius = '--radio-image-border-radius',
	radioImageBorderColor = '--radio-image-border-color',
	radioImageBorderColorChecked = '--radio-image-border-color-checked',

	startPageBg = '--start-page-bg',
	startPageFormBg = '--start-page-form-bg',
	startPageFormLegal = '--start-page-form-legal',
	startPageFormRadius = '--start-page-form-radius',
	startPageTitleColor = '--start-page-title-color',
	startPageTitleWeight = '--start-page-title-weight',
	startPageImageVisibility = '--start-page-image-display',
	startInputsShadow = '--start-page-input-shadow',
	startInputsBg = '--start-page-input-bg',
	startInputsColor = '--start-page-input-color',

	popupRadius = '--popup-radius',
	popupHeaderBg = '--popup-header-bg',
	popupHeaderColor = '--popup-header-color',
	popupBg = '--popup-bg',
	popupColor = '--popup-color',
	popupCloseBg = '--popup-close-bg',
	popupCloseColor = '--popup-close-color',

	legalText = '--legal-text',
	testimSep = '--lp-testim-sep',

	videoInitialVolume = '0.0',
}
