import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Injector,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ControlsType } from 'src/app/constants/controls-type';
import { IAppState } from 'src/app/store/states/app.state';
import { BaseFormControlComponent } from '../base-form-control.component';
import { EventsService } from 'src/app/services/events.service';

@Component({
	selector: 'number-stepper',
	templateUrl: './number-stepper.component.html',
	styleUrls: ['./number-stepper.component.scss'],
})
export class NumberStepperComponent extends BaseFormControlComponent implements OnInit, AfterViewInit {
	@Input() maxValue: number;
	@Input() minValue: number;
	@ViewChild('minus', { static: true, read: ElementRef }) minus;
	@ViewChild('plus', { static: true, read: ElementRef }) plus;

	invalid: boolean;

	constructor(
		injector: Injector,
		private cdRef: ChangeDetectorRef,
		protected store: Store<IAppState>,
		eventsService: EventsService
	) {
		super(injector, store, eventsService);
	}

	ngOnInit() {
		this.setValue();
		this.controlType = ControlsType.numberStepper;
	}

	ngAfterViewInit() {
		this.baseNgAfterViewInit();
		this.controlContainer.control.controls[this.name].setValue(this.value);
		this.cdRef.detectChanges();
	}

	setValue(): void {
		this.value = this.controlContainer.control.controls[this.name].value
			? this.controlContainer.control.controls[this.name].value
			: this.minValue
				? this.minValue
				: 0;
	}

	increment() {
		this.controlContainer.control.controls[this.name].markAsTouched();
		if (!this.maxValue || this.value < this.maxValue) {
			this.value++;
			this.controlContainer.control.controls[this.name].setValue(this.value);
		}
	}

	decrement() {
		this.controlContainer.control.controls[this.name].markAsTouched();
		if (this.value >= 1 && (!this.minValue || this.value > this.minValue)) {
			this.value--;
			this.controlContainer.control.controls[this.name].setValue(this.value);
		}
	}

	onBlur(event) {
		super.baseOnBlur();
		event.currentTarget.setAttribute('blured', true);
		if (this.minus.nativeElement.hasAttribute('blured') && this.plus.nativeElement.hasAttribute('blured')) {
			const field = this.controlContainer.control.controls[this.name];
			field.markAsTouched();

			setTimeout(() => {
				this.invalid = field.status === 'INVALID' || field.touched;
			}, 1);
		}
	}

	onFocus(event) {
		super.baseOnFocus();
	}

	isInvalid() {
		const field = this.controlContainer.control.controls[this.name];
		if (!field) {
			return false;
		}
		return field.invalid && field.touched;
	}

	disablePlusButtom() {
		return this.maxValue ? this.maxValue > this.value : true;
	}
}
