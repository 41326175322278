import { Component, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { RoutingService } from 'src/app/services/routing.service';
import { EventActions } from '../../../../../../models/event-data.model';
import { selectActiveTheme } from '../../../../../../store/selectors/active-theme.selector';
import { first } from 'rxjs/operators';
import { LobService } from 'src/app/services/lob.service';
import { GeneralPropsEnum } from '../../../../../../models/general-props.enum';
import { StepsEnum } from '../../../../../../enums/steps.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { EventsService } from 'src/app/services/events.service';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
	selector: 'app-carrier-kickout',
	templateUrl: './carrier-kickout.component.html',
	styleUrls: ['./carrier-kickout.component.scss'],
})
export class CarrierKickoutComponent implements OnInit {
	EventActions = EventActions;
	phoneNumber: string;
	lob: string;
	title: string;
	subtitle: string;
	LobsEnum = LobsEnum;
	backButtonOn: boolean;
	backButtonUrl: string;
	callAgentButtonOn: boolean;
	callAgentButtonUrl: string;
	imageName: string;

	constructor(
		public routingService: RoutingService,
		private store: Store,
		private getLobService: LobService,
		private eventsService: EventsService,
		private route: ActivatedRoute
	) {
		this.imageName = this.route.snapshot.data?.imageName;
	}

	ngOnInit(): void {
		this.store
			.select(selectActiveTheme)
			.pipe(untilDestroyed(this))
			.subscribe((theme) => {
				this.title = theme[GeneralPropsEnum.texts][StepsEnum.CARRIER_KICKOUT].title;
				this.subtitle = theme[GeneralPropsEnum.texts][StepsEnum.CARRIER_KICKOUT].subTitle;
				this.backButtonOn = theme[GeneralPropsEnum.texts][StepsEnum.CARRIER_KICKOUT].backButtonOn;
				this.backButtonUrl = theme[GeneralPropsEnum.texts][StepsEnum.CARRIER_KICKOUT].backButtonUrl;
				this.callAgentButtonOn = theme[GeneralPropsEnum.texts][StepsEnum.CARRIER_KICKOUT].callAgentButtonOn;
				this.callAgentButtonUrl = theme[GeneralPropsEnum.texts][StepsEnum.CARRIER_KICKOUT].callAgentButtonUrl;
			});

		this.getLobService
			.getLob$()
			.pipe(first())
			.subscribe((lob) => {
				this.lob = lob;

				if (this.lob !== LobsEnum.PERSONAL_AUTO) {
					this.subtitle += ` Call <%agent_number%> to speak with a licensed agent.`;
				}
			});
	}

	handleCallAgent() {
		if (this.callAgentButtonUrl) {
			this.routingService.navigateToExternal(this.callAgentButtonUrl, true);
		} else {
			this.routingService.callTo(this.phoneNumber);
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'call-to-agent',
			button: 'call-to-agent',
			module: 'call-agent-module',
		});
	}

	handleBackToQuote() {
		if (this.backButtonUrl) {
			this.routingService.navigateToExternal(this.backButtonUrl, true);
		} else {
			window.history.back();
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'go-back',
			button: 'go-back-carrier-kickout',
			module: 'carrier-kickout-module',
		});
	}
}
