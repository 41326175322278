import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { RoutingService } from 'src/app/services/routing.service';
import { EventActions } from '../../../../../../models/event-data.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isMobile } from 'src/app/utils/general.utils';
import { selectActiveTheme } from '../../../../../../store/selectors/active-theme.selector';
import { GeneralPropsEnum } from '../../../../../../models/general-props.enum';
import { StepsEnum } from '../../../../../../enums/steps.enum';
import { EventsService } from 'src/app/services/events.service';

@UntilDestroy()
@Component({
	templateUrl: './agent-locked.component.html',
})
export class AgentLockedComponent implements OnInit {
	EventActions = EventActions;
	title: string;
	subtitle: string;
	agentPhoneNumber: string;
	isMobile: boolean;
	callAgentButtonOn: any;
	callAgentButtonText: any;
	callAgentButtonUrl: string;

	constructor(private readonly store: Store, private readonly routingService: RoutingService) { }

	ngOnInit(): void {
		this.isMobile = isMobile();

		this.store
			.select(selectActiveTheme)
			.pipe(untilDestroyed(this))
			.subscribe((theme) => {
				this.callAgentButtonOn = theme[GeneralPropsEnum.texts][StepsEnum.AGENT_LOCKED].callAgentButtonOn;
				this.callAgentButtonUrl = theme[GeneralPropsEnum.texts][StepsEnum.AGENT_LOCKED].callAgentButtonUrl;
			});
	}

	callAgent(): void {
		if (this.callAgentButtonUrl) {
			this.routingService.navigateToExternal(this.callAgentButtonUrl, true);
		} else {
			this.routingService.callTo(this.agentPhoneNumber);
		}
	}

}
