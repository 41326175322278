import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class NgOnDestroyService extends Subject<null> implements OnDestroy {
  // TODO: add explicit constructor

	ngOnDestroy() {
		this.next(null);
		this.complete();
	}
}
