import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BnNgIdleService } from 'bn-ng-idle';
import { Subscription, timer } from 'rxjs';

import { resetProgressBar } from '../store/actions/progress-meter.actions';
import { ResetQuoteDataExceptAddress } from '../store/actions/quote-data.actions';
import { ConfigService } from './config.service';
import { StepsEnum } from '../enums/steps.enum';
import { RoutingService } from './routing.service';

@UntilDestroy()
@Injectable()
export class SessionTimeoutService {
	private isSessionExpired = false;
	private sessionTimeoutSeconds;
	private timer$: Subscription;

	constructor(
		private readonly configService: ConfigService,
		private readonly idleService: BnNgIdleService,
		private readonly store: Store,
		private readonly router: Router,
		private readonly routingService: RoutingService
	) {}

	startIdleTimer(): void {
		const sessionTimeoutMinutes = this.configService.getConfig().sessionTimeoutMinutes;
		this.sessionTimeoutSeconds = sessionTimeoutMinutes * 60;

		this.idleService
			.startWatching(this.sessionTimeoutSeconds)
			.pipe(untilDestroyed(this))
			.subscribe((isExpired: boolean) => {
				if (isExpired) {
					this.resetSession();
				}
			});

		// run timer to check time after PC`s sleeping and date are greater than 12 hour
		this.startTimerSleepMode();
	}

	resetIdleTimer(): void {
		this.idleService.stopTimer();
		this.timer$.unsubscribe();
	}

	private resetSession(): void {
		this.isSessionExpired = true;

		this.store.dispatch(resetProgressBar());
		this.store.dispatch(ResetQuoteDataExceptAddress());
		this.routingService.navigateToErrorPage(StepsEnum.END_SESSION);
		this.resetIdleTimer();
	}

	private startTimerSleepMode(): void {
		localStorage.setItem('startTimeSleep', Date.now().toString());

		const cachedStartIdleTime = localStorage.getItem('startTimeSleep');
		const finaleSessionTimeOut = 43200000;
		const timeWithSessionTimeOut = +new Date(+cachedStartIdleTime);

		this.timer$ = timer(1000, 1000)
			.pipe(untilDestroyed(this))
			.subscribe((res) => {
				const now = +new Date();

				if (now - timeWithSessionTimeOut > finaleSessionTimeOut) {
					this.resetSession();
				}
			});
	}
}
