import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../store/states/app.state';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { selectInterviewMetadata } from '../../../../../store/selectors/interview-metadata.selector';
import { IInterviewMetadataState } from '../../../../../store/states/interview-metadata.state';
import { first } from 'rxjs/operators';
import { LobsEnum } from '../../../../../enums/lobs.enum';

@UntilDestroy()
@Component({
	selector: 'app-almost-there',
	templateUrl: './almost-there.component.html',
	styleUrls: ['./almost-there.component.scss'],
})
export class AlmostThereComponent implements OnInit {
	interviewMetadata: IInterviewMetadataState;
	isCrossSellToHome: boolean;

	constructor(protected store: Store<IAppState>, private progressMeterService: ProgressMeterService) {}

	ngOnInit(): void {
		this.store
			.select(selectInterviewMetadata)
			.pipe(first())
			.subscribe((interviewMetadata) => {
				this.interviewMetadata = interviewMetadata;
				this.isCrossSellToHome = this.interviewMetadata.crossSellTo.includes(LobsEnum.PERSONAL_HOME);
			});
	}

	handleContinue() {
		this.progressMeterService.goToNextPage();
	}

	getImageSrc() {
		if (this.isCrossSellToHome) {
			return '../../../../../../assets/images/almost-there-home.svg';
		}

		return '../../../../../../assets/images/almost-there-auto.svg';
	}
}
