import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { CoverageModel } from '../../../../../models/coverage.model';
import { IAppState } from '../../../../../store/states/app.state';
import { selectActiveTheme } from '../../../../../store/selectors/active-theme.selector';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActionApiService } from '../../../../../services/action-api.service';
import { SetAlternativeLogo } from '../../../../../store/actions/theme.action';
import { first } from 'rxjs/operators';
import { EventActions } from '../../../../../models/event-data.model';
import { BuyCoverageButtonService } from '../../../../../services/buy-coverage-button.service';
import { EventsService } from '../../../../../services/events.service';

@UntilDestroy()
@Component({
	selector: 'app-anchor-carrier',
	templateUrl: './anchor-carrier.component.html',
	styleUrls: ['./anchor-carrier.component.scss'],
})
export class AnchorCarrierComponent implements OnInit, OnDestroy {
	@Input() anchorCarrier: CoverageModel;
	actionApiService: ActionApiService;

	image: string;

	constructor(
		protected store: Store<IAppState>,
		private as: ActionApiService,
		private buyCoverageButtonService: BuyCoverageButtonService,
		private eventsService: EventsService
	) {
		this.actionApiService = as;
	}

	get month(): number {
		return this.anchorCarrier.term === 'TwelveMonths' ? 1 : 6;
	}

	get termNumber(): string {
		return this.month === 1 ? '1' : '';
	}

	get displayTerm(): string {
		return this.month === 1 ? 'terms.year' : 'terms.6mo';
	}

	get price(): number {
		return +this.anchorCarrier.premium / (this.month === 1 ? 12 : 6);
	}

	get totalPremium(): number {
		return this.anchorCarrier.premium;
	}

	ngOnInit(): void {
		this.store
			.select(selectActiveTheme)
			.pipe(first())
			.subscribe(({ images }) => {
				this.image = images.anchorCarrier ? 'anchorCarrier' : null;

				if (images.anchorCarrierPageLogo) {
					this.store.dispatch(
						SetAlternativeLogo({
							logo: 'anchorCarrierPageLogo',
						})
					);
				}
			});
	}

	ngOnDestroy(): void {
		this.store.dispatch(
			SetAlternativeLogo({
				logo: null,
			})
		);
	}

	onClick(): void {
		this.anchorCarrier = { ...this.anchorCarrier, consumerBindType: 'BridgeToCarrier' };
		this.buyCoverageButtonService.buyCoverageButtonClicked([this.anchorCarrier], '');

		this.eventsService.addEvent(EventActions.BUTTON_CLICK, {
			label: 'continue',
			button: 'bridge-btn',
			module: 'anchor-carrier',
		});
	}
}
