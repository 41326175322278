<section class="preview {{ section.name }}">
	<div class="content">
		<div class="rating-wrapper">
			<div class="rating">
				<div class="fill"></div>
				<svg fill="none" viewBox="0 0 262 53" xmlns="http://www.w3.org/2000/svg">
					<path
						class="stars"
						clip-rule="evenodd"
						d="M262 0H0V53H262V0ZM35.1986 21.4999L29.9449 10.8163L24.6912 21.4999L12.9383 23.2106L21.4395 31.5251L19.4333 43.2653L29.9449 37.7223L40.4565 43.2653L38.4462 31.5251L46.9516 23.2106L35.1986 21.4999ZM80.0807 10.8163L85.3344 21.4999L97.0874 23.2106L88.582 31.5251L90.5923 43.2653L80.0807 37.7223L69.5691 43.2653L71.5753 31.5251L63.0741 23.2106L74.827 21.4999L80.0807 10.8163ZM135.47 21.4999L130.217 10.8163L124.963 21.4999L113.21 23.2106L121.711 31.5251L119.705 43.2653L130.217 37.7223L140.728 43.2653L138.718 31.5251L147.223 23.2106L135.47 21.4999ZM180.352 10.8163L185.606 21.4999L197.359 23.2106L188.854 31.5251L190.864 43.2653L180.352 37.7223L169.841 43.2653L171.847 31.5251L163.346 23.2106L175.099 21.4999L180.352 10.8163ZM235.742 21.4999L230.488 10.8163L225.234 21.4999L213.481 23.2106L221.983 31.5251L219.977 43.2653L230.488 37.7223L241 43.2653L238.989 31.5251L247.495 23.2106L235.742 21.4999Z"
						fill-rule="evenodd"
					/>
				</svg>
			</div>
		</div>

		<h3 appTranslation="testimonials.title"></h3>

		<hr *ngIf="isSepVisible" />

		<div class="quote-wrapper">
			<q appTranslation="testimonials.quote"></q>
			<div appTranslation="testimonials.quoteAuthor" class="author"></div>

			<div *ngIf="button" class="action-wrapper">
				<button (click)="linkClick(url)" appTranslation="testimonials.button" class="button" type="button"></button>
			</div>
		</div>
	</div>
</section>
