import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';

import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../../../base-page.component';
import { inOutAnimation } from 'src/app/animations';

@UntilDestroy()
@Component({
	selector: 'app-lemonade-second-page',
	templateUrl: './lemonade-second-page.component.html',
	styleUrls: ['./lemonade-second-page.component.scss'],
	animations: [inOutAnimation],
})
export class LemonadeSecondPageComponent extends BasePageComponent implements OnInit, OnDestroy {
	@Output() changePage = new EventEmitter();

	form;

	constructor(injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>) {
		super(injector, store, quoteDataService);
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();
		this.patchData(this.form);
		this.registerOnChange(this.form);
		// this.setValidators();
	}

	initForm(): void {
		this.form = new FormGroup({
			SmokeDetector: new FormControl({ value: null, disabled: false }, []),
			DeadBoltLocks: new FormControl({ value: null, disabled: false }, []),
			FireExtinguisher: new FormControl({ value: null, disabled: false }, []),
			FireDetection: new FormControl({ value: null, disabled: false }, []),
			BurglarAlarm: new FormControl({ value: null, disabled: false }, []),
		});
	}
}
