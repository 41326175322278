import { AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { first } from 'rxjs/operators';
import { BasePageComponent } from '../../base-page.component';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { Validations } from 'src/app/utils/validation';
import { DateAdapter } from '@angular/material/core';
import { FormattedDateService } from '../../../../../services/formatted-date.service';
import { QuoteDataService } from '../../../../../services/quote-data.service';
import { EventActions } from '../../../../../models/event-data.model';
import { GtmControlsActions } from '../../../../../models/gtm-controls-actions.enum';
import { LobService } from 'src/app/services/lob.service';
import { StepsEnum } from '../../../../../enums/steps.enum';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDatepickerProviders } from '../../../../../constants/mat-datepicker-constants';

@UntilDestroy()
@Component({
	selector: 'app-policy-date-picker',
	templateUrl: './policy-date-picker.component.html',
	styleUrls: ['./policy-date-picker.component.scss'],
	providers: MatDatepickerProviders,
})
export class PolicyDatePickerComponent extends BasePageComponent implements OnInit, AfterViewInit {
	@ViewChild('myForm', { read: ElementRef }) myFormEl;
	form: FormGroup;
	moment = moment;
	PolicyStartDate = '';
	minDate: Date;
	IsDatePolicyChosen = false;
	questionName = 'EffectiveDate';
	isPrefilled = false;
	prefilledIndicationKeys;
	lob = '';
	describedByErrorId = `${this.questionName}_error`;

	showCalendar = true;

	constructor(
		injector: Injector,
		quoteDataService: QuoteDataService,
		store: Store<IAppState>,
		private formattedDateService: FormattedDateService,
		private lobService: LobService,
		private dateAdapter: DateAdapter<any>,
		private translateService: TranslateService
	) {
		super(injector, store, quoteDataService);
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
		this.myFormEl.nativeElement.querySelector('.mat-calendar-table-header-divider').remove();
	}

	ngOnInit(): void {
		super.ngOnInit(StepsEnum.POLICY_DATEPICKER);
		if (this.translateService.currentLang) {
			this.dateAdapter.setLocale(this.translateService.currentLang);
		}

		this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe((langChangeEvent) => {
			// showCalendar flag forces the calendar to re-render, because otherwise the calendar will not update the locale for days of the week
			this.showCalendar = false;
			setTimeout(() => {
				this.dateAdapter.setLocale(langChangeEvent.lang);
				this.showCalendar = true;
			});
		});
		this.minDate = new Date();

		this.initForm();
		this.registerOnChange(this.form);
		this.patchData(this.form);

		this.formattedDateService
			.getFormattedPolicyStartDate()
			.pipe(first())
			.subscribe((formattedDate) => {
				this.PolicyStartDate = formattedDate;
			});

		this.pushEvent(EventActions.DISPLAY, GtmControlsActions.DISPLAY);

		this.lobService
			.getLob$()
			.pipe(untilDestroyed(this))
			.subscribe((LobQuoteData) => {
				if (LobQuoteData[0] === 'PersonalAuto') {
					this.lob = 'PersonalAuto';
					this.minDate.setDate(this.minDate.getDate() + 1);
					this.prefilledIndicationKeys = ['PriorCarrierExpirationDateAuto'];
				} else {
					this.prefilledIndicationKeys = ['PriorCarrierExperationDate'];
				}
			});
	}

	initForm(): void {
		this.form = new FormGroup({}, null, null);
		this.form.addControl(
			this.questionName,
			new FormControl({ value: null, disabled: false }, [Validators.required, Validations.isPastDate])
		);
	}

	updateFormDate(value: any) {
		const val = moment(value).format('YYYY-MM-DD');
		document.getElementsByClassName('stage-choose')[0].classList.remove('error');
		this.form.get('EffectiveDate').setErrors(null);
		this.form.get('EffectiveDate').setValue(val);
		this.pushEvent(EventActions.EDIT, GtmControlsActions[this.isPrefilled ? 'PREFILL_EDIT' : 'EDIT']);
	}

	handleValidation(event) {
		document.getElementsByClassName('stage-choose')[0].classList.add('error');
		this.pushEvent(EventActions.SYS, GtmControlsActions.ERROR);
	}

	getFormControl(): FormControl {
		return this.form.controls.EffectiveDate as FormControl;
	}

	isInvalid() {
		const field = this.getFormControl();

		if (!field) {
			return false;
		}
		return field.invalid && field.touched;
	}

	private pushEvent(actionName: EventActions, controlActions: GtmControlsActions): void {
		this.eventsService.addEvent(actionName, { label: `${this.questionName}_${controlActions}` });
	}
}
