<div *ngIf="featureOn && isUpsellVisible" class="upsell-container">
	<div class="upsell-text">
		<h3 [masks]="masks" appTranslation="bundleBox.title" class="upsell-title"></h3>
		<h4 [masks]="masks" appTranslation="bundleBox.subTitle" class="upsell-subtitle"></h4>
	</div>

	<app-button
		(clicked)="toggleBundle()"
		[masks]="masks"
		[materialIcon]="isSelected ? 'check' : 'add'"
		[title]="button"
		severity="bordered"
		titleKey="bundleBox.button"
	></app-button>
</div>
