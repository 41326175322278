export enum StepGroup {
	residence = 'residence',
	property = 'property',
	policyholder = 'policyholder',
	startDate = 'startDate',
	quotes = 'quotes',
	drivers = 'drivers',
	auto = 'auto',
	home = 'home',
	vehicles = 'vehicles',
	history = 'history',
	addQuestions = 'addQuestions',
	payment = 'payment',
	complete = 'complete',
	confirmation = 'confirmation',
}

export enum FlowType {
	fq = 'FQ',
	qq = 'QQ',
}
