import { AfterViewInit, Component, Injector, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ControlsType } from 'src/app/constants/controls-type';
import { Fields } from 'src/app/constants/fields';
import { IAppState } from 'src/app/store/states/app.state';
import { BaseFormControlComponent } from '../base-form-control.component';
import { EventsService } from 'src/app/services/events.service';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends BaseFormControlComponent implements OnInit, AfterViewInit {
	@Input() valueChecked: boolean;

	labelId: string;

	constructor(injector: Injector, protected store: Store<IAppState>, eventsService: EventsService) {
		super(injector, store, eventsService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.labelId = this.name + '_label';

		this.toggleLabel();

		this.controlType = ControlsType.checkbox;
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
	}

	toggleLabel(): void {
		// check if values should be other than true or false
		const formCtrlVal = this.controlContainer.control.controls[this.fieldObj.name].value;

		this.value = this.fieldObj.altValues ? formCtrlVal === this.fieldObj.altValues.check : !!formCtrlVal;
	}

	onChange(event) {
		this.initValue = !event.target.checked;
		const field = Fields[this.fieldObj.name];
		if (
			!event.target.checked &&
			field &&
			field.children &&
			field.children &&
			field.children.filter((c) => c.name.indexOf('MailingAddress') > -1).length === 0
		) {
			field.children
				.filter((child) => this.controlContainer.control.controls[child.name].value !== undefined)
				.forEach((child) => {
					if (!child.leaveValueWhenHidden) {
						this.controlContainer.control.controls[child.name].patchValue(null);
					}
				});
		}
		// check if values should be other then true or false
		if (this.fieldObj.altValues) {
			if (!this.controlContainer.control.controls[this.fieldObj.name].value) {
				this.controlContainer.control.controls[this.fieldObj.name].value = !this.value
					? this.fieldObj.altValues.check
					: this.fieldObj.altValues.uncheck;
			} else {
				this.controlContainer.control.controls[this.fieldObj.name].value =
					this.controlContainer.control.controls[this.fieldObj.name].value == this.fieldObj.altValues.check ||
					this.controlContainer.control.controls[this.fieldObj.name].value === true
						? this.fieldObj.altValues.check
						: this.fieldObj.altValues.uncheck;
			}
		}
		this.toggleLabel();
	}

	toggle(event) {
		if (event.keyCode === 13 || event.keyCode === 32) {
			this.controlContainer.control.controls[this.fieldObj.name].patchValue(
				!this.controlContainer.control.controls[this.fieldObj.name].value
			);
			this.onChange(event);
			event.preventDefault();
			event.stopPropagation();
			event.stopImmediatePropagation();
		}
	}

	onFocus() {
		this.baseOnFocus();
	}

	onBlur() {
		this.baseOnBlur();
		const field = this.controlContainer.control.controls[this.fieldObj.name];
		if (this.fieldObj.name !== Fields.AnimalsOnThePremises_None.name) {
			field.markAsTouched();
		} else {
			field.markAsPristine();
		}
	}

	isInvalid() {
		const field = this.controlContainer.control.controls[this.fieldObj.name];

		if (!field) {
			return false;
		}
		return field.invalid && field.touched;
	}
}
