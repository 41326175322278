import { Component, Injector, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../base-page.component';
import { EventActions } from '../../../../../models/event-data.model';
import { selectSecretSource, selectSource } from '../../../../../store/selectors/result-data.selectors';
import { StepsEnum } from '../../../../../enums/steps.enum';
import { selectInterviewTexts } from '../../../../../store/selectors/interview-texts.selectors';
import { setIsQuoteCompleted } from '../../../../../store/actions/result-data.actions';

@UntilDestroy()
@Component({
	selector: 'app-success-page-escrow',
	templateUrl: './success-page-escrow.component.html',
	styleUrls: ['./success-page-escrow.component.scss'],
})
export class SuccessPageEscrowComponent extends BasePageComponent implements OnInit {
	eventActions = EventActions;
	source: string;
	secretSource: string;
	backButtonOn: boolean;
	backButtonUrl: string;

	constructor(injector: Injector, protected store: Store<IAppState>) {
		super(injector, store);
	}

	ngOnInit(): void {
		this.eventsService.addEvent(this.EventActions.END_SESSION);
		this.store.dispatch(setIsQuoteCompleted({ data: true }));
		combineLatest([
			this.store.select(selectSource),
			this.store.select(selectSecretSource),
			this.store.select(selectInterviewTexts),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([source, secretSource, stepTexts]) => {
				this.source = source;
				this.secretSource = secretSource;
				this.backButtonOn = stepTexts[StepsEnum.SUCCESS_PAGE_ESCROW].backButtonOn;
				this.backButtonUrl = stepTexts[StepsEnum.SUCCESS_PAGE_ESCROW].backButtonUrl;
			});
	}
}
