import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LobsEnum } from '../../../enums/lobs.enum';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/states/app.state';
import {
	removeCrossSellTo,
	UpdateCrossSellTo,
	UpdateInterviewMetadata,
} from '../../../store/actions/interview-metadata.actions';
import { selectInterviewMetadata } from '../../../store/selectors/interview-metadata.selector';
import { first } from 'rxjs/operators';
import { getD2CFeatures } from 'src/app/utils/general.utils';

@Component({
	selector: 'app-upsell-box',
	templateUrl: './upsell-box.component.html',
	styleUrls: ['./upsell-box.component.scss'],
})
export class UpsellBoxComponent implements OnInit {
	@Input() currentLob: LobsEnum;
	@Input() upsellLob: LobsEnum;
	@Output() upsellToggled: EventEmitter<boolean> = new EventEmitter();
	isUpsellVisible: boolean;
	isSelected = false;
	isMultiLob = false;
	baseLob = '';
	lobSelection: string[] = [];
	lobsInitialized: string[] = [];
	featureOn = false;
	masks = {};

	constructor(private store: Store<IAppState>) { }

	get button(): string {
		return this.isSelected ? 'Bundle selected' : null;
	}

	ngOnInit(): void {
		this.featureOn = getD2CFeatures()?.crossSell[this.currentLob]?.indexOf(this.upsellLob) > -1;
		// TODO: move to store

		this.store
			.select(selectInterviewMetadata)
			.pipe(first())
			.subscribe((interviewMetadata) => {
				this.lobsInitialized = interviewMetadata.lobsInitialized;
				this.baseLob = this.lobsInitialized[0];
				this.lobSelection = interviewMetadata.lobSelection;
				this.isSelected = interviewMetadata.crossSellTo.includes(this.upsellLob);
				this.isMultiLob = interviewMetadata.isMultiLob;
				this.masks = {
					base_lob: `lobs.${this.baseLob}`,
					bundle_lob: `lobs.${this.upsellLob}`,
				};

				if (!this.isMultiLob && this.featureOn) {
					this.toggleUpsellBoxVisibility(true);
				}
			});
	}

	public toggleUpsellBoxVisibility(isUpsellVisible: boolean) {
		if (!isUpsellVisible) {
			this.isSelected = false;
			this.updateFlowType();
		}

		this.isUpsellVisible = isUpsellVisible;
	}

	toggleBundle() {
		this.isSelected = !this.isSelected;
		this.upsellToggled.emit(this.isSelected);
	}

	updateFlowType() {
		const upsellLob = LobsEnum[this.getLobsEnumKeyByValue(this.upsellLob)];
		const lobSelection = [...this.lobSelection].filter((lob) => lob !== upsellLob);

		if (this.isUpsellVisible && this.isSelected) {
			this.store.dispatch(UpdateCrossSellTo({ crossSellTo: [this.upsellLob] }));
			lobSelection.push(upsellLob);
		} else {
			this.store.dispatch(removeCrossSellTo({ crossSellTo: [this.upsellLob] }));
		}

		this.store.dispatch(
			UpdateInterviewMetadata({
				data: {
					lobSelection,
				},
			})
		);
	}

	getLobsEnumKeyByValue(lob) {
		const upsellLobIndex = Object.values(LobsEnum).indexOf(lob);
		return Object.keys(LobsEnum)[upsellLobIndex];
	}
}
