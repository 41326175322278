import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-page-heading',
	templateUrl: './page-heading.component.html',
	styleUrls: ['./page-heading.component.scss'],
})
export class PageHeadingComponent {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() titleKey: string;
	@Input() subtitleKey: string;
}
