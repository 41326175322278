<section class="preview {{ section.name }}">
	<div class="content">
		<div class="title-section">
			<h1 appTranslation="whoWeAre.title"></h1>
			<p appTranslation="whoWeAre.subTitle"></p>
		</div>

		<div *ngIf="button" class="call-to-action">
			<button (click)="linkClick(url)" appTranslation="whoWeAre.button" class="button" type="button"></button>
		</div>
	</div>
</section>
