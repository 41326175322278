import { AfterViewInit, Component, Injector, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { BaseFormControlComponent } from '../base-form-control.component';
import { FormControl } from '@angular/forms';
import { EventsService } from 'src/app/services/events.service';

@Component({
	selector: 'app-yes-no',
	templateUrl: './yes-no.component.html',
	styleUrls: ['./yes-no.component.scss'],
})
export class YesNoComponent extends BaseFormControlComponent implements AfterViewInit, OnInit {
	@Input() falseValue: any = false;
	@Input() trueValue: any = true;
	@Input() vertical: any = false;

	constructor(injector: Injector, protected store: Store<IAppState>, eventsService: EventsService) {
		super(injector, store, eventsService);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	ngAfterViewInit() {}

	isInvalid() {
		const field = this.controlContainer.control.controls[this.name];
		return field.touched && field.status === 'INVALID';
	}

	markAsTouched() {
		const field = this.controlContainer.control.controls[this.name] as FormControl;
		field.markAsTouched();
	}
}
