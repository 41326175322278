import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { capitalize } from '../utils/general.utils';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';

class CustomDateAdapter extends MomentDateAdapter {
	getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
		// returns the array of days of week, trimmed to first 3 letters, respecting the locale
		return super.getDayOfWeekNames('long').map((day) => {
			return capitalize(day.substring(0, 3));
		});
	}

	getFirstDayOfWeek(): number {
		return 1;
	}
}

export const MY_FORMATS = {
	...MAT_NATIVE_DATE_FORMATS,
	display: {
		...MAT_NATIVE_DATE_FORMATS.display,
		dateInput: 'DD MMMM YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

export const MatDatepickerProviders = [
	{ provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
	{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
];
