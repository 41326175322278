<app-error
	[imageName]="imageName || 'carrierKickOut'"
	[showFriendlyId]="true"
	subtitleKey="carrier-kickout.subTitle"
	titleKey="carrier-kickout.title"
>
	<div class="buttons-footer">
		<button
			(click)="handleCallAgent()"
			*ngIf="lob !== LobsEnum.PERSONAL_AUTO"
			[class.call-agent-button-on]="callAgentButtonOn"
			class="button-call-agent app-button"
		>
			<span class="title">
				<span appTranslation="carrier-kickout.callAgentButtonText"></span>
				<i appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
			</span>
		</button>

		<button (click)="handleBackToQuote()" *ngIf="backButtonOn" class="app-button bordered" type="button">
			<span class="title">
				<span appTranslation="carrier-kickout.backButtonText"></span> &nbsp;<i
					appHiddenWhenCssDisabled
					class="material-icons"
					role="presentation"
					>chevron_right</i
				>
			</span>
		</button>
	</div>
</app-error>
