<app-error
	imageName="confirmationEmailSent"
	subtitleKey="confirmationEmailSent.subtitle"
	titleKey="confirmationEmailSent.title"
>
	<p class="didnt-get">
		<span appTranslation="confirmationEmailSent.didntGetTheMail"></span>&nbsp;<button
			(click)="sendConfirmationEmail($event)"
			[disabled]="!isResendEnabled"
			class="re-send-link"
			type="button"
		>
			<span *ngIf="isResendEnabled" appTranslation="confirmationEmailSent.resendEmail"></span>
			<span *ngIf="!isResendEnabled">
				{{ "buttons.tryAgainIn" | translate }}
				{{ countdownSeconds > 9 ? countdownSeconds : "0" + countdownSeconds }}
				{{ "buttons.seconds" | translate }}
			</span>
		</button>
	</p>
</app-error>
