<section class="preview {{ section.name }}">
	<div class="content">
		<div class="title-section">
			<h1 appTranslation="howItWorks.title"></h1>
			<p appTranslation="howItWorks.subTitle"></p>
		</div>

		<ul class="step-list">
			<li *ngFor="let step of stepList; index as i">
				<div class="counter">
					<span>{{ i + 1 }}</span>
				</div>
				<div class="title">{{ getTitle(step) }}</div>
				<app-image [alt]="step.alt" [name]="step.name" classNames="presentation-image" role="presentation"></app-image>
			</li>
		</ul>

		<div (click)="linkClick(url)" *ngIf="button" class="call-to-action">
			<button appTranslation="howItWorks.button" class="button" type="button"></button>
		</div>
	</div>
</section>
