import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-consumer-flow',
  templateUrl: './no-consumer-flow.component.html',
  styleUrls: ['./no-consumer-flow.component.scss']
})
export class NoConsumerFlowComponent implements OnInit {

  leadSourcePhone: string;
  title = 'Oops! Something went wrong';
  subtitle = '';

  constructor() { }

  ngOnInit(): void {
    this.leadSourcePhone = JSON.parse(sessionStorage.getItem('leadSource'))?.leadSource?.phone;
    this.subtitle = `Sorry, the requested Consumer Flow is not available at the moment. Please try later or call the agency directly at ${this.leadSourcePhone}`
  }

  getImageSrc() {
			return '../../../../../../assets/images/kickout_background-image.png';
	}

}
