<section class="preview {{ section.name }}">
	<div class="content">
		<h1 appTranslation="statistic.title"></h1>
		<p appTranslation="statistic.subTitle"></p>

		<ul class="stat-list">
			<li *ngFor="let statItem of statList">
				<span [class]="statItem.top.type">{{ getText(statItem.top) }}</span>
				<span [class]="statItem.bottom.type">{{ getText(statItem.bottom) }}</span>
			</li>
		</ul>
	</div>
</section>
