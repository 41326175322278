import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { QuoteDataService } from 'src/app/services/quote-data.service';
import { selectFirstName } from 'src/app/store/selectors/quote-data.selectors';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../../../base-page.component';
import { LoaderActions } from 'src/app/store/actions';

@UntilDestroy()
@Component({
	selector: 'app-lemonade',
	templateUrl: './lemonade.component.html',
	styleUrls: ['./lemonade.component.scss'],
})
export class LemonadeComponent extends BasePageComponent implements OnInit, OnDestroy {
	firstName = '';

	constructor(injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>) {
		super(injector, store, quoteDataService);
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	ngOnInit(): void {
		this.store.dispatch(LoaderActions.Hideloader());

		super.ngOnInit();
		this.initForm();
		this.patchData(this.form);
		this.registerOnChange(this.form);
		let NumberOfMortgageesValue = this.form.controls.NumberOfMortgagees.value;
		this.form.controls.NumberOfMortgagees.setValue(
			NumberOfMortgageesValue === 1 ? true : NumberOfMortgageesValue === 3 ? false : !!NumberOfMortgageesValue
		);

		this.store
			.select(selectFirstName)
			.pipe(untilDestroyed(this))
			.subscribe((firstName) => {
				this.firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
				this.title = `${this.firstName}, do any of these questions apply to you?`;
			});
	}

	initForm(): void {
		this.form = new FormGroup({
			NumberOfMortgagees: new FormControl({ value: null, disabled: false }, [Validators.required]),
			PL_AdditionalStructures_Pool: new FormControl({ value: null, disabled: false }, [Validators.required]),
			PL_PoolFeatures_4ftFence: new FormControl({ value: null, disabled: false }),
			PL_AdditionalStructures_Trampoline: new FormControl({ value: null, disabled: false }, [Validators.required]),
		});

		this.onFormChange();

		this.registerOnChange(this.form);
	}

	onFormChange() {
		this.form.controls['PL_AdditionalStructures_Pool'].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			if (!value) {
				this.resetField('PL_PoolFeatures_4ftFence');
			} else {
				this.form.controls['PL_PoolFeatures_4ftFence'].setValidators(Validators.required);
			}
		});
	}

	private resetField(fieldName) {
		this.form.controls[fieldName].setValue(null);
		this.form.controls[fieldName].setValidators(null);
		this.form.controls[fieldName].setErrors(null);
	}
}
