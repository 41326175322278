import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-legal-text',
	templateUrl: './legal-text.component.html',
	styleUrls: ['./legal-text.component.scss'],
})
export class LegalTextComponent {
	@Input() customLegalText: string;
}
