import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { selectFirstName } from 'src/app/store/selectors/quote-data.selectors';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../../../base-page.component';
import { v4 as uuidv4 } from 'uuid';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectIsAdditionalQuestionsChosen } from 'src/app/store/selectors/result-data.selectors';
import { inOutAnimation } from 'src/app/animations';
import { setIsAdditionalQuestionsChosen } from 'src/app/store/actions/result-data.actions';
import { startWith, take } from 'rxjs';
import { Validations } from 'src/app/utils/validation';
import { SW_LossesOptions } from '../../../../../../../constants/field-options';
import { handleIncompleteAddressInAddressForm } from '../../../../../../../utils/general.utils';

const LOSS_FIELD_NAMES = ['DateOfLoss', 'PersonalLineLossAmount', 'PLLossDescription'];

@UntilDestroy()
@Component({
	selector: 'app-stillwater',
	templateUrl: './stillwater.component.html',
	styleUrls: ['./stillwater.component.scss'],
	animations: [inOutAnimation],
})
export class StillwaterComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
	isAdditionalQuestionsChosen: boolean;
	firstName: string;
	form;
	SequenceNum: any;
	lossesOptions = SW_LossesOptions;

	fieldsNames = {
		AddressLine1: 'PreviousAddress.AddressLine1',
		AddressLine2: 'PreviousAddress.AddressLine2',
		City: 'PreviousAddress.City',
		State: 'PreviousAddress.State',
		ZipCode: 'PreviousAddress.ZipCode',
		County: 'PreviousAddress.County',
	};

	constructor(injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>) {
		super(injector, store, quoteDataService);
	}

	get losses(): FormArray {
		return this.form.controls['PersonalLosses'] as FormArray;
	}

	get addressForm(): FormGroup {
		return this.form.get('PreviousAddress') as FormGroup;
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();

		this.store
			.select(selectFirstName)
			.pipe(untilDestroyed(this))
			.subscribe((firstName) => {
				this.firstName = firstName;
			});

		//If there is no loss at all and PersonalLosses control is true - add 1 empty loss automatically
		if (this.form.controls['PLHaveAnyLosses'].value == true && this.form.controls['PersonalLosses'].value.length == 0) {
			this.addLoss();
		}
	}

	addLoss() {
		// should not create a loss line if there are prefilled losses on page load
		if (this.getActiveLosses(this.losses).length < 4) {
			const id = uuidv4();
			const lossItem = this.formBuilder.group({
				Id: id,
				SequenceNum: this.SequenceNum++,
				DateOfLoss: [''],
				PersonalLineLossAmount: [null],
				PLLossDescription: [null],
				active: true,
			});

			lossItem.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
				// if one of loss fields is not empty, make all fields required,
				// if all loss fields are empty, remove validations
				if (value.active) {
					if (value.DateOfLoss || value.PersonalLineLossAmount || value.PLLossDescription) {
						this.makeLossFieldsRequired(lossItem);
					} else {
						this.makeLossFieldsNotRequired(lossItem);
					}

					this.updateValidationOfAllLossesForms();
				}
			});

			this.losses.push(lossItem);
		}

		this.updateValidationOfAllLossesForms();
	}

	removeLoss(id) {
		const index = this.losses.controls.findIndex((item) => item.value.Id === id);
		this.losses.controls[index].patchValue({
			active: false,
			DateOfLoss: null,
			PLLossDescription: null,
			PersonalLineLossAmount: null,
			SequenceNum: -1,
		});
		this.updateValidationOfAllLossesForms();
	}

	getActiveLosses(losses) {
		return losses.controls.filter((lose) => lose.value.active);
	}

	public getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
		let hasError = false;
		const result = Object.keys(form.controls).reduce((acc, key) => {
			const control = form.controls[key];
			const errors =
				control instanceof FormGroup || control instanceof FormArray ? this.getAllErrors(control) : control.errors;
			if (errors) {
				acc[key] = errors;
				hasError = true;
			}
			return acc;
		}, {} as { [key: string]: any });
		return hasError ? result : null;
	}

	onNextButtonClicked() {
		// user selected input will be prefill when coming back to this page
		if (!this.isAdditionalQuestionsChosen) {
			this.store.dispatch(setIsAdditionalQuestionsChosen({ isAdditionalQuestionsChosen: true }));
		}
	}

	handleAddressChange() {
		handleIncompleteAddressInAddressForm(this.addressForm);
	}

	private initForm(): void {
		this.form = new FormGroup({
			BusinessOrDaycare: new FormControl({ value: null, disabled: false }, [Validators.required]),
			NumberOfDogsOnPremises: new FormControl({ value: null, disabled: false }, [Validators.required]),
			RestrictedDogs: new FormControl({ value: null, disabled: false }),
			AnimalBites: new FormControl({ value: null, disabled: false }),
			ViciousExoticAnimals: new FormControl({ value: null, disabled: false }),
			PLHaveAnyLosses: new FormControl({ value: null, disabled: false }, [Validators.required]),
			PersonalLosses: new FormArray([]),
			YearsAtAddress: new FormControl({ value: null, disabled: false }, [Validators.required]),
			PreviousAddress: new FormGroup({
				AddressLine1: new FormControl(
					{
						value: null,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(50), Validations.isPoBox]
				),
				AddressLine2: new FormControl({ value: null, disabled: false }, [Validators.maxLength(50)]),
				City: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.maxLength(25)]),
				State: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.maxLength(2)]),
				ZipCode: new FormControl(
					{
						value: null,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(5), Validations.zipStrictLength(5)]
				),
				County: new FormControl({ value: null, disabled: false }, []),
			}),
			HomeAutoInsurance: new FormControl({ value: null, disabled: false }, [Validators.required]),
			BundelingAutoPolicyNum: new FormControl({ value: null, disabled: true }, [Validators.required]),
			IsMailAddress: new FormControl({ value: false, disabled: false }, [Validators.required]),
		});

		this.store
			.select(selectIsAdditionalQuestionsChosen)
			.pipe(take(1))
			.subscribe((isChosen) => {
				this.isAdditionalQuestionsChosen = isChosen;
				if (isChosen) {
					this.patchData(this.form);
					this.patchDataArray(this.form, 'PersonalLosses');
					this.form.controls['RestrictedDogs'].patchValue(this.form.controls['ViciousExoticAnimals'].value);
				}
				setTimeout(() => {
					this.onFormChange();
				}, 1000);
			});

		this.SequenceNum = this.form.controls['PersonalLosses'].length ? this.form.controls['PersonalLosses'].length : 0;
		this.registerOnChange(this.form);
	}

	private onFormChange() {
		this.form.controls['PLHaveAnyLosses'].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			if (value) {
				this.addLoss();
			} else {
				this.removeAllLosses();
			}
		});

		this.form.controls['HomeAutoInsurance'].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			if (value) {
				this.form.controls['BundelingAutoPolicyNum'].enable();
			} else {
				this.form.controls['BundelingAutoPolicyNum'].disable();
			}
		});

		this.form.controls['YearsAtAddress'].valueChanges
			.pipe(untilDestroyed(this), startWith(false))
			.subscribe((value) => {
				if (value) {
					this.form.controls.PreviousAddress.controls.AddressLine1.enable();
					this.form.controls.PreviousAddress.controls.AddressLine2.enable();
					this.form.controls.PreviousAddress.controls.City.enable();
					this.form.controls.PreviousAddress.controls.State.enable();
					this.form.controls.PreviousAddress.controls.ZipCode.enable();
				} else {
					this.form.controls.PreviousAddress.controls.AddressLine1.disable();
					this.form.controls.PreviousAddress.controls.AddressLine2.disable();
					this.form.controls.PreviousAddress.controls.City.disable();
					this.form.controls.PreviousAddress.controls.State.disable();
					this.form.controls.PreviousAddress.controls.ZipCode.disable();
				}
			});

		this.form.controls['NumberOfDogsOnPremises'].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			if (!value) {
				this.resetField('AnimalBites');
				this.resetField('RestrictedDogs');
				this.resetField('ViciousExoticAnimals');
			} else {
				this.form.controls['AnimalBites'].enable();
				this.form.controls['RestrictedDogs'].enable();
				this.form.controls['AnimalBites'].setValidators(Validators.required);
				this.form.controls['RestrictedDogs'].setValidators(Validators.required);
			}
		});

		this.form.controls['RestrictedDogs'].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			if (value) {
				this.form.controls['ViciousExoticAnimals'].setValue(true);
			} else {
				this.form.controls['ViciousExoticAnimals'].setValue(false);
			}
		});
	}

	private updateValidationOfAllLossesForms() {
		// If some losses forms are empty, make them not required
		// const activeLossesForms = this.getActiveLosses(this.losses);
		const filledLosses = this.losses.controls.filter((item) => {
			if (item.value.DateOfLoss || item.value.PersonalLineLossAmount || item.value.PLLossDescription) {
				return true;
			}
			return false;
		});

		const emptyLosses = this.losses.controls.filter((item) => {
			if (!item.value.DateOfLoss && !item.value.PersonalLineLossAmount && !item.value.PLLossDescription) {
				return true;
			}
			return false;
		});

		filledLosses.forEach((item) => {
			this.makeLossFieldsRequired(item as FormGroup);
		});

		emptyLosses.forEach((item) => {
			this.makeLossFieldsNotRequired(item as FormGroup);
		});
	}

	private makeLossFieldsRequired(lossItem: FormGroup) {
		LOSS_FIELD_NAMES.forEach((fieldName) => {
			lossItem.controls[fieldName].setValidators(Validators.required);
		});
		this.updateLossFieldsValidity(lossItem);
	}

	private makeLossFieldsNotRequired(lossItem: FormGroup) {
		LOSS_FIELD_NAMES.forEach((fieldName) => {
			lossItem.controls[fieldName].clearValidators();
			lossItem.controls[fieldName].removeValidators([]);
			lossItem.controls[fieldName].setErrors(null);
		});
		this.updateLossFieldsValidity(lossItem);
	}

	private updateLossFieldsValidity(lossItem: FormGroup) {
		LOSS_FIELD_NAMES.forEach((fieldName) => {
			lossItem.controls[fieldName].updateValueAndValidity({ emitEvent: false });
		});
	}

	private removeAllLosses() {
		this.losses.controls.forEach((item) => {
			this.removeLoss(item.value.Id);
		});
		this.SequenceNum = 0;
	}

	private resetField(fieldName) {
		this.form.controls[fieldName].disable();
		this.form.controls[fieldName].setValue(null);
		this.form.controls[fieldName].removeValidators();
		this.form.controls[fieldName].setErrors(null);
		this.form.controls[fieldName].markAsUntouched();
	}
}
