import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header.component';
import { DirectivesModule } from '../../../directives/directives.module';
import { ImageModule } from '../../shared/image/image.module';
import { ProgressMeterHeaderComponent } from '../progress-meter-header/progress-meter-header.component';
import { FormControlModule } from '../../form-controls/form-control.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [HeaderComponent, ProgressMeterHeaderComponent],
	imports: [
		CommonModule,
		RouterModule,
		DirectivesModule,
		ImageModule,
		FormControlModule,
		ReactiveFormsModule,
		TranslateModule,
	],
	exports: [HeaderComponent],
})
export class HeaderModule { }
