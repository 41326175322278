<app-error [showFriendlyId]="true" imageName="technical" subtitleKey="technical.subTitle" titleKey="technical.title">
	<app-button
		(clicked)="callAgent()"
		*ngIf="callAgentButtonOn || (isMobile && callAgentButtonText)"
		[eventData]="{
			action: EventActions.BUTTON_CLICK,
			label: 'Interview.CallAgent'
		}"
		appClickEvent
		titleKey="technical.callAgentButtonText"
	>
	</app-button>

	<app-button
		(clicked)="goHome()"
		*ngIf="backButtonOn"
		[eventData]="{
			action: EventActions.BUTTON_CLICK,
			label: 'Interview.GoHomeButton'
		}"
		appClickEvent
		severity="bordered"
		titleKey="technical.backButtonText"
	>
	</app-button>
</app-error>
