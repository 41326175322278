import { Component, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { RoutingService } from 'src/app/services/routing.service';
import { EventActions } from '../../../../../../models/event-data.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isMobile } from 'src/app/utils/general.utils';
import { selectActiveTheme } from '../../../../../../store/selectors/active-theme.selector';
import { GeneralPropsEnum } from '../../../../../../models/general-props.enum';
import { StepsEnum } from '../../../../../../enums/steps.enum';

@UntilDestroy()
@Component({
	selector: 'app-no-rates-error',
	templateUrl: './no-rates-error.component.html',
})
export class NoRatesErrorComponent implements OnInit {
	@Input() showFriendlyId = true;
	eventActions = EventActions;
	agentPhoneNumber: string;
	isMobile: boolean;
	callAgentButtonOn: boolean;
	callAgentButtonUrl: string;

	constructor(private readonly store: Store, private readonly routingService: RoutingService) {}

	ngOnInit(): void {
		this.isMobile = isMobile();

		this.store
			.select(selectActiveTheme)
			.pipe(untilDestroyed(this))
			.subscribe((theme) => {
				this.callAgentButtonOn = theme[GeneralPropsEnum.texts][StepsEnum.NO_RESULTS].callAgentButtonOn;
				this.callAgentButtonUrl = theme[GeneralPropsEnum.texts][StepsEnum.NO_RESULTS].callAgentButtonUrl;
			});
	}

	callAgent(): void {
		this.routingService.callTo(this.agentPhoneNumber);
	}

	goHome(): void {
		this.routingService.navigateToHomePage();
	}
}
