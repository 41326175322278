<section class="preview {{ section.name }}">
	<div class="content">
		<div class="title-section">
			<h1 appTranslation="faq.title"></h1>
			<p appTranslation="faq.subTitle"></p>
		</div>

		<mat-accordion *ngIf="faqs?.length">
			<mat-expansion-panel #panel *ngFor="let faq of faqs" hideToggle="true">
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ getText(faq.top) }}
					</mat-panel-title>
					<mat-panel-description>
						<div [class.opened]="panel.expanded" class="arrow"></div>
					</mat-panel-description>
				</mat-expansion-panel-header>

				<div [innerHTML]="getText(faq.bottom)" class="faq-answer"></div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</section>
