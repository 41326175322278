import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { ActionApiService } from 'src/app/services/action-api.service';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { IAppState } from 'src/app/store/states/app.state';
import { selectAgentPhoneNumber } from '../../../../../../store/selectors/active-theme.selector';
import { NavigateToErrorPage } from 'src/app/store/actions/routing.actions';
import { UpdateInterviewMetadata } from 'src/app/store/actions/interview-metadata.actions';
import { AppService } from 'src/app/services/app.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { EventsService } from 'src/app/services/events.service';
import { EventActions } from 'src/app/models/event-data.model';

@UntilDestroy()
@Component({
	selector: 'app-link-expired-error',
	templateUrl: './link-expired-error.component.html',
	styleUrls: ['./link-expired-error.component.scss'],
})
export class LinkExpiredErrorComponent implements OnInit {
	token = '';
	tokenStatus = '';
	agentPhoneNumber = '';
	isExpiredRenewable = false;
	EventActions = EventActions;

	constructor(
		private actionApiService: ActionApiService,
		protected store: Store<IAppState>,
		private appService: AppService,
		private eventsService: EventsService
	) {}

	ngOnInit(): void {
		this.store
			.select(selectInterviewMetadata)
			.pipe(first())
			.subscribe((interviewMetaData) => {
				this.token = interviewMetaData.token;
				this.tokenStatus = interviewMetaData.tokenStatus;
				this.appService.clearData();
				this.store.dispatch(UpdateInterviewMetadata({ data: { tokenStatus: this.tokenStatus } }));
				this.isExpiredRenewable = interviewMetaData.tokenStatus === 'ExpiredRenewable';
			});

		this.store
			.select(selectAgentPhoneNumber)
			.pipe(untilDestroyed(this))
			.subscribe((phoneNumber) => {
				this.agentPhoneNumber = phoneNumber;
			});
	}

	handleResendLink() {
		this.store.dispatch(UpdateInterviewMetadata({ data: { tokenStatus: '' } }));
		this.actionApiService.resendEmail(this.token).subscribe(() => {
			this.store.dispatch(NavigateToErrorPage({ errorType: 'link-sent' }));
		});
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'resend-link',
			button: 'resend-link',
			module: 'link-expired-module',
		});
	}
}
