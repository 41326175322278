<ul class="radio-list-container">
	<li *ngFor="let option of options; let index = index">
		<label
			(click)="handleChooseOption(option.value)"
			[for]="option.value"
			[ngClass]="{ checked: option.value === this.selectedOption }"
			class="radio-button-item"
		>
			<span class="circle-wrapper">
				<span class="inner"></span>
			</span>

			<span class="img-wrapper">
				<app-image
					[class.show]="this.selectedOption === option.value"
					[name]="getFileName(option.imageName || option.value, true)"
					alt="null"
					role="presentation"
				></app-image>

				<app-image
					[class.show]="this.selectedOption !== option.value"
					[name]="getFileName(option.imageName || option.value, false)"
					alt="null"
					role="presentation"
				></app-image>
			</span>

			<input
				[attr.id]="option.value"
				[attr.tabindex]="index === 0 ? 0 : null"
				[checked]="option.value === this.selectedOption"
				[name]="name"
				[value]="option.value"
				class="radio-input"
				type="radio"
			/>
			<span class="title">
				<ng-container *ngIf="option.labelKey; else withoutLabelKey">
					{{ "dropdowns." + option.labelKey | translate }}
				</ng-container>

				<ng-template #withoutLabelKey>
					{{ option.title }}
				</ng-template>
			</span>
		</label>
	</li>
</ul>
