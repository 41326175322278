<error-message
	*ngIf="!withoutErrorMessage"
	[describedByErrorId]="describedByErrorId"
	[fieldDisplayName]="postfix || name"
	[field]="controlContainer.control.controls[this.name]"
	[isFocus$]="isFocus$"
>
</error-message>

<app-label
	*ngIf="label"
	[label]="label"
	[name]="this.name"
	[popupLink]="popupLink"
	[subLabel]="subLabel"
	[tabindex]="tabindex"
>
</app-label>

<form (ngModelChange)="setValue()" [formGroup]="controlContainer.control">
	<ul
		[ngClass]="{ error: error, 'with-inner-label': labelInsideField, disabled: disabled, focus: (isFocus$ | async) }"
		class="radio-list-container"
	>
		<li *ngFor="let option of options; let i = index">
			<label
				(keydown)="updateAnswer($event, i)"
				[for]="option.value"
				[ngClass]="{ checked: option.checked }"
				class="radio-button-item"
			>
				<span class="image-container">
					<app-image
						[class.show]="option.checked"
						[name]="getFileName(option.value, true)"
						alt="null"
						role="presentation"
					></app-image>

					<app-image
						[class.show]="!option.checked"
						[name]="getFileName(option.value, false)"
						alt="null"
						role="presentation"
					></app-image>
				</span>

				<span class="circle-wrapper">
					<span class="inner"></span>
				</span>

				<input
					(blur)="onBlur()"
					(change)="updateAnswer($event, i)"
					(focus)="onFocus()"
					[attr.aria-checked]="!!value"
					[attr.aria-describedby]="error ? describedByErrorId : null"
					[attr.aria-invalid]="!!error ? true : null"
					[attr.id]="option.value"
					[attr.tabindex]="i === 0 ? 0 : null"
					[checked]="option.value === value"
					[name]="name"
					[value]="option.value"
					class="radio-input"
					type="radio"
				/>

				<span class="title">
					<ng-container *ngIf="option.labelKey; else withoutLabelKey">
						{{ "dropdowns." + option.labelKey | translate }}
					</ng-container>

					<ng-template #withoutLabelKey>
						{{ option.title }}
					</ng-template>
				</span>
			</label>
		</li>
	</ul>
</form>
