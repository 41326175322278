import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LandingSectionModel, SectionNavModel } from '../../../../../../../models/landing-section.model';
import { GeneralPropsEnum } from '../../../../../../../models/general-props.enum';
import { selectFooter } from '../../../../../../../store/selectors/active-theme.selector';
import { selectProgressMeter } from '../../../../../../../store/selectors/progress-meter.selector';
import { StepsEnum } from '../../../../../../../enums/steps.enum';
import { AdditionalClassesEnum } from '../../../../../../../models/additional-classes.enum';
import { ThemeModel } from '../../../../../../../models/theme.model';
import { LanguagesModel } from '../../../../../../../models/languages.model';

@UntilDestroy()
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	@Input() theme: ThemeModel;
	@Input() langSettings: LanguagesModel;

	footer: LandingSectionModel;
	footerVisibility: string;
	isFooterVisible = false;

	constructor(private store: Store) {}

	get lang(): string {
		return this.langSettings.selected ? `:${this.langSettings.selected}` : '';
	}

	get nav(): SectionNavModel[] {
		return this.footer[GeneralPropsEnum.nav];
	}

	get logoPosition(): SectionNavModel[] {
		return this.footer[GeneralPropsEnum.settings][AdditionalClassesEnum.logoPosition];
	}

	ngOnInit(): void {
		this.store
			.select(selectFooter)
			.pipe(untilDestroyed(this))
			.subscribe((footer) => {
				this.footer = footer;
				this.footerVisibility = footer?.settings?.footerVisibility;

				if (footer && footer[GeneralPropsEnum.enabled]) {
					this.isFooterVisible = true;

					this.store
						.select(selectProgressMeter)
						.pipe(untilDestroyed(this))
						.subscribe(({ flowOptions, activeStep, flowType }) => {
							const activeStepName = flowOptions[flowType][activeStep].name;

							if (this.footerVisibility === 'default') {
								this.isFooterVisible = footer.enabled && activeStepName === StepsEnum.YOUR_ADDRESS;
							}
						});
				}
			});
	}

	getTitle(link: { title?: string }): string {
		return link[`title${this.lang}`] || link[`title`];
	}
}
