import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { v4 as uuidv4 } from 'uuid';
import { CoverageModel } from '../../../../../../models/coverage.model';
import { ActionApiService } from 'src/app/services/action-api.service';
import { pricePerMonth, yearlyTermDisplayName } from '../../../../../../utils/general.utils';

@UntilDestroy()
@Component({
	selector: 'app-coverage',
	templateUrl: './coverage.component.html',
	styleUrls: ['./coverage.component.scss'],
})
export class CoverageComponent implements OnInit {
	@Output() buttonClicked = new EventEmitter<CoverageModel>();
	// @Input() rate: CoverageModel;
	@Input() premium: number;
	@Input() term: string;
	@Input() isMonthly = true;
	@Input() isInEligible = false;
	@Input() customLogo: string;
	@Input() isOffline = false;
	@Input() buttonText;
	@Input() carrier: string;
	@Input() lob: string;
	@Input() loading: string;
	actionApiService: ActionApiService;

	descriptionId = uuidv4();

	pricePerMonth = pricePerMonth;

	constructor(actionApiService: ActionApiService) {
		this.actionApiService = actionApiService;
	}

	get displayTerm(): string {
		return this.isMonthly ? 'terms.month-short' : yearlyTermDisplayName(this.term, true);
	}

	ngOnInit(): void {}

	onButtonClick() {
		this.buttonClicked.emit();
	}
}
