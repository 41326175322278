<section class="main-content">
	<ng-container *ngIf="loaderInformation$ | async as loaderData">
		<ng-container *ngIf="loaderData?.visible && !finishFetch">
			<app-loader></app-loader>
			<app-page-heading [title]="'titles.finalizingYourQuote' | translate"></app-page-heading>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="finishFetch">
		<ng-container *ngIf="rate; else noRates">
			<app-page-heading subtitleKey="quoteConfirmation.subTitle" titleKey="quoteConfirmation.title"></app-page-heading>

			<app-coverages-details [buttonDisclaimer]="buttonDisclaimer"></app-coverages-details>
		</ng-container>
	</ng-container>

	<ng-template #noRates>
		<app-carrier-kickout></app-carrier-kickout>
	</ng-template>
</section>
