import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../base-page.component';
import { FormattedDateService } from '../../../../../services/formatted-date.service';
import { selectSecretSource, selectSource } from '../../../../../store/selectors/result-data.selectors';
import { selectAddress } from '../../../../../store/selectors/quote-data.selectors';
import { StepsEnum } from '../../../../../enums/steps.enum';
import { ActionApiService } from 'src/app/services/action-api.service';
import { selectInterviewTexts } from '../../../../../store/selectors/interview-texts.selectors';
import { selectPaymentRedirect } from '../../../../../store/selectors/payment-redirect.selector';
import { selectApplicationId } from '../../../../../store/selectors/interview-metadata.selector';

@UntilDestroy()
@Component({
	selector: 'app-success-page',
	templateUrl: './success-page.component.html',
	styleUrls: ['./success-page.component.scss'],
})
export class SuccessPageComponent extends BasePageComponent implements OnInit {
	policyStartDate;
	policyNumber;
	source: string;
	secretSource: string;
	paymentRedirect = 'https://www.boltinsurance.com/';
	isAddressEntered: boolean;
	message: string;
	backButtonOn: boolean;
	backButtonUrl: string;

	constructor(
		injector: Injector,
		protected store: Store<IAppState>,
		private formattedDateService: FormattedDateService,
		private actionApiService: ActionApiService,
		private route: ActivatedRoute,
		private router: Router,
		private location: PlatformLocation
	) {
		super(injector, store);
	}

	ngOnInit(): void {
		this.eventsService.addEvent(this.EventActions.END_SESSION);
		this.store
			.select(selectApplicationId)
			.pipe(first())
			.subscribe((appId) => {
				localStorage.setItem(`paymentStatus-${appId}`, 'success');
			});

		combineLatest([
			this.store.select(selectSource),
			this.store.select(selectSecretSource),
			this.store.select(selectAddress),
			this.store.select(selectInterviewTexts),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([source, secretSource, address, stepTexts]) => {
				this.source = source;
				this.secretSource = secretSource;
				this.isAddressEntered = !!address['PropertyAddress.AddressLine1'];
				this.title = stepTexts[this.isEscrowFlow ? StepsEnum.SUCCESS_PAGE_ESCROW : StepsEnum.SUCCESS_PAGE].title;
				this.subtitle = stepTexts[this.isEscrowFlow ? StepsEnum.SUCCESS_PAGE_ESCROW : StepsEnum.SUCCESS_PAGE].subTitle;
				this.backButtonOn =
					stepTexts[this.isEscrowFlow ? StepsEnum.SUCCESS_PAGE_ESCROW : StepsEnum.SUCCESS_PAGE].backButtonOn;
				this.backButtonUrl = this.isEscrowFlow ? stepTexts[StepsEnum.SUCCESS_PAGE_ESCROW].backButtonUrl : null;
			});

		this.formattedDateService
			.getFormattedPolicyStartDate('MM/DD/YYYY')
			.pipe(first())
			.subscribe((formattedDate) => {
				this.policyStartDate = formattedDate;
			});

		this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
			this.actionApiService
				.getTransactionId(params.transactionId, this.actionService.getSource())
				.pipe(first())
				.subscribe((data) => {
					if (data['status'] == 'Success') {
						this.policyNumber = data['additionalData']['policyNumber'];
					}
					if (data['additionalData'].message) {
						this.message = data['additionalData'].message;
					}
				});
		});

		this.store
			.select(selectPaymentRedirect)
			.pipe(untilDestroyed(this))
			.subscribe((pr) => {
				if (pr?.length) {
					this.paymentRedirect = pr;
				}
			});

		this.location.onPopState(() => {
			// if (!this.isAddressEntered) {
			if (this.secretSource) {
				this.router.navigate([this.secretSource, StepsEnum.YOUR_ADDRESS], { queryParamsHandling: 'merge' });
			} else {
				this.router.navigate([this.source, StepsEnum.YOUR_ADDRESS], { queryParamsHandling: 'merge' });
			}
			// }
		});

		if (!this.policyStartDate) {
			if (this.secretSource) {
				this.router.navigate([this.secretSource, StepsEnum.YOUR_ADDRESS], { queryParamsHandling: 'merge' });
			} else {
				this.router.navigate([this.source, StepsEnum.YOUR_ADDRESS], { queryParamsHandling: 'merge' });
			}
		}
	}

	goHomeClick() {
		window.location.href = this.backButtonUrl || this.paymentRedirect;
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, { label: 'go-home', button: 'go-home', module: 'success-module' });
	}

	downloadPDFPolicy() {
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, { label: 'download-pdf-button', button: 'download-pdf-button', module: 'success-module' });
	}
}
