import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common'

@Pipe({
	name: 'zeroOrTwoDigitsFraction',
})
export class ZeroOrTwoDigitsFraction implements PipeTransform {
	constructor(public currencyPipe: CurrencyPipe) {
	}
	transform(val: number, currency?: string): string {
		let ret;
		if (val !== undefined && val !== null) {
			if (Number.isInteger(val)) {
				ret = val;
				ret = this.currencyPipe.transform(ret, currency, 'symbol', '1.0-0')
			} else {
				ret = val.toFixed(2)
				ret = this.currencyPipe.transform(ret, currency, 'symbol', '1.2-2')
			}
			return ret;
		}
		return null;
	}
}
