<div
	*ngIf="this.formDriver"
	[formGroup]="this.formDriver"
	[ngClass]="{ notValid: !formDriver.valid && active }"
	class="accordion-block driver-accordion"
	data-status="closed"
>
	<div class="accordion-top">
		<app-image
			*ngIf="!active; else other_content"
			[name]="'driverDisabledIcon'"
			alt="null"
			height="40"
			role="presentation"
		></app-image>
		<ng-template #other_content>
			<app-image [name]="'driverActiveIcon'" alt="null" height="40" role="presentation"></app-image>
		</ng-template>

		<div class="accordion-title-wrapper">
			<div class="title">
				{{ this.formDriver.value.FirstName + " " + this.formDriver.value.LastName | uppercase }}
			</div>
			<p class="gender">
				{{
					(formDriver.value.Gender ? ("dropdowns.gender." + formDriver.value.Gender.toLowerCase() | translate) : "") +
						this.findYearOnDate(formDriver.value.DOB)
				}}
			</p>
		</div>

		<div class="accordion-actions-wrapper">
			<div *ngIf="!this.formDriver.valid && active" class="error-driver-wrapper">
				<img
					_ngcontent-hbx-c57=""
					alt="null"
					class="error-driver"
					height="20"
					role="presentation"
					src="assets/images/error-driver.svg"
				/>
			</div>

			<div *ngIf="source !== DriverTypeEnum.Primary && active" class="button-wrapper">
				<button (click)="editClick(false)" [disabled]="!active">
					<span [ngClass]="{ notValid: !this.formDriver.valid }" class="edit-text">{{
						"titles.editDetails" | translate
					}}</span>
					<i
						[ngClass]="{ error: !this.formDriver.valid }"
						appHiddenWhenCssDisabled
						class="material-icons edit"
						role="presentation"
					>
						edit
					</i>
				</button>
			</div>

			<p class="driver-type">
				{{ driverType ? ("driverTypes." + driverType | translate) : null }}
			</p>

			<toggle-slider
				(parentFun)="handleToggle($event, source)"
				*ngIf="source !== DriverTypeEnum.Primary"
				[accessibilityLabel]="this.formDriver.value.FirstName + ' ' + this.formDriver.value.LastName"
				[disabled]="!active && disabledToggle"
				[value]="active"
			></toggle-slider>
		</div>
	</div>
</div>
