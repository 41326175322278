import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickEventDirective } from './click-event.directive';
import { HiddenWhenCssDisabledDirective } from './hidden-when-css-disabled.directive';
import { TextUnmaskingPipe } from '../pipes/text-unmasking.pipe';
import { TranslationDirective } from './translation.directive';
import { FeatureFlagDirective } from './feature-flag.directive';
import { ToggleTooltipDirective } from './toggle-tooltip.directive'; 

@NgModule({
	declarations: [ClickEventDirective, HiddenWhenCssDisabledDirective, TextUnmaskingPipe, TranslationDirective, FeatureFlagDirective, ToggleTooltipDirective],
	exports: [ClickEventDirective, HiddenWhenCssDisabledDirective, TextUnmaskingPipe, TranslationDirective, FeatureFlagDirective, ToggleTooltipDirective],
	imports: [CommonModule],
})
export class DirectivesModule {}
