import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextButtonComponent } from './next-button.component';
import { DirectivesModule } from '../../directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [NextButtonComponent],
	imports: [CommonModule, DirectivesModule, TranslateModule],
	exports: [NextButtonComponent],
})
export class NextButtonModule {}
