import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page.component';
import { QuoteDataService } from '../../../../../services/quote-data.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../store/states/app.state';
import { ActionApiService } from '../../../../../services/action-api.service';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { selectQuoteData } from '../../../../../store/selectors/quote-data.selectors';
import { Validations } from '../../../../../utils/validation';
import { v4 as uuidv4 } from 'uuid';
import { Location } from '@angular/common';
import moment from 'moment/moment';

const MAX_NUMBER_OF_PETS_ENABLED = 3;
const MAX_NUMBER_OF_PETS = 10;

@Component({
	selector: 'app-pets',
	templateUrl: './pets.component.html',
	styleUrls: ['./pets.component.scss'],
})
export class PetsComponent extends BasePageComponent implements OnInit {
	maxNumberOfPetsEnabled = MAX_NUMBER_OF_PETS_ENABLED;
	maxNumberOfPets = MAX_NUMBER_OF_PETS;
	isLoading = false;
	actionApiService: ActionApiService;
	SequenceNum = 0;
	isAddEditPetMode = false;
	editedPetForm: FormGroup;
	editedPetFormPreviousValue: any;

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private location: Location,
		actionApiService: ActionApiService
	) {
		super(injector, store, quoteDataService);
		this.actionApiService = actionApiService;
	}

	get petsFormControl(): FormArray {
		return this.form.controls.ManualPets as FormArray;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();
		this.registerOnChange(this.form);

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				let pets = [];

				if (quoteData.Pets && quoteData.Pets.length) {
					pets = [...quoteData.Pets];
				}

				if (quoteData.ManualPets && quoteData.ManualPets.length) {
					pets = [...pets, ...quoteData.ManualPets];
				}

				if (pets.length) {
					if (pets.length === 1 && !pets[0].completed) {
						const formGroupItem = this.createFormControl(pets[0]);
						this.petsFormControl.push(formGroupItem);
						this.openPetFormForEdit(this.petsFormControl.controls[0]);
					} else {
						pets
							.filter((petItem) => petItem.completed)
							.forEach((item) => {
								if (item) {
									const formGroupItem = this.createFormControl(item);
									this.petsFormControl.push(formGroupItem);

									if (item.Id === quoteData.editedPetId) {
										this.openPetFormForEdit(formGroupItem);
									}
								}
							});
					}
				} else {
					this.addPetFormAndOpenForEdit();
				}
			});
	}

	isOnePetForm() {
		return this.petsFormControl.controls.length === 1;
	}

	addEmptyPetFormGroup() {
		const formGroupItem = this.createFormControl({});
		this.petsFormControl.push(formGroupItem);
	}

	addPetFormAndOpenForEdit() {
		this.addEmptyPetFormGroup();
		this.openPetFormForEdit(this.petsFormControl.controls[this.petsFormControl.controls.length - 1]);
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'add-pet',
			button: 'add-pet',
			module: 'pets-module',
		});
	}

	openPetFormForEdit(petForm: AbstractControl, resetInternalStep?: boolean) {
		this.isAddEditPetMode = true;
		this.editedPetFormPreviousValue = petForm.value;
		this.editedPetForm = petForm as FormGroup;
		if (resetInternalStep) {
			this.editedPetForm.controls.internalStep.setValue(0);
		}
		this.form.controls.editedPetId.setValue(petForm.value.Id);
	}

	onPetFormCompleted() {
		this.editedPetForm.controls.enabled.setValue(!this.cantEnable());
		this.closePetForm();
	}

	onPetFormCancelled() {
		if (this.editedPetForm.value.completed) {
			// if completed is true, it means that the user has completed adding a pet previously but didn't finish the edit process later
			// should restore the previous form value
			this.editedPetForm.setValue(this.editedPetFormPreviousValue);
		} else {
			// if there are NO completed pet forms, redirect to previous screen
			if (this.isOnePetForm()) {
				this.location.back();
			} else {
				// if completed is not true, it means that the user never finished the form
				// should delete the petForm that was newly added
				this.petsFormControl.removeAt(this.petsFormControl.controls.length - 1);
			}
		}

		this.closePetForm();
	}

	closePetForm() {
		this.isAddEditPetMode = false;
		this.editedPetForm = null;
		this.form.controls.editedPetId.setValue(null);
	}

	cantAdd() {
		return this.getActiveFields(this.petsFormControl).length >= MAX_NUMBER_OF_PETS;
	}

	cantEnable() {
		return (
			this.getActiveFields(this.petsFormControl).filter((vehicleFormGroup) => vehicleFormGroup.value.enabled).length >=
			this.maxNumberOfPetsEnabled
		);
	}

	getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
		let hasError = false;
		const result = Object.keys(form.controls).reduce((acc, key) => {
			const control = form.controls[key];
			const errors =
				control instanceof FormGroup || control instanceof FormArray ? this.getAllErrors(control) : control.errors;
			// will show tooltip if there is errors
			if (key === 'newListing') {
				control.patchValue(false);
			}
			if (errors) {
				control.markAllAsTouched();
				acc[key] = errors;
				hasError = true;
			}
			return acc;
		}, {} as { [key: string]: any });
		return hasError ? result : null;
	}

	getActiveFormErrors() {
		const errors = [];
		this.getActiveFields(this.petsFormControl).forEach((item) => {
			const error = this.getAllErrors(item);
			if (error) {
				errors.push({ error });
			}
		});

		return errors;
	}

	handleEdit(petFormGroup: FormGroup) {
		this.openPetFormForEdit(petFormGroup, true);
	}

	handleContinue() {
		const payload = { Pets: this.modifyPetsPayload(this.petsFormControl.value) };

		// Updates the quote
		this.actionApiService
			.updateApplication(payload)
			.pipe(first())
			.subscribe(() => {
				// when update is finished call to lexis nexis
				this.progressMeterService.goToNextPage();
			});
	}

	continue() {
		this.isLoading = true;
		const errors = this.getActiveFormErrors();
		if (errors && Object.keys(errors).length > 0) {
			const errorElement: any = document.querySelector('.error');
			if (errorElement) {
				// scroll to element
				errorElement.closest('.accordion-block').dataset.status = 'closed';
				errorElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			}
			this.isLoading = false;
		}
	}

	isAtLeastOnePetEnabled() {
		for (const key of Object.keys(this.petsFormControl.controls)) {
			if (this.petsFormControl.controls[key].value.enabled === true) {
				return true;
			}
		}
		return false;
	}

	createFormControl(item): FormGroup {
		const id = uuidv4();
		const data = {
			Id: item.Id || id,
			SequenceNum: this.SequenceNum++,
			enabled: [item.enabled],
			completed: [item.completed],
			internalStep: [item.internalStep || 0],
			PLPetSpecies: [item.PLPetSpecies || null, [Validators.required]],
			PLPetName: [item.PLPetName || null, [Validators.required]],
			PLPetBreedType: [item.PLPetBreedType || null, [Validators.required]],
			PLPetBreed: [item.PLPetBreed || null, [Validators.required]],
			PLPetGender: [item.PLPetGender || null, [Validators.required]],
			PetDOB: [
				item.PetDOB ? moment(item.PetDOB, 'MM/DD/YYYY').format('MM/YYYY') : null,
				[Validators.required, Validations.petDOB],
			],
			PLSpayedNeutered: [item.PLSpayedNeutered !== undefined ? item.PLSpayedNeutered : null, [Validators.required]],
			PLHasDisease: [item.PLHasDisease !== undefined ? item.PLHasDisease : null, [Validators.required]],
		};

		return this.formBuilder.group(data) as FormGroup;
	}

	getActiveFields(form) {
		if (form.controls.length === 0) {
			return [];
		}
		return form.controls?.filter((item) => item.value.SequenceNum > -1);
	}

	getPageTitle(): string {
		return this.isAddEditPetMode ? 'titles.tellAboutPet' : 'titles.addAnotherPet';
	}

	private modifyPetsPayload(petsData: any) {
		return petsData.map(({ enabled, completed, internalStep, ...petsDataItem }) => {
			if (!enabled) {
				petsDataItem.SequenceNum = -1;
			}
			return petsDataItem;
		});
	}

	private initForm() {
		this.form = new FormGroup({
			ManualPets: new FormArray([]),
			editedPetId: new FormControl(''),
		});
	}
}
