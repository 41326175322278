import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { setFinalStep } from 'src/app/store/actions/progress-meter.actions';
import { setIsQuoteCompleted } from 'src/app/store/actions/result-data.actions';
import { AppService } from '../../../../../services/app.service';

@Component({
	selector: 'app-payment-result',
	templateUrl: './payment-result.component.html',
	styleUrls: ['./payment-result.component.scss'],
})
export class PaymentResultComponent implements OnInit, AfterViewInit {
	isEscrow: boolean;

	constructor(
		public route: ActivatedRoute,
		private store: Store,
		private progressMeterService: ProgressMeterService,
		private appService: AppService
	) {
		window.onbeforeunload = () => {
			// Once the interview is successfully complete, the data should be cleared on navigation.
			// If the user tries to navigate back, they will be redirected to the first page
			appService.clearData();
		};
	}

	ngOnInit(): void {
		this.isEscrow = this.progressMeterService.isEscrowFlow();
	}

	ngAfterViewInit(): void {
		const state = this.route.snapshot.data.status;
		if (this.route.snapshot.data.status === 'success') {
			this.store.dispatch(setIsQuoteCompleted({ data: true }));
		}
		this.store.dispatch(setFinalStep(state));
	}
}
