import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';

import { EventActions } from '../../../../../../models/event-data.model';
import { GtmControlsActions } from '../../../../../../models/gtm-controls-actions.enum';
import { firstLetterToLowerCase } from 'src/app/utils/general.utils';
import { EventsService } from 'src/app/services/events.service';

@UntilDestroy()
@Component({
	selector: 'app-radio-buttons',
	templateUrl: './radio-buttons.component.html',
	styleUrls: ['./radio-buttons.component.scss'],
})
export class RadioButtonsComponent implements OnInit {
	@Output() handleSelect = new EventEmitter();
	@Input() selectedOption: string;
	@Input() options;
	@Input() name;
	@Input() lobSelectionPage: boolean;

	isPrefilled = false;

	constructor(private store: Store, private eventsService: EventsService) { }

	ngOnInit(): void {
		this.displayAction();

		this.options.forEach((option) => {
			if (option.value === this.selectedOption) {
				this.prefillEdit(option);
			}
		});
	}

	handleChooseOption(option) {
		this.selectedOption = option;
		this.handleSelect.emit(option);
		this.editAction(option);
	}

	getFileName(optionName: string, isChecked: boolean): string {
		const fileName = firstLetterToLowerCase(optionName);
		return isChecked ? fileName + 'Checked' : fileName;
	}

	private displayAction(): void {
		this.eventsService.addEvent(EventActions.DISPLAY, { label: this.getLabelName(GtmControlsActions.DISPLAY) });
	}

	private prefillEdit(option) {
		this.eventsService.addEvent(EventActions.PREFILL, { label: this.getLabelName(GtmControlsActions.PREFILL), value: option });
		this.isPrefilled = true;
	}

	private editAction(option) {
		const controlAction = this.isPrefilled ? 'PREFILL_EDIT' : 'EDIT';
		this.eventsService.addEvent(EventActions.EDIT, { label: this.getLabelName(GtmControlsActions[controlAction]), value: option });
	}

	private getLabelName(controlAction: GtmControlsActions): string {
		return `${this.name}_${controlAction}`;
	}
}
