import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { hidePopupAction, showPopupAction } from 'src/app/store/actions/popup.actions';
import { RoutingService } from 'src/app/services/routing.service';
import { EventActions } from '../../../../../models/event-data.model';
import { selectSecretSource, selectSource } from '../../../../../store/selectors/result-data.selectors';
import { combineLatest } from 'rxjs';
import { LobService } from 'src/app/services/lob.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { selectApplicationId } from '../../../../../store/selectors/interview-metadata.selector';
import { selectInterviewTexts } from '../../../../../store/selectors/interview-texts.selectors';
import { StepsEnum } from '../../../../../enums/steps.enum';

@UntilDestroy()
@Component({
	selector: 'app-payment-failed',
	templateUrl: './payment-failed.component.html',
	styleUrls: ['./payment-failed.component.scss'],
})
export class PaymentFailedComponent implements OnInit {
	eventActions = EventActions;
	lob: string;
	backButtonOn: boolean;
	backButtonUrl: string;
	callAgentButtonOn: boolean;
	callAgentButtonUrl: string;

	constructor(
		private store: Store,
		public routingService: RoutingService,
		private getLobService: LobService,
		private progressMeterService: ProgressMeterService
	) {}

	ngOnInit(): void {
		this.store
			.select(selectInterviewTexts)
			.pipe(untilDestroyed(this))
			.subscribe((stepTexts) => {
				this.backButtonOn = stepTexts[StepsEnum.PAYMENT_FAILED].backButtonOn;
				this.backButtonUrl = stepTexts[StepsEnum.PAYMENT_FAILED].backButtonUrl;
				this.callAgentButtonOn = stepTexts[StepsEnum.PAYMENT_FAILED].callAgentButtonOn;
				this.callAgentButtonUrl = stepTexts[StepsEnum.PAYMENT_FAILED].callAgentButtonUrl;
			});

		this.store
			.select(selectApplicationId)
			.pipe(first())
			.subscribe((appId) => {
				localStorage.setItem(`paymentStatus-${appId}`, 'failed');
			});

		if (this.isInIframe()) {
			window.parent.postMessage({ action: 'hidePopupActionAndRedirectToPaymentFailed' }, window.origin);
			return;
		} else {
			this.getLobService
				.getLob$()
				.pipe(first())
				.subscribe((lob) => {
					this.lob = lob;
				});
		}
	}

	openPopup() {
		if (this.callAgentButtonUrl) {
			this.routingService.navigateToExternal(this.callAgentButtonUrl, true);
		} else {
			this.store.dispatch(
				showPopupAction({
					componentName: 'contact-agent',
					payload: { text: 'popups.titles.contact-agent', gtmLabel: 'ContactAgentPopUp' },
				})
			);
		}
	}

	handleTryAgain() {
		this.store.dispatch(hidePopupAction());
		if (this.backButtonUrl) {
			this.routingService.navigateToExternal(this.backButtonUrl, true);
		} else {
			combineLatest([this.store.select(selectSource), this.store.select(selectSecretSource)])
				.pipe(first())
				.subscribe(([source, secretSource]) => {
					if (secretSource) {
						this.routingService.navigateToRoute(
							this.progressMeterService.getRouteName(this.lob) + '/' + 'payment-plan'
						);
					} else {
						this.routingService.navigateToRoute(
							this.progressMeterService.getRouteName(this.lob) + '/' + 'payment-plan'
						);
					}
				});
		}
	}

	isInIframe(): boolean {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}
}
