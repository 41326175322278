import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'rateNumber',
})
export class RateNumberPipe implements PipeTransform {
	transform(val: number): number {
		if (val !== undefined && val !== null) {
			return Math.floor(val);
		}
		return null;
	}
}
