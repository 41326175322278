import { Component, OnInit } from '@angular/core';
import { SectionBaseComponent } from '../section-base.component';
import { ConfigService } from '../../../../../../../services/config.service';

@Component({
	selector: 'app-video',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.scss'],
	inputs: ['section'],
})
export class VideoComponent extends SectionBaseComponent implements OnInit {
	order: string;
	videoApiUrl: string;

	constructor(private config: ConfigService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.videoApiUrl = this.config.getConfig().videoStorageUrl;

		this.order = this.theme.texts[this.section.name].order;
		this.url =
			this.theme.texts[this.section.name].videoUrl && this.videoApiUrl
				? `${this.videoApiUrl}${this.theme.texts[this.section.name].videoUrl}`
				: null;
	}
}
