import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phone',
})
export class PhonePipe implements PipeTransform {
	constructor() {}

	transform(value: string): string {
		const phone = value.replace(/[^0-9.]/g, '');

		return phone?.length ? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}` : phone;
	}
}
