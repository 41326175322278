import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ConfirmationEmailService {
	constructor() {}

	getConfirmationEmailSentNote(email: string, isResend?: boolean) {
		return {
			noteType: 'System Note',
			noteSubject: `Confirmation Email${isResend ? ' re-send' : ''} requested`,
			noteDescription: `Confirmation Email request received with the following details:
- Email: ${email}`,
		};
	}
}
