<section class="main-content">
	<app-page-heading
		[subtitle]="'titles.continueWithLastLeftQuote' | translate"
		[title]="'titles.goodToSeeYouBack' | translate"
	></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="form-wrapper">
		<div class="row">
			<app-question-wrapper
				[show]="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
				class="first-name"
				icon="person"
			>
				<unmasked-input
					*ngIf="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
					[name]="Fields.FirstName.name"
					[pattern]="Fields.FirstName.pattern"
					[placeholder]="'personalInfo.firstName' | translate"
					maxlength="50"
					type="text"
				>
				</unmasked-input>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.LastName.name) && form.get(Fields.LastName.name).enabled"
				class="last-name"
				icon="person"
			>
				<unmasked-input
					[name]="Fields.LastName.name"
					[pattern]="Fields.LastName.pattern"
					[placeholder]="'personalInfo.lastName' | translate"
					maxlength="30"
					type="text"
				></unmasked-input>
			</app-question-wrapper>
		</div>
		<div class="row">
			<app-question-wrapper
				[show]="form.get(Fields.DateOfBirth.name) && form.get(Fields.DateOfBirth.name).enabled"
				class="date-of-birth"
				icon="calendar_today"
			>
				<masked-input
					[inputMask]="'**/**/9999'"
					[name]="Fields.DateOfBirth.name"
					[placeholder]="'personalInfo.dateOfBirth' | translate"
					class="date-input"
				>
				</masked-input>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.Email.name) && form.get(Fields.Email.name).enabled"
				class="email"
				icon="email"
			>
				<unmasked-input
					[name]="Fields.Email.name"
					[pattern]="Fields.Email.pattern"
					[placeholder]="'personalInfo.email' | translate"
					[subLabel]="'labels.emailQuoteAlert' | translate"
					[tabindex]="form.get(Fields.Email.name) && form.get(Fields.Email.name).enabled ? null : -1"
					type="email"
				>
				</unmasked-input>
			</app-question-wrapper>
		</div>
		<div *ngIf="serverResponse?.notFound" class="not-found">
			<span [innerHTML]="'titles.didntFindQuote' | translate"></span>
		</div>
		<div *ngIf="serverResponse?.forbidden" class="in-use">
			<span [innerHTML]="'titles.quoteAlreadyAssigned' | translate"></span>
			<span *ngIf="phoneNumber">{{ " " }}{{ "labels.contactUsAt" | translate }}{{ " " }} {{ phoneNumber }}</span>
		</div>
		<next-button
			(clicked)="handleContinue()"
			(validationFailed)="handleValidation($event)"
			[disabled]="!form.valid"
			[myForm]="myform"
			[title]="'buttons.retrieve' | translate"
			continue="true"
		></next-button>
		<button class="new-quote-button" routerLink="../your-address">{{ "buttons.startNewQuote" | translate }}</button>
	</form>
</section>
