import { Component, OnInit } from '@angular/core';

import { RepeatedTextModel } from '../../../../../../../models/landing-section.model';
import { GeneralPropsEnum } from '../../../../../../../models/general-props.enum';
import { SectionBaseComponent } from '../section-base.component';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent extends SectionBaseComponent implements OnInit {
	faqs: RepeatedTextModel[];

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.faqs = this.section[GeneralPropsEnum.repeatedTexts];
	}

	getText(item: { text: string }): string {
		if (item.hasOwnProperty(`text${this.lang}`)) {
			return item[`text${this.lang}`];
		}

		return item.text;
	}
}
