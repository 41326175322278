<section class="main-content">
	<app-page-heading [title]="'titles.connectWithAgent' | translate"></app-page-heading>
</section>

<div class="content">
	<div>
		<div class="fisrt-column-header">
			<div>{{ "labels.yourDetails" | translate }}</div>
			<div>i</div>
		</div>
		<app-your-details></app-your-details>
	</div>
	<div class="separator"></div>
	<div>
		<div class="second-column-header">{{ "labels.offerDetails" | translate }}</div>
		<app-carrier-offer-card></app-carrier-offer-card>
	</div>
</div>
