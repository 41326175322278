<ng-container [formGroup]="controlContainer.control">
	<div class="address-container">
		<div *ngFor="let addrField of addressFields; let i = index; first as isFirst">
			<error-message
				[fieldDisplayName]="postfix || addrField"
				[field]="controlContainer.control.controls[addrField]"
				[isFocus$]="focus$"
			>
			</error-message>
		</div>
		<app-label *ngIf="label" [label]="label"></app-label>
		<div class="address-wrapper">
			<div class="first">
				<div class="addr-question-wrapper street-addr-block">
					<unmasked-input
						(isOnFocus)="focusChange($event)"
						[autocomplete]="false"
						[name]="addressLine1"
						[pattern]="'^[A-Za-z\ 0-9\-\'\,\.\d]*$'"
						[tabindex]="tabindex"
						maxlength="50"
						[placeholder]="'address.streetNumberAndName' | translate"
						type="text"
						withoutErrorMessage="true"
					>
					</unmasked-input>
				</div>

				<div class="addr-question-wrapper city-addr-block">
					<unmasked-input
						(isOnFocus)="focusChange($event)"
						[autocomplete]="false"
						[name]="addressLine2"
						[pattern]="'^[A-Za-z\ 0-9\-\'\,\.\d]*$'"
						[tabindex]="tabindex"
						maxlength="30"
						[placeholder]="'address.aptUnit' | translate"
						type="text"
						withoutErrorMessage="true"
					>
					</unmasked-input>

					<unmasked-input
						(isOnFocus)="focusChange($event)"
						[name]="city"
						[pattern]="'^[a-zA-Z]*$'"
						[tabindex]="tabindex"
						[labelInsideField]="'address.city' | translate"
						maxlength="25"
						[placeholder]="'address.city' | translate"
						withoutErrorMessage="true"
					>
					</unmasked-input>
				</div>
			</div>
			<div class="addr-question-wrapper state-zip-block">
				<unmasked-input
					(isOnFocus)="focusChange($event)"
					[name]="state"
					[pattern]="'^[a-zA-Z]*$'"
					[tabindex]="tabindex"
					[labelInsideField]="'address.state' | translate"
					maxlength="2"
					[placeholder]="'address.state' | translate"
					withoutErrorMessage="true"
				>
				</unmasked-input>

				<unmasked-input
					(isOnFocus)="focusChange($event)"
					[autocomplete]="false"
					[name]="zipCode"
					[pattern]="'^[0-9]*$'"
					[tabindex]="tabindex"
					maxlength="5"
					[placeholder]="'address.zipCode' | translate"
					type="tel"
					withoutErrorMessage="true"
				>
				</unmasked-input>
			</div>
		</div>
	</div>
</ng-container>
