<div class="your-details-comp-main">
	<form #myform="ngForm" [formGroup]="form" class="applicant-form stage">
		<div class="Payment_plan_container">
			<div class="sub-header">{{ "labels.paymentPlan" | translate }}</div>
			<div
				[ngClass]="{ show: form.get(Fields.PaymentPlan.name) && form.get(Fields.PaymentPlan.name).enabled }"
				class="question-wrapper last"
			>
				<app-dropdown
					[labelInsideField]="'labels.paymentPlan' | translate"
					[name]="Fields.PaymentPlan.name"
					[options]="FieldOptions.PaymentPlanOptions"
					[placeholder]="'labels.chooseOption' | translate"
					[tabindex]="form.get(Fields.PaymentPlan.name) && form.get(Fields.PaymentPlan.name).enabled ? 0 : -1"
				>
				</app-dropdown>
			</div>
		</div>

		<hr />

		<div class="Billing_info_container">
			<div class="sub-header">{{ "labels.billingInfo" | translate }}</div>

			<div class="first-name-last-name-container">
				<div class="question-wrapper first-name field-inline show">
					<div [ngClass]="{ show: form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled }">
						<unmasked-input
							*ngIf="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
							[name]="Fields.FirstName.name"
							[pattern]="Fields.FirstName.pattern"
							[placeholder]="'personalInfo.firstName' | translate"
							maxlength="50"
							type="text"
						>
						</unmasked-input>
					</div>
				</div>

				<div class="question-wrapper last-name field-inline show">
					<div [ngClass]="{ show: form.get(Fields.LastName.name) && form.get(Fields.LastName.name).enabled }">
						<unmasked-input
							*ngIf="form.get(Fields.LastName.name) && form.get(Fields.LastName.name).enabled"
							[name]="Fields.LastName.name"
							[pattern]="Fields.LastName.pattern"
							[placeholder]="'personalInfo.lastName' | translate"
							maxlength="50"
							type="text"
						>
						</unmasked-input>
					</div>
				</div>
			</div>
		</div>

		<div
			*ngIf="
				form.controls[Fields.PropertyAddressAddressLine1.name] &&
				form.controls[Fields.PropertyAddressAddressLine1.name].enabled
			"
		>
			<app-address-suggestion
				(addressChangedEvent)="handleAddressChange()"
				[addressLine1]="form.controls[Fields.PropertyAddressAddressLine1.name].value"
				[addressLine2]="form.controls[Fields.PropertyAddressAddressLine2.name].value"
				[city]="form.controls[Fields.PropertyAddressCity.name].value"
				[county]="form.controls[Fields.PropertyAddressCounty.name].value"
				[fieldsNames]="fieldsNames"
				[form]="form"
				[placeholder]="'address.suggestion.address' | translate"
				[state]="form.controls[Fields.PropertyAddressState.name].value"
				[toShowMap]="false"
				[zipCode]="form.controls[Fields.PropertyAddressZipCode.name].value"
				addressType="address"
			></app-address-suggestion>
			<error-message
				[field]="form.controls[Fields.PropertyAddressAddressLine1.name].value"
				[isFocus$]="true"
			></error-message>
		</div>

		<hr />

		<div class="Paymens_info_container">
			<div class="sub-header">{{ "labels.paymentInfo" | translate }}</div>

			<div class="question-wrapper first-name field-inline show">
				<div [ngClass]="{ show: form.get(Fields.CardNumber.name) && form.get(Fields.CardNumber.name).enabled }">
					<masked-input
						*ngIf="form.get(Fields.CardNumber.name) && form.get(Fields.CardNumber.name).enabled"
						[inputMask]="'9999 9999 9999 9999'"
						[name]="Fields.CardNumber.name"
						[placeholder]="'personalInfo.cardNumber' | translate"
						class="date-input"
						type="tel"
					>
					</masked-input>
				</div>
			</div>

			<div class="exp-date-cvc-container">
				<div class="question-wrapper first-name field-inline show">
					<div
						[ngClass]="{ show: form.get(Fields.ExpirationDate.name) && form.get(Fields.ExpirationDate.name).enabled }"
					>
						<masked-input
							[inputMask]="'99/99'"
							[name]="Fields.ExpirationDate.name"
							[placeholder]="'labels.monthYearFormat' | translate"
							class="date-input"
							type="tel"
						>
						</masked-input>
					</div>
				</div>

				<div class="question-wrapper last-name field-inline show">
					<div [ngClass]="{ show: form.get(Fields.CVCNumber.name) && form.get(Fields.CVCNumber.name).enabled }">
						<unmasked-input
							*ngIf="form.get(Fields.CVCNumber.name) && form.get(Fields.CVCNumber.name).enabled"
							[name]="Fields.CVCNumber.name"
							[pattern]="Fields.CVCNumber.pattern"
							[placeholder]="'labels.cvcNumber' | translate"
							maxlength="3"
							type="text"
						>
						</unmasked-input>
					</div>
				</div>
			</div>
		</div>
	</form>

	<button (click)="handleSendClick()" class="app-button button-agent-card" tabindex="0">
		<span class="title">
			{{ "labels.payInFull" | translate }}
			<i appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
		</span>
	</button>
</div>
