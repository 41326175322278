import { Component } from '@angular/core';

import { ImageItemModel } from '../../../../../../../models/landing-section.model';
import { GeneralPropsEnum } from '../../../../../../../models/general-props.enum';
import { SectionBaseComponent } from '../section-base.component';

@Component({
	selector: 'app-carriers',
	templateUrl: './carriers.component.html',
	styleUrls: ['./carriers.component.scss'],
	inputs: ['section', 'images'],
})
export class CarriersComponent extends SectionBaseComponent {
	constructor() {
		super();
	}

	get carList(): ImageItemModel[] {
		return this.section[GeneralPropsEnum.imagesList];
	}
}
