import { Component } from '@angular/core';
import { SectionBaseComponent } from '../section-base.component';

@Component({
	selector: 'app-who-we-are',
	templateUrl: './who-we-are.component.html',
	styleUrls: ['./who-we-are.component.scss'],
})
export class WhoWeAreComponent extends SectionBaseComponent {
	constructor() {
		super();
	}

	linkClick(url: string) {
		if (url) {
			window.open(url, '_blank').focus();

			return;
		}

		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
}
