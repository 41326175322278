<div [class.multilob]="pm?.isMultiLob" class="pm-flow">
	<div *ngFor="let group of groupedSteps; trackBy: id; let i = index" [class.active]="group.active" class="step-group">
		<div class="progress">
			<div [ngStyle]="{ width: group.progress + '%' }" class="fill"></div>
		</div>
		<div class="title">
			{{ "steps." + group.name | translate }}
		</div>
	</div>
</div>
