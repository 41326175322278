import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../store/states/app.state';
import {
	selectApplicationId,
	selectInterviewMetadata,
	selectIsConfirmationEmailQuoteNoteSent,
} from '../../../../../store/selectors/interview-metadata.selector';
import { IInterviewMetadataState } from '../../../../../store/states/interview-metadata.state';
import { first } from 'rxjs/operators';
import { ConfirmationEmailService } from '../../../../../services/confirmation-email.service';
import { combineLatest } from 'rxjs';
import { selectQuoteData } from '../../../../../store/selectors/quote-data.selectors';
import { IQuoteDataState } from '../../../../../store/states/quote-data.state';
import { ActionApiService } from '../../../../../services/action-api.service';
import { UpdateInterviewMetadata } from '../../../../../store/actions/interview-metadata.actions';

const RESEND_TIMEOUT = 60000;

@UntilDestroy()
@Component({
	selector: 'app-almost-there',
	templateUrl: './confirmation-email-sent.component.html',
	styleUrls: ['./confirmation-email-sent.component.scss'],
})
export class ConfirmationEmailSentComponent implements OnInit, AfterViewInit {
	interviewMetadata: IInterviewMetadataState;
	quoteData: IQuoteDataState;
	applicationId: string;
	isResendEnabled = false;
	countdownSeconds: number;
	countdownInterval: any;
	private isConfirmationEmailQuoteNoteSent: any;

	constructor(
		protected store: Store<IAppState>,
		private confirmationEmailService: ConfirmationEmailService,
		private actionApiService: ActionApiService
	) {}

	ngOnInit(): void {
		combineLatest([
			this.store.select(selectInterviewMetadata),
			this.store.select(selectQuoteData),
			this.store.select(selectApplicationId),
			this.store.select(selectIsConfirmationEmailQuoteNoteSent),
		])
			.pipe(first())
			.subscribe(([interviewMetadata, quoteData, applicationId, isConfirmationEmailQuoteNoteSent]) => {
				this.interviewMetadata = interviewMetadata;
				this.quoteData = quoteData;
				this.applicationId = applicationId;

				if (!this.interviewMetadata.isConfirmationEmailSentDisplayed) {
					this.store.dispatch(UpdateInterviewMetadata({ data: { isConfirmationEmailSentDisplayed: true } }));
					this.actionApiService.patchPolicyAttachment({ isConfirmationEmailSentDisplayed: true });
				}
				this.isConfirmationEmailQuoteNoteSent = isConfirmationEmailQuoteNoteSent;
			});

		this.sendConfirmationEmail();
	}

	ngAfterViewInit() {
		this.startResendEmailCountdown();
	}

	startResendEmailCountdown() {
		this.countdownSeconds = RESEND_TIMEOUT / 1000;

		this.countdownInterval = setInterval(() => {
			--this.countdownSeconds;
			if (this.countdownSeconds <= 0) {
				clearInterval(this.countdownInterval);
				this.isResendEnabled = true;
			}
		}, 1000);
	}

	sendConfirmationEmail(event?) {
		if (event) {
			event.preventDefault();
			this.isResendEnabled = false;

			this.startResendEmailCountdown();
		}

		const isResend = !!event;

		if (!this.isConfirmationEmailQuoteNoteSent || isResend) {
			return this.actionApiService
				.createQuoteNote({
					tenant: sessionStorage.getItem('tenant'),
					applicationId: this.applicationId,
					...this.confirmationEmailService.getConfirmationEmailSentNote(this.quoteData.Email, isResend),
				})
				.pipe(first())
				.subscribe((res) => {
					this.store.dispatch(UpdateInterviewMetadata({ data: { isConfirmationEmailQuoteNoteSent: true } }));
				});
		}
	}
}
