import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Fields } from '../../../../../../constants/fields';
import { Validations } from 'src/app/utils/validation';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../../base-page.component';
import { handleIncompleteAddressInAddressForm } from '../../../../../../utils/general.utils';

@Component({
	selector: 'app-your-details',
	templateUrl: './your-details.component.html',
	styleUrls: ['./your-details.component.scss'],
})
export class YourDetailsComponent extends BasePageComponent implements OnInit {
	form: FormGroup;
	fieldsNames = {
		AddressLine1: 'PropertyAddress.AddressLine1',
		AddressLine2: 'PropertyAddress.AddressLine2',
		City: 'PropertyAddress.City',
		State: 'PropertyAddress.State',
		ZipCode: 'PropertyAddress.ZipCode',
		County: 'PropertyAddress.County',
	};

	constructor(injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initform();
	}

	initform() {
		this.form = new FormGroup({}, null, null);

		this.form.addControl(
			Fields.FirstName.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.LastName.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.PropertyAddressAddressLine1.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(50),
				Validations.isPoBox,
			])
		);

		this.form.addControl(
			Fields.PropertyAddressAddressLine2.name,
			new FormControl({ value: null, disabled: false }, [Validators.maxLength(50)])
		);

		this.form.addControl(
			Fields.PropertyAddressCity.name,
			new FormControl({ value: null, disabled: false }, [Validators.required, Validators.maxLength(25)])
		);

		this.form.addControl(
			Fields.PropertyAddressState.name,
			new FormControl({ value: null, disabled: false }, [Validators.required, Validators.maxLength(2)])
		);

		this.form.addControl(
			Fields.PropertyAddressZipCode.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(5),
				Validations.zipStrictLength(5),
			])
		);
		this.form.addControl(Fields.PropertyAddressCounty.name, new FormControl({ value: null, disabled: false }, []));

		this.form.addControl(
			Fields.CardNumber.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.ExpirationDate.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.CVCNumber.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.PaymentPlan.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);
	}

	handleSendClick() {
		console.log(this.form.value);
	}

	handleAddressChange() {
		handleIncompleteAddressInAddressForm(this.form);
	}
}
