<div *ngIf="showMap && toShowMap" id="map"></div>
<app-question-wrapper>
	<div
		[ngClass]="{
			'with-placeholder': placeholder,
			'has-value': value,
			error: error || controlContainer.control.controls[addressFormName]?.error,
			disabled: disabled
		}"
		class="input-container address-suggestion address-field-container"
	>
		<div class="input-field address-field">
			<label
				[for]="addressAutoComplete"
				[ngClass]="{ 'has-value': value, noIcon: showIcon == false }"
				class="inner-label"
				>{{ placeholder | translate }}</label
			>
			<i *ngIf="showIcon !== false" appHiddenWhenCssDisabled class="material-icons" role="presentation">
				location_on
			</i>
			<input
				#placesRef
				(focus)="onFocus()"
				(keyup)="validateNumsLettersSpace(this)"
				(onAddressChange)="handleAddressChange($event)"
				[(ngModel)]="value"
				[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
				[attr.aria-invalid]="isInvalid() ? true : null"
				[id]="addressAutoComplete"
				[maxlength]="maxLength"
				[minlength]="minlength"
				[ngClass]="{
					error: isInvalid() || controlContainer.control.controls[addressFormName]?.error,
					focus: (isFocus$ | async),
					hasvalue: value,
					noIcon: showIcon == false
				}"
				[attr.options]="options"
				[placeholder]="''"
				[tabindex]="tabindex ? tabindex : 0"
				class="unmasked-input"
				ngx-google-places-autocomplete
				tabindex="0"
				type="text"
			/>
		</div>
	</div>
	<div
		[ngClass]="{
			'with-placeholder': placeholder,
			'has-value': valueUnits,
			error: error || controlContainer.control.controls[addressFormName]?.error,
			disabled: disabled
		}"
		class="input-container address-suggestion unit-field-container"
	>
		<div class="input-field">
			<form [formGroup]="form">
				<input [attr.name]="addressAutoComplete" [value]="value" type="hidden" />
				<label [ngClass]="{ 'has-value': valueUnits, noIcon: showIcon == false }" class="inner-label" for="units">
					{{ "address.unit" | translate }}
				</label>
				<input
					(input)="updateUnitValue($event)"
					(keyup)="validateNumsLettersSpaceSpecialChars(this)"
					[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
					[attr.aria-invalid]="isInvalid() ? true : null"
					[ngClass]="{ hasvalue: valueUnits }"
					[placeholder]="''"
					[value]="valueUnits"
					class="units"
					id="units"
					type="text"
				/>
			</form>
		</div>
	</div>
</app-question-wrapper>

<error-message
	[describedByErrorId]="describedByErrorId"
	[fieldDisplayName]="postfix || addressAutoComplete"
	[field]="controlContainer.control.controls[addressFormName]"
	showIconError="true"
>
</error-message>

<error-message
	[describedByErrorId]="describedByErrorId"
	[fieldDisplayName]="postfix || addressAutoComplete"
	[field]="form.controls[addressAutoComplete]"
	[isFocus$]="isFocus$"
	showIconError="true"
>
</error-message>
