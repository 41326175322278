<section class="main-content">
	<app-page-heading
		[title]="(firstName | titlecase) + ', ' + ('labels.anyQuestionsApplyToYou' | translate)"
	></app-page-heading>
	<form #myForm="ngForm" [formGroup]="form" class="all-questions-wrapper">
		<div class="question-container">
			<div class="question-wrapper show">
				<label class="input-label" for="BusinessOrDaycare">
					{{ "titles.businessAtHome" | translate }}
				</label>
				<app-yes-no name="BusinessOrDaycare"></app-yes-no>
			</div>
		</div>

		<div class="question-container">
			<div class="question-wrapper show">
				<label class="input-label" for="NumberOfDogsOnPremises">
					{{ "titles.numberOfDogsOnPremises" | translate }}
				</label>
				<app-yes-no name="NumberOfDogsOnPremises"></app-yes-no>
			</div>
		</div>

		<div *ngIf="!!form.controls['NumberOfDogsOnPremises'].value" [@inOutAnimation] class="question-container">
			<div class="question-wrapper show">
				<label class="input-label" for="RestrictedDogs">
					{{ "titles.isDogBreedIncluded" | translate }}
					<span
						[matTooltip]="'titles.stilwaterBreeds' | translate"
						appToggleTooltip
						class="tooltip"
						matTooltipPosition="above"
					>
						{{ "labels.list" | translate }}
					</span>
					{{ "titles.isViciousBreed" | translate }}
				</label>
				<app-yes-no name="RestrictedDogs"></app-yes-no>
			</div>
		</div>

		<div *ngIf="!!form.controls['NumberOfDogsOnPremises'].value" [@inOutAnimation] class="question-container">
			<div class="question-wrapper show">
				<label class="input-label" for="AnimalBites">{{ "titles.dogInvolvedInBiting" | translate }}</label>
				<app-yes-no name="AnimalBites"></app-yes-no>
			</div>
		</div>

		<div class="question-container">
			<div class="question-wrapper show">
				<label class="input-label" for="PLHaveAnyLosses">{{ "titles.anyHomeInsuranceInLast5Years" | translate }}</label>
				<app-yes-no name="PLHaveAnyLosses"></app-yes-no>
			</div>
			<p *ngIf="getActiveLosses(losses).length > 0" class="sub-title">
				{{ "titles.addLoses" | translate }}
			</p>
			<ng-container *ngIf="!!form.controls['PLHaveAnyLosses'].value">
				<div *ngIf="losses">
					<ng-container *ngFor="let lossItem of getActiveLosses(losses); let i = index">
						<div [@inOutAnimation] [formGroup]="lossItem" class="loss-form-wrapper">
							<div class="fields-wrapper">
								<div class="loss-form-item">
									<div class="question-wrapper">
										<app-dropdown
											[labelInsideField]="'labels.lossType' | translate"
											[options]="lossesOptions"
											[reproducibleIndex]="i"
											name="PLLossDescription"
										>
										</app-dropdown>
									</div>
								</div>

								<div class="loss-form-item">
									<div class="question-wrapper">
										<app-datepicker
											[labelInsideField]="'labels.whenDitItHappened' | translate"
											[placeholder]="'labels.whenDitItHappened' | translate"
											[reproducibleIndex]="i"
											[theControl]="lossItem"
											name="DateOfLoss"
										></app-datepicker>
									</div>
								</div>

								<div class="loss-form-item">
									<div class="question-wrapper">
										<unmasked-input
											[isCurrency]="true"
											[pattern]="'^[0-9]*$'"
											[placeholder]="'labels.plLossAmount' | translate"
											[reproducibleIndex]="i"
											maxlength="50"
											name="PersonalLineLossAmount"
											type="text"
										></unmasked-input>
									</div>
								</div>
							</div>
							<div class="actions-wrapper">
								<button
									(click)="addLoss()"
									*ngIf="i === getActiveLosses(losses).length - 1 && getActiveLosses(losses).length !== 4"
									class="add"
									tabindex="0"
								>
									{{ "buttons.add" | translate }}
									<i appHiddenWhenCssDisabled class="material-icons" role="presentation">add_circle_outline</i>
								</button>

								<button
									(click)="removeLoss(lossItem.value.Id)"
									*ngIf="getActiveLosses(losses).length > 1"
									[attr.aria-label]="'buttons.remove' | translate"
									class="remove"
									tabindex="0"
								>
									{{ "buttons.remove" | translate }}
									<i appHiddenWhenCssDisabled class="material-icons" role="presentation">delete_outline</i>
								</button>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>

		<div class="question-container">
			<div class="question-wrapper show">
				<label class="input-label" for="YearsAtAddress">{{
					"titles.lessThan24MonthsAtInsuredAddress" | translate
				}}</label>
				<app-yes-no name="YearsAtAddress"></app-yes-no>
			</div>
			<ng-container *ngIf="!!form.controls['YearsAtAddress'].value">
				<p class="sub-title">{{ "labels.enterPreviousAddressDetails" | translate }}</p>
				<app-address-suggestion
					(addressChangedEvent)="handleAddressChange()"
					[addressLine1]="form.get('PreviousAddress.AddressLine1').value"
					[addressLine2]="form.get('PreviousAddress.AddressLine2').value"
					[city]="form.get('PreviousAddress.City').value"
					[county]="form.get('PreviousAddress.County').value"
					[fieldsNames]="fieldsNames"
					[form]="form"
					[placeholder]="'address.suggestion.previousHomeAddress' | translate"
					[state]="form.get('PreviousAddress.State').value"
					[toShowMap]="false"
					[zipCode]="form.get('PreviousAddress.ZipCode').value"
				>
				</app-address-suggestion>

				<error-message [field]="form.get('PreviousAddress').get('AddressLine1')" [isFocus$]="true"></error-message>
			</ng-container>
		</div>

		<div class="question-container">
			<div class="question-wrapper show">
				<label class="input-label" for="HomeAutoInsurance">{{ "titles.autoPolicyWithStillwater" | translate }}</label>
				<app-yes-no name="HomeAutoInsurance"></app-yes-no>
			</div>
			<p class="sub-title"></p>
			<!-- used for spacing don't remove -->
			<unmasked-input
				*ngIf="!!form.controls['HomeAutoInsurance'].value"
				[@inOutAnimation]
				[placeholder]="'labels.autoPolicyNumber' | translate"
				maxlength="50"
				name="BundelingAutoPolicyNum"
				type="text"
			></unmasked-input>
		</div>
	</form>

	<next-button
		(clicked)="onNextButtonClicked()"
		(validationFailed)="handleValidation($event)"
		[disabled]="!form.valid"
		[isFullQuote]="true"
		[myForm]="myForm"
		[title]="'buttons.continue' | translate"
	>
	</next-button>
</section>
