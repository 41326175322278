import { trigger, animate, transition, style, query } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
	transition('* => *', [
		query(':enter', [style({ opacity: 0 })], { optional: true }),
		query(':leave', [style({ opacity: 1 }), animate('0.2s', style({ opacity: 0 }))], { optional: true }),
		query(':enter', [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))], { optional: true }),
	]),
]);

export const inOutAnimation = trigger('inOutAnimation', [
	transition(':enter', [style({ opacity: 0 }), animate('.3s linear', style({ opacity: 1 }))]),
	transition(':leave', [style({ opacity: 1 }), animate('.3s linear', style({ opacity: 0 }))]),
]);
