<section class="main-content">
	<app-page-heading
		[title]="(firstName | titlecase) + ' ' + ('labels.anyQuestionsApplyToYou' | translate)"
	></app-page-heading>
	<form #myForm="ngForm" [formGroup]="form" class="all-questions-wrapper">
		<div class="additional-questions-container">
			<div class="question-container">
				<div class="question-wrapper show">
					<label class="input-label" id="ViciousExoticAnimals">
						{{ "titles.anyExoticAnimals" | translate }}
					</label>
					<app-yes-no name="ViciousExoticAnimals"></app-yes-no>
				</div>
			</div>

			<div class="question-container">
				<div class="question-wrapper show">
					<label class="input-label" id="AnimalBites">
						{{ "titles.animalLiabilityNotRequire" | translate }}
					</label>
					<app-yes-no name="AnimalBites"></app-yes-no>
				</div>
			</div>
		</div>
	</form>

	<next-button
		(validationFailed)="handleValidation($event)"
		[disabled]="!form.valid"
		[myForm]="myForm"
		[title]="'buttons.continue' | translate"
	>
	</next-button>
</section>
