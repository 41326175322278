<app-error
	[showFriendlyId]="false"
	imageName="agentLocked"
	subtitleKey="agentLocked.subTitle"
	titleKey="agentLocked.title"
>
	<app-button
		(clicked)="callAgent()"
		*ngIf="callAgentButtonOn"
		[eventData]="{
			action: EventActions.BUTTON_CLICK,
			label: 'Interview.CallAgent'
		}"
		appClickEvent
		titleKey="agentLocked.callAgentButtonText"
	>
	</app-button>
</app-error>
