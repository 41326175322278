<section class="main-content main-content-personal-details">
	<form [formGroup]="form" class="applicant-form stage">
		<div class="questions-container">
			<div [style.width]="getProgressbarWidth()" class="progress-bar"></div>
			<div [class.hidden]="form?.controls?.internalStep?.value !== 0" class="pet-form">
				<app-question-wrapper>
					<app-radio-buttons
						(handleSelect)="handleSelectPetSpecies($event)"
						[options]="petSpeciesOptions"
						[selectedOption]="form?.controls?.PLPetSpecies.value"
						name="lobs"
					>
					</app-radio-buttons>
				</app-question-wrapper>
				<app-question-wrapper>
					<unmasked-input
						[emitKeyupOnBlur]="false"
						[maxlength]="20"
						[name]="Fields.PLPetName.name"
						[pattern]="Fields.PLPetName.pattern"
						[placeholder]="'labels.plPetName' | translate"
						class="pet-name"
					></unmasked-input>
				</app-question-wrapper>
			</div>
			<div [class.hidden]="form?.controls?.internalStep?.value !== 1" class="pet-form">
				<h2 *ngIf="form?.controls?.PLPetSpecies.value && form?.controls?.PLPetName.value">
					{{ "labels.whatKindOf" | translate }} {{ form?.controls?.PLPetSpecies.value.toLowerCase() }}
					{{ "labels.is" | translate }}
					{{ form?.controls?.PLPetName.value | titlecase }}
				</h2>

				<app-question-wrapper>
					<app-dropdown
						[labelInsideField]="'labels.plPetBreedType' | translate"
						[name]="Fields.PLPetBreedType.name"
						[options]="petBreedTypeOptions"
						[reAlphabeticalOrder]="false"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper>
					<app-dropdown
						[disabled]="!form?.controls?.PLPetBreedType?.valid || !petBreedOptions.length"
						[labelInsideField]="'labels.plPetBreed' | translate"
						[name]="Fields.PLPetBreed.name"
						[options]="petBreedOptions"
						[placeholder]="'labels.chooseOption' | translate"
						[reAlphabeticalOrder]="false"
						[searchable]="true"
						class="app-dropdown-custom"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper>
					<app-dropdown
						[labelInsideField]="'labels.plPetGender' | translate"
						[name]="Fields.PLPetGender.name"
						[options]="petGenderOptions"
						[reAlphabeticalOrder]="false"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper class="date-of-birth" icon="calendar_today">
					<masked-input
						[inputMask]="'99/9999'"
						[name]="Fields.PetDOB.name"
						[placeholder]="'labels.petDob' | translate"
						class="date-input"
					></masked-input>
				</app-question-wrapper>
			</div>
			<div [class.hidden]="form?.controls?.internalStep?.value !== 2" class="pet-form">
				<h2 *ngIf="form?.controls?.PLPetName.value">
					{{ "labels.tellUsAbout" | translate }}
					{{ form?.controls?.PLPetName.value | titlecase }}
					{{ "labels.petHealthSuffix" | translate }}
				</h2>
				<div class="question-container">
					<div class="question-wrapper yes-no-wrapper show">
						<label [id]="Fields.PLSpayedNeutered.name">
							{{ "labels.petSpayedNeutered" | translate }}
						</label>
						<app-yes-no [name]="Fields.PLSpayedNeutered.name"></app-yes-no>
					</div>
				</div>
				<div class="question-container">
					<div class="question-wrapper yes-no-wrapper show">
						<label [id]="Fields.PLHasDisease.name">
							{{ "labels.petHasDisease" | translate }}
						</label>
						<app-yes-no [name]="Fields.PLHasDisease.name"></app-yes-no>
					</div>
				</div>
			</div>

			<div class="spacer"></div>
			<next-button
				(clicked)="handleContinue()"
				[continue]="true"
				[disabled]="!isCurrentStepValid()"
				[title]="getNextButtonText()"
			></next-button>
			<button (click)="goBack()" class="back-button" type="button">{{ "buttons.goBack" | translate }}</button>
		</div>
	</form>
</section>
