<div [formGroup]="controlContainer.control">
	<label
		(focusin)="onFocus()"
		(focusout)="onBlur()"
		(keydown)="toggle($event)"
		[attr.id]="labelId"
		[for]="fieldObj.name"
		[ngClass]="{ error: isInvalid(), checked: valueChecked }"
	>
		<input
			(change)="onChange($event)"
			[attr.aria-checked]="valueChecked"
			[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
			[attr.aria-invalid]="isInvalid() ? true : null"
			[attr.id]="fieldObj.name"
			[attr.name]="fieldObj.name"
			[formControlName]="fieldObj.name"
			[tabindex]="tabindex"
			class="custom-checkbox-input"
			type="checkbox"
		/>

		<span
			*ngIf="!fieldObj.labelKey"
			[innerHTML]="'labels.' + fieldObj.name | translate | textUnmask"
			class="custom-checkbox-label"
		>
		</span>
		<span *ngIf="fieldObj.labelKey" [appTranslation]="fieldObj.labelKey" class="custom-checkbox-label"> </span>
	</label>
</div>
<error-message
	[describedByErrorId]="describedByErrorId"
	[fieldDisplayName]="fieldObj.postfix || fieldObj.name"
	[field]="controlContainer.control.controls[fieldObj.name]"
	[isFocus$]="isFocus$"
>
</error-message>
