<div aria-describedby="confirm-address" class="popover">
	<div class="popover-inner">
		<i
			(click)="onClose()"
			(keyup)="closePopOver($event)"
			[attr.aria-label]="'buttons.close' | translate"
			class="icon icon-close"
			role="button"
			tabindex="0"
		></i>
		<div *ngIf="!isEmpty(addresses)" class="popover-title" id="confirm-address">
			{{ getTitle() }}
		</div>
		<div class="popover-content">
			<ul role="list">
				<li
					(click)="onSelect(address)"
					(keyup)="eventHandler($event, address)"
					*ngFor="let address of addresses"
					[hidden]="isEmpty(addresses)"
					class="linkable"
					role="listitem"
					tabindex="0"
				>
					{{ address.fullName }}
				</li>

				<li *ngIf="isEmpty(addresses)" role="listitem" tabindex="-1">
					{{ "address.suggestion.noMatchFound" | translate }}
				</li>
			</ul>
		</div>
	</div>
</div>
