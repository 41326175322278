<ng-container *ngIf="sections?.length">
	<ng-container *ngFor="let section of sections">
		<ng-container [ngSwitch]="section.type">
			<ng-container *ngSwitchCase="sectionTypes.sectionMain">
				<app-your-address [class]="section.name" [theme]="theme"></app-your-address>
			</ng-container>

			<ng-container *ngIf="section.enabled">
				<app-plain-text
					*ngSwitchCase="sectionTypes.plainText"
					[langSettings]="langSettings"
					[section]="section"
					[theme]="theme"
				></app-plain-text>

				<app-statistic
					*ngSwitchCase="sectionTypes.statistic"
					[langSettings]="langSettings"
					[section]="section"
					[theme]="theme"
				></app-statistic>

				<app-carriers
					*ngSwitchCase="sectionTypes.carriers"
					[langSettings]="langSettings"
					[section]="section"
					[theme]="theme"
				></app-carriers>

				<app-how-it-works
					*ngSwitchCase="sectionTypes.howItWorks"
					[langSettings]="langSettings"
					[section]="section"
					[theme]="theme"
				></app-how-it-works>

				<app-testimonials
					*ngSwitchCase="sectionTypes.testimonials"
					[langSettings]="langSettings"
					[section]="section"
					[theme]="theme"
				></app-testimonials>

				<app-faq
					*ngSwitchCase="sectionTypes.faq"
					[langSettings]="langSettings"
					[section]="section"
					[theme]="theme"
				></app-faq>

				<app-who-we-are
					*ngSwitchCase="sectionTypes.wwa"
					[langSettings]="langSettings"
					[section]="section"
					[theme]="theme"
				></app-who-we-are>

				<app-video
					*ngSwitchCase="sectionTypes.video"
					[langSettings]="langSettings"
					[section]="section"
					[theme]="theme"
				></app-video>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>
