import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { QuestionWrapperComponent } from './components/question-wrapper/question-wrapper.component';
import { DirectivesModule } from '../../../directives/directives.module';

@NgModule({
	declarations: [QuestionWrapperComponent],
	exports: [QuestionWrapperComponent],
	imports: [CommonModule, DirectivesModule, TranslateModule, MatTooltipModule],
})
export class QuestionWrapperModule {}
