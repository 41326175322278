<div
	[formGroup]="controlContainer.control"
	[ngClass]="{
		'has-value': value,
		error: controlContainer.control.controls[this.name].errors && isInvalid()
	}"
	class="container"
>
	<!--	This input is required for mat-datepicker to function properly. It should be hidden, the displayed value is casted to/from masked input-->
	<input
		(dateChange)="dateChange($event)"
		(focus)="handleFocus()"
		[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
		[attr.aria-invalid]="isInvalid() ? true : null"
		[formControlName]="name"
		[matDatepicker]="picker"
		[max]="maxDate"
		[min]="minDate"
		[placeholder]="placeholder || 'labels.chooseDate' | translate"
		[value]="displayValue"
		appHiddenWhenCssDisabled
		autocomplete="off"
		class="form-control hidden-mat-input"
		matInput
	/>

	<ng-container [formGroup]="maskedInputForm">
		<masked-input
			[inputMask]="'**/**/9999'"
			[name]="'maskedInput'"
			[placeholder]="placeholder || 'labels.chooseDate' | translate"
			class="date-input"
			[notshowLabelWhenFilled]="notshowLabelWhenFilled"
		></masked-input>
	</ng-container>

	<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
	<mat-datepicker #picker></mat-datepicker>
</div>

<error-message
	[describedByErrorId]="describedByErrorId"
	[fieldDisplayName]="postfix || name"
	[field]="controlContainer.control.controls[this.name]"
	[isFocus$]="isFocus$"
>
</error-message>
