import { Component } from '@angular/core';

import { ImageItemModel } from '../../../../../../../models/landing-section.model';
import { GeneralPropsEnum } from '../../../../../../../models/general-props.enum';
import { SectionBaseComponent } from '../section-base.component';

@Component({
	selector: 'app-how-it-works',
	templateUrl: './how-it-works.component.html',
	styleUrls: ['./how-it-works.component.scss'],
	inputs: ['section', 'images'],
})
export class HowItWorksComponent extends SectionBaseComponent {
	constructor() {
		super();
	}

	get stepList(): ImageItemModel[] {
		return this.section[GeneralPropsEnum.imagesList];
	}

	getTitle(step: ImageItemModel): string {
		if (step.hasOwnProperty(`alt${this.lang}`)) {
			return step[`alt${this.lang}`];
		}

		return step.alt;
	}

	linkClick(url: string) {
		if (url) {
			window.open(url, '_blank').focus();

			return;
		}

		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
}
