<ng-container *ngIf="isInitialVisible">
	<app-loader-fq-reports *ngIf="!(isPaymentVisible || noRates)"></app-loader-fq-reports>

	<ng-container *ngIf="isPaymentVisible">
		<app-page-heading subtitleKey="paymentEscrow.subTitle" titleKey="paymentEscrow.title"></app-page-heading>

		<form #myForm="ngForm" [formGroup]="form" class="questions-container">
			<div class="row price-row">
				<div class="col-auto title">{{ "labels.totalPrice" | translate }}</div>
				<div class="col-auto price">
					{{ totalPrice | zeroOrTwoDigitsFraction }}
					<span class="term"> / {{ "terms.year" | translate | lowercase }}</span>
				</div>
			</div>
			<div class="row price-row">
				<div class="col-auto title">{{ "labels.paymentPlan" | translate }}</div>
				<div class="col-auto title">{{ "labels.payInFull" | translate }}</div>
			</div>

			<hr class="question-spacer" />

			<h2 class="questions-container-title">{{ "titles.connectYourPolicyToPropertyEscrow" | translate }}</h2>

			<div class="row">
				<div class="col-md-6 question-wrapper">
					<app-question-wrapper>
						<label [for]="Fields.MortgageCompanyName.name" class="input-label">
							{{ "labels.mortgageCompanyName" | translate }}
						</label>
						<unmasked-input
							[name]="Fields.MortgageCompanyName.name"
							[pattern]="Fields.MortgageCompanyName.pattern"
							[placeholder]="'labels.pleaseEnter' | translate"
							notshowLabelWhenFilled="true"
							type="text"
						>
						</unmasked-input>
					</app-question-wrapper>
				</div>
				<div class="col-md-6 question-wrapper">
					<app-question-wrapper>
						<label [for]="Fields.MortgageAccountNumber.name" class="input-label">
							{{ "labels.mortgageAccountNumber" | translate }}
						</label>
						<unmasked-input
							[name]="Fields.MortgageAccountNumber.name"
							[pattern]="Fields.MortgageAccountNumber.pattern"
							[placeholder]="'labels.pleaseEnter' | translate"
							notshowLabelWhenFilled="true"
							type="text"
						>
						</unmasked-input>
					</app-question-wrapper>
				</div>
				<div class="col-12 question-wrapper">
					<div class="address-form-row row" formGroupName="MortgageCompanyAddress">
						<div class="col-md-7">
							<app-question-wrapper>
								<label [for]="Fields.MortgageCompanyAddress.name" class="input-label">
									{{ "address.suggestion.mortgageCompanyAddress" | translate }}
								</label>
								<unmasked-input
									[autocomplete]="false"
									[name]="'AddressLine1'"
									[pattern]="'^[A-Za-z\ 0-9\-\'\,\.\d]*$'"
									[placeholder]="'labels.pleaseEnter' | translate"
									[tabindex]="1"
									maxlength="50"
									notshowLabelWhenFilled="true"
									type="text"
									withoutErrorMessage="true"
								>
								</unmasked-input>
							</app-question-wrapper>
						</div>
						<div class="col-md-5">
							<app-question-wrapper>
								<label class="input-label" for="AddressLine2">
									{{ "address.aptUnit" | translate }}
								</label>
								<unmasked-input
									[autocomplete]="false"
									[name]="'AddressLine2'"
									[pattern]="'^[A-Za-z\ 0-9\-\'\,\.\d]*$'"
									[placeholder]="'labels.pleaseEnter' | translate"
									[tabindex]="1"
									id="AddressLine2"
									maxlength="30"
									notshowLabelWhenFilled="true"
									type="text"
									withoutErrorMessage="true"
								>
								</unmasked-input>
							</app-question-wrapper>
						</div>
						<div class="col-md-4">
							<app-question-wrapper>
								<label class="input-label" for="City">{{ "address.city" | translate }}</label>
								<unmasked-input
									[autocomplete]="false"
									[name]="'City'"
									[pattern]="'^[A-Za-z\ 0-9\-\'\,\.\d]*$'"
									[placeholder]="'labels.pleaseEnter' | translate"
									[tabindex]="1"
									id="City"
									maxlength="25"
									notshowLabelWhenFilled="true"
									withoutErrorMessage="true"
								>
								</unmasked-input>
							</app-question-wrapper>
						</div>
						<div class="col-md-4">
							<app-question-wrapper>
								<label class="input-label" for="State">{{ "address.state" | translate }}</label>
								<app-dropdown
									[labelInsideField]="'labels.pleaseEnter' | translate"
									[name]="'State'"
									[options]="statesOptions"
									[reAlphabeticalOrder]="false"
									[tabindex]="1"
									id="State"
									notShowLabelWhenSelected="true"
									searchable="true"
								></app-dropdown>
							</app-question-wrapper>
						</div>
						<div class="col-md-4">
							<app-question-wrapper>
								<label class="input-label" for="ZipCode">{{ "address.zipCode" | translate }}</label>
								<unmasked-input
									[autocomplete]="false"
									[name]="'ZipCode'"
									[pattern]="'^[0-9]*$'"
									[placeholder]="'labels.pleaseEnter' | translate"
									[tabindex]="1"
									id="ZipCode"
									maxlength="5"
									notshowLabelWhenFilled="true"
									type="tel"
									withoutErrorMessage="true"
								>
								</unmasked-input>
							</app-question-wrapper>
						</div>
					</div>
				</div>
			</div>

			<hr class="question-spacer" />

			<h2 appTranslation="paymentEscrow.whatDoYouNeedToKnowTitle" class="questions-container-title"></h2>

			<div
				[masks]="{ totalPrice: totalPrice | zeroOrTwoDigitsFraction }"
				appTranslation="paymentEscrow.whatDoYouNeedToKnow"
				class="content"
			></div>

			<div *ngIf="isMortgageBillPayCheckboxOn && customFields" class="checkbox-item" formGroupName="CustomFields">
				<app-checkbox
					[fieldObj]="Fields.MortgageBillPay"
					[tabindex]="isMortgageBillPayCheckboxOn && MortgageBillPayControl.enabled ? 0 : -1"
					[valueChecked]="MortgageBillPayControl.value"
				>
				</app-checkbox>
			</div>

			<next-button
				(clicked)="handleContinue()"
				[continue]="true"
				[disabled]="form.invalid"
				[myForm]="myForm"
				class="fullscreen"
				titleKey="paymentEscrow.button"
			></next-button>
		</form>

		<section appTranslation="paymentEscrow.legalText" class="escrow-legal-text"></section>
	</ng-container>

	<app-no-rates-error *ngIf="noRates" [showFriendlyId]="true"></app-no-rates-error>
</ng-container>
