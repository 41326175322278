import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';
import { HttpLoaderFactory } from '../utils/http-loader.factory';
import { HttpClient } from '@angular/common/http';
import { SafePipe } from '../pipes/safe.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PopupDirective, PopupWrapperComponent } from '../components/layout/popup/popup-wrapper/popup-wrapper.component';
import { AddressComponent } from '../components/form-controls/address/address.component';
import { SsnMaskingPipe } from '../pipes/ssn-masking.pipe';
import { PopoverAddressComponent } from '../components/form-controls/popover-address/popover-address.component';
import { RateNumberPipe } from '../pipes/rate-number.pipe';
import { CarrierOfferCardComponent } from '../components/shared/carrier-offer-card/carrier-offer-card.component';
import { PaymentDetailsComponent } from '../components/layout/pages/shared/payment-details/payment-details.component';
import { YourDetailsComponent } from '../components/layout/pages/shared/payment-details/your-details/your-details.component';
import { PolicyDatePickerComponent } from '../components/layout/pages/shared/policy-date-picker/policy-date-picker.component';
import { BasePageComponent } from '../components/layout/pages/base-page.component';
import { SuccessPageComponent } from '../components/layout/pages/shared/success-page/success-page.component';
import { PaymentFailedComponent } from '../components/layout/pages/shared/payment-failed/payment-failed.component';
import { CoverageComponent } from '../components/layout/pages/shared/result/coverage/coverage.component';
import { HomesiteComponent } from '../components/layout/pages/renters/additional-questions/components/homesite/homesite.component';
import { StillwaterComponent } from '../components/layout/pages/renters/additional-questions/components/stillwater/stillwater.component';
import { PaymentResultComponent } from '../components/layout/pages/shared/payment-result/payment-result.component';
import { SingleYesNoComponent } from '../components/layout/pages/homeowners/shared/single-yes-no/single-yes-no.component';
import { LobSelectionComponent } from '../components/layout/pages/shared/lob-selection/lob-selection.component';
import { RadioButtonsComponent } from '../components/layout/pages/homeowners/shared/radio-buttons/radio-buttons.component';
import { ErrorComponent } from '../components/layout/pages/shared/error/error.component';
import { TechnicalErrorComponent } from '../components/layout/pages/shared/error/technical-error/technical-error.component';
import { EndSessionErrorComponent } from '../components/layout/pages/shared/error/end-session-error/end-session-error.component';
import { StateNotAllowedComponent } from '../components/layout/pages/shared/error/state-not-allowed/state-not-allowed.component';
import { PageNotFoundErrorComponent } from '../components/layout/pages/shared/error/page-not-found-error/page-not-found-error.component';
import { LemonadeComponent } from '../components/layout/pages/homeowners/additional-questions/components/lemonade/lemonade.component';
import { LemonadeSecondPageComponent } from '../components/layout/pages/homeowners/additional-questions/components/lemonade-second-page/lemonade-second-page.component';
import { DriverComponent } from '../components/layout/pages/auto/drivers/driver/driver.component';
import { VehicleComponent } from '../components/layout/pages/auto/vehicles/vehicle/vehicle.component';
import { AddEditVehicleComponent } from '../components/layout/popup/add-edit-vehicle/add-edit-vehicle.component';
import { PrimaryVehicleComponent } from '../components/layout/popup/primary-vehicle/primary-vehicle.component';
import { VehicleCoverComponent } from '../components/layout/pages/auto/auto-coverages/vehicle-cover/vehicle-cover.component';
import { LinkExpiredErrorComponent } from '../components/layout/pages/shared/error/link-expired-error/link-expired-error.component';
import { AddEditPetComponent } from '../components/layout/pages/pets/add-edit-pet/add-edit-pet.component';
import { PetsComponent } from '../components/layout/pages/pets/pets/pets.component';
import { LinkSentComponent } from '../components/layout/pages/shared/error/link-sent/link-sent.component';
import { AlmostThereComponent } from '../components/layout/pages/shared/almost-there/almost-there.component';
import { LegalTextComponent } from '../components/shared/legal-text/legal-text.component';
import { PersonalDetailsPetsComponent } from '../components/layout/pages/pets/personal-details/personal-details.component';
import { RadioButtonsMultiSelectionComponent } from '../components/layout/pages/homeowners/shared/radio-buttons-multi-selection/radio-buttons.component';
import { RetrieveQuotePage } from '../components/layout/pages/shared/retrieve-quote/retrieve quote.component';
import { PageHeadingComponent } from '../components/shared/page-heading/components/page-heading/page-heading.component';
import { NoRatesErrorComponent } from '../components/layout/pages/shared/error/no-rates-error/no-rates-error.component';
import { FiskerDisclaimerComponent } from '../components/layout/pages/shared/fisker-disclaimer/fisker-disclaimer.component';
import { PaymentPlanEscrowComponent } from '../components/layout/pages/shared/payment-plan-escrow/payment-plan-escrow.component';
import { SuccessPageEscrowComponent } from '../components/layout/pages/shared/success-page-escrow/success-page-escrow.component';
import { ConfirmationEmailSentComponent } from '../components/layout/pages/shared/confirmation-email-sent/confirmation-email-sent.component';
import { YourAddressComponent } from '../components/layout/pages/shared/landing-page/sections/your-address/your-address.component';
import { LandingPageComponent } from '../components/layout/pages/shared/landing-page/landing-page.component';
import { HowItWorksComponent } from '../components/layout/pages/shared/landing-page/sections/how-it-works/how-it-works.component';
import { VideoComponent } from '../components/layout/pages/shared/landing-page/sections/video/video.component';
import { PlainTextComponent } from '../components/layout/pages/shared/landing-page/sections/plain-text/plain-text.component';
import { TestimonialsComponent } from '../components/layout/pages/shared/landing-page/sections/testimonials/testimonials.component';
import { FaqComponent } from '../components/layout/pages/shared/landing-page/sections/faq/faq.component';
import { WhoWeAreComponent } from '../components/layout/pages/shared/landing-page/sections/who-we-are/who-we-are.component';
import { ZeroOrTwoDigitsFraction } from '../pipes/zero-or-two-digits-frac.pipe';
import { SectionBaseComponent } from '../components/layout/pages/shared/landing-page/sections/section-base.component';
import { QuoteConfirmationComponent } from '../components/layout/pages/shared/quote-confirmation/quote-confirmation.component';
import { CoveragesDetailsComponent } from '../components/shared/coverages-details/coverages-details.component';
import { NoConsumerFlowComponent } from '../components/layout/pages/shared/error/no-consumer-flow/no-consumer-flow.component';
import { PhonePipe } from '../pipes/phone.pipe';
import { LoaderFqReportsComponent } from '../components/layout/pages/shared/loader-fq-reports/loader-fq-reports.component';
import { AgentLockedComponent } from '../components/layout/pages/shared/error/agent-locked/agent-locked.component';
import { FooterComponent } from '../components/layout/pages/shared/landing-page/sections/footer/footer.component';
import { StatisticComponent } from '../components/layout/pages/shared/landing-page/sections/statistic/statistic.component';
import { CarriersComponent } from '../components/layout/pages/shared/landing-page/sections/carriers/carriers.component';
import { AnchorCarrierComponent } from '../components/layout/pages/shared/anchor-carrier/anchor-carrier.component';
import { CoveragesListComponent } from '../components/shared/coverages-list/coverages-list.component';
import { DirectivesModule } from '../directives/directives.module';
import { UpsellBoxComponent } from '../components/shared/upsell-box/upsell-box.component';
import { PopupModule } from '../components/layout/popup/popup.module';
import { ToggleModule } from '../components/shared/toggle/toggle.module';
import { NextButtonModule } from '../components/next-button/next-button.module';
import { FormControlModule } from '../components/form-controls/form-control.module';
import { HeaderModule } from '../components/layout/header/header.module';
import { LabelModule } from '../components/form-controls/label/label.module';
import { ErrorMessageModule } from '../components/error-message/error-message.module';
import { ImageModule } from '../components/shared/image/image.module';
import { CommonModule } from '@angular/common';
import { QuestionWrapperModule } from '../components/layout/question-wrapper/question-wrapper.module';
import { ProgressMeterComponent } from '../components/layout/progress-meter/progress-meter.component';
import { LoaderComponent } from '../components/shared/loader/loader.component';
import { DiscountsComponent } from '../components/shared/discounts/discounts.component';
import { CarrierKickoutComponent } from '../components/layout/pages/shared/kickouts/carrier-kickout/carrier-kickout.component';

@NgModule({
	declarations: [
		SsnMaskingPipe,
		RateNumberPipe,
		BasePageComponent,
		LoaderComponent,
		ProgressMeterComponent,
		PopupDirective,
		PopupWrapperComponent,
		AddressComponent,
		PhonePipe,
		YourAddressComponent,
		PopoverAddressComponent,
		CoverageComponent,
		ZeroOrTwoDigitsFraction,
		CarrierOfferCardComponent,
		PaymentDetailsComponent,
		YourDetailsComponent,
		PolicyDatePickerComponent,
		SafePipe,
		SuccessPageComponent,
		PaymentFailedComponent,
		HomesiteComponent,
		StillwaterComponent,
		RetrieveQuotePage,
		PaymentResultComponent,
		SingleYesNoComponent,
		LobSelectionComponent,
		RadioButtonsComponent,
		RadioButtonsMultiSelectionComponent,
		PersonalDetailsPetsComponent,
		ErrorComponent,
		TechnicalErrorComponent,
		AgentLockedComponent,
		NoRatesErrorComponent,
		EndSessionErrorComponent,
		StateNotAllowedComponent,
		PageNotFoundErrorComponent,
		LemonadeComponent,
		LemonadeSecondPageComponent,
		DriverComponent,
		VehicleComponent,
		AddEditVehicleComponent,
		PrimaryVehicleComponent,
		VehicleCoverComponent,
		LinkExpiredErrorComponent,
		LinkSentComponent,
		FiskerDisclaimerComponent,
		AddEditPetComponent,
		PetsComponent,
		AlmostThereComponent,
		LegalTextComponent,
		PageHeadingComponent,
		PaymentPlanEscrowComponent,
		SuccessPageEscrowComponent,
		ConfirmationEmailSentComponent,
		LandingPageComponent,
		FooterComponent,
		StatisticComponent,
		CarriersComponent,
		HowItWorksComponent,
		VideoComponent,
		PlainTextComponent,
		TestimonialsComponent,
		FaqComponent,
		WhoWeAreComponent,
		SectionBaseComponent,
		QuoteConfirmationComponent,
		CoveragesDetailsComponent,
		NoConsumerFlowComponent,
		AnchorCarrierComponent,
		CoveragesListComponent,
		LoaderFqReportsComponent,
		UpsellBoxComponent,
		CarrierKickoutComponent,
	],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		DirectivesModule,
		MatTooltipModule,
		MatIconModule,
		MatTabsModule,
		MatButtonToggleModule,
		MatExpansionModule,
		MatDividerModule,
		MatDatepickerModule,
		MatMomentDateModule,
		MatCardModule,
		MatSlideToggleModule,
		PopupModule,
		ToggleModule,
		NextButtonModule,
		FormControlModule,
		ImageModule,
		HeaderModule,
		LabelModule,
		ErrorMessageModule,
		QuestionWrapperModule,
		NgSelectModule,
		DiscountsComponent,
		TranslateModule.forRoot({
			defaultLanguage: LanguageService.currentLanguage.value || 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient, LanguageService],
			},
		})
	],
	exports: [
		PopupWrapperComponent,
		FooterComponent,
		NextButtonModule,
		MatTooltipModule,
		MatIconModule,
		MatTabsModule,
		MatButtonToggleModule,
		MatExpansionModule,
		MatDividerModule,
		MatDatepickerModule,
		MatMomentDateModule,
		MatCardModule,
		MatSlideToggleModule,
		FormControlModule,
		FormsModule,
		ReactiveFormsModule,
		LoaderComponent,
		ProgressMeterComponent,
		DirectivesModule,
		TranslateModule,
		HeaderModule,
		ToggleModule,
		LegalTextComponent,
		QuestionWrapperModule,
		UpsellBoxComponent,
		PageHeadingComponent,
		DriverComponent,
		VehicleComponent,
		AddEditVehicleComponent,
		VehicleCoverComponent,
		ImageModule,
		AnchorCarrierComponent,
		CoverageComponent,
		NoRatesErrorComponent,
		SafePipe,
		DiscountsComponent,
		RadioButtonsComponent,
		SingleYesNoComponent,
		ErrorMessageModule,
		CarrierOfferCardComponent,
		LoaderFqReportsComponent,
		ZeroOrTwoDigitsFraction,
		PaymentPlanEscrowComponent,
		PhonePipe,
		ErrorComponent,
	],
})
export class SharedModule { }
