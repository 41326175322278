<section class="main-content bottom-space">
	<app-page-heading [title]="getPageTitle() | translate"></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="stage">
		<ul [attr.aria-label]="'labels.addedPets' | translate" class="wrapper">
			<ng-container *ngIf="!isAddEditPetMode">
				<ng-container *ngFor="let petFormGroup of getActiveFields(petsFormControl); let i = index">
					<li class="accordion-block pet-accordion" data-status="open">
						<div class="accordion-top">
							<img
								alt="{{ petFormGroup.value.PLPetSpecies }}"
								class="pet-logo"
								role="presentation"
								src="assets/icons/pets-{{ petFormGroup.value.PLPetSpecies?.toLowerCase() }}-small{{
									petFormGroup?.value?.enabled ? '-checked' : ''
								}}.svg"
							/>
							<div class="sr-only">{{ petFormGroup.value.PLPetSpecies }}</div>
							<div class="accordion-title-wrapper">
								<h3
									[attr.aria-label]="('labels.petNameIs' | translate) + ' ' + +petFormGroup.value.PLPetName"
									class="title"
									role="heading"
									tabindex="0"
								>
									<span class="pet-name">{{ petFormGroup.value.PLPetName }}</span>
								</h3>
							</div>
							<div class="accordion-actions-wrapper">
								<button
									(click)="handleEdit(petFormGroup)"
									*ngIf="petFormGroup.value?.enabled"
									[attr.aria-label]="'titles.editDetails' | translate"
									class="edit"
									type="button"
								>
									<span class="edit-text">{{ "titles.editDetails" | translate }}</span>
									<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons edit" role="presentation">
										edit
									</i>
								</button>

								<mat-slide-toggle
									[disabled]="!petFormGroup.value?.enabled && cantEnable()"
									[formControl]="petFormGroup.controls.enabled"
									class="example-margin"
									>{{ "labels.togglePet" | translate }}
								</mat-slide-toggle>
							</div>
						</div>
					</li>
				</ng-container>
			</ng-container>

			<app-add-edit-pet
				(cancelled)="onPetFormCancelled()"
				(completed)="onPetFormCompleted()"
				*ngIf="!!editedPetForm"
				[form]="editedPetForm"
			></app-add-edit-pet>
		</ul>
	</form>

	<div *ngIf="!isAddEditPetMode" class="add-pet">
		<app-button
			(clicked)="addPetFormAndOpenForEdit()"
			[disabled]="cantAdd()"
			materialIcon="add"
			severity="bordered"
			[title]="'buttons.addAnotherPet' | translate"
		></app-button>
		<div *ngIf="cantAdd()" class="error-cant-add">
			{{ "labels.policiesLimitedTo" | translate }} {{ " " }} {{ maxNumberOfPets }} {{ " " }}
			{{ "labels.pets" | translate }}
		</div>
	</div>

	<div [class.hidden]="isAddEditPetMode" class="next-button-wrapper">
		<next-button
			(clicked)="handleContinue()"
			(validationFailed)="continue()"
			[continue]="true"
			[disabled]="!form.valid || !isAtLeastOnePetEnabled()"
			[myForm]="myform"
			[title]="'buttons.continue' | translate"
		></next-button>
		<span [ngClass]="{ show: isLoading }" class="loader show"></span>
	</div>
</section>
