import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseFormControlComponent } from 'src/app/components/form-controls/base-form-control.component';
import { IAppState } from 'src/app/store/states/app.state';

@Component({
  selector: 'app-single-yes-no',
  templateUrl: './single-yes-no.component.html',
  styleUrls: ['./single-yes-no.component.scss']
})
export class SingleYesNoComponent implements AfterViewInit, OnInit {


  @Input()
  choosedAns: string;

  @Output()
  handleAns = new EventEmitter<string>();
  constructor() {

  }


  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }

  updateAns(value) {
    this.choosedAns = value;
    this.handleAns.emit(this.choosedAns);

  }


}
