<app-error imageName="paymentFailed" subtitleKey="payment-failed.subTitle" titleKey="payment-failed.title">
	<div>
		<div class="buttons-footer">
			<button
				(click)="handleTryAgain()"
				*ngIf="backButtonOn"
				[eventData]="{
					action: eventActions.BUTTON_CLICK,
					label: 'back-to-quote',
					button: 'back-to-quote',
					module: 'payment-failed'
				}"
				appClickEvent
				class="app-button bordered try-again-desktop"
				type="button"
			>
				<span class="title">
					<span appTranslation="payment-failed.backButtonText"></span>
					<i appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
				</span>
			</button>

			<button
				(click)="handleTryAgain()"
				*ngIf="backButtonOn"
				[eventData]="{
					action: eventActions.BUTTON_CLICK,
					label: 'back-to-quote',
					button: 'back-to-quote',
					module: 'payment-failed'
				}"
				appClickEvent
				appHiddenWhenCssDisabled
				class="app-button try-again-mobile"
			>
				<span class="title">
					<span appTranslation="payment-failed.backButtonText"></span>
					<i appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
				</span>
			</button>

			<button
				(click)="openPopup()"
				*ngIf="callAgentButtonOn"
				[eventData]="{
					action: eventActions.BUTTON_CLICK,
					label: 'call-agent',
					button: 'call-agent',
					module: 'payment-failed'
				}"
				appClickEvent
				class="app-button call-agent-button bordered"
				tabindex="0"
			>
				<span class="title">
					<span appTranslation="payment-failed.callAgentButtonText"></span>
					<i appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
				</span>
			</button>
		</div>
	</div>
</app-error>
