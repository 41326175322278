import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { showPopupAction } from '../../../../../../store/actions/popup.actions';
import { Store } from '@ngrx/store';
import {
	UpdateDiscoverVehicle,
	UpdateManualVehicle,
	UpdatePersonalVehicle,
} from '../../../../../../store/actions/quote-data.actions';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Validations } from 'src/app/utils/validation';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
	selector: 'app-vehicle',
	templateUrl: './vehicle.component.html',
	styleUrls: ['./vehicle.component.scss'],
})
export class VehicleComponent implements OnInit {
	@Input() source: string;
	@Input() cantAdd: boolean;
	@Input() isAdditionalQuestionsPage = false;
	@Input() allVins;

	public form: FormGroup;

	constructor(
		private controlContainer: ControlContainer,
		private elementRef: ElementRef,
		private store: Store,
		private translateService: TranslateService
	) {}

	get title(): string {
		const titleParts = [this.form.value.PLYear, this.form.value.PLMake, this.form.value.PLModel].filter((part) => part);

		return titleParts.join(' ');
	}

	ngOnInit(): void {
		this.form = this.controlContainer.control as FormGroup;
		if (!this.isAdditionalQuestionsPage) {
			this.form.controls.VIN.addValidators(Validations.validateVINduplicate);
		}
	}

	handleEdit(vehicleOpened: boolean) {
		this.store.dispatch(
			showPopupAction({
				componentName: 'add-edit-vehicle',
				payload: {
					text: `${this.translateService.instant('popups.titles.add-edit-vehicle-edit')} ${this.form.value.PLYear} ${
						this.form.value.PLMake
					} ${this.form.value.PLModel}`,
					customClass: 'add-edit-vehicle',
					gtmLabel: 'AddEditVehiclePopUp',
					Id: this.form.value.Id,
					value: this.form.value,
					// Should not display validation errors on first open of modal, so don't pass errors to modal
					// if vehicle was never opened (vehicleOpened: false)
					...(vehicleOpened && { formErrors: this.getFormErrorsForModal() }),
				},
			})
		);
	}

	handleEditPrimary(vehicle) {
		this.store.dispatch(
			showPopupAction({
				componentName: 'primary-vehicle',
				payload: {
					text: `${this.translateService.instant('popups.titles.primary-vehicle')} ${vehicle.PLMake}`,
					customClass: 'primary-vehicle',
					gtmLabel: 'PrimaryVehiclePopUp',
					Id: vehicle.Id,
					value: vehicle,
				},
			})
		);
	}

	getVehicleLogoName(make: string): string {
		return make.split(' ').join('-').toLowerCase().replace('.', '');
	}

	// todo implement
	delete() {
		this.form.patchValue({ SequenceNum: -1 });
	}

	toggle(event) {
		let openAuto = false;
		this.store
			.select(selectInterviewMetadata)
			.pipe(first())
			.subscribe((res) => {
				if (!res.vehiclesOpened.includes(this.form.value.Id) && event.checked) {
					this.handleEdit(false);
					openAuto = true;
				}
			});

		setTimeout(
			() => {
				if (this.form.value.type === 'PersonalVehicles') {
					this.store.dispatch(UpdatePersonalVehicle({ data: { ...this.form.value, enabled: event.checked } }));
				} else if (this.form.value.type === 'discoverVehicles') {
					this.store.dispatch(UpdateDiscoverVehicle({ data: { ...this.form.value, enabled: event.checked } }));
				} else if (this.form.value.type === 'manualVehicles') {
					this.store.dispatch(UpdateManualVehicle({ data: { ...this.form.value, enabled: event.checked } }));
				}
			},
			openAuto ? 500 : 0
		);
	}

	private getFormErrorsForModal() {
		return Object.entries(this.form.controls).map(([name, control]) => ({
			name,
			errors: control.errors,
		}));
	}
}
