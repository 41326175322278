import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { RoutingService } from 'src/app/services/routing.service';
import { SessionTimeoutService } from 'src/app/services/session-timeout.service';
import { EventActions } from '../../../../../../models/event-data.model';
import { IAppState } from '../../../../../../store/states/app.state';
import { selectActiveTheme } from '../../../../../../store/selectors/active-theme.selector';
import { GeneralPropsEnum } from '../../../../../../models/general-props.enum';
import { StepsEnum } from '../../../../../../enums/steps.enum';
import { EventsService } from 'src/app/services/events.service';

@UntilDestroy()
@Component({
	templateUrl: './end-session-error.component.html',
})
export class EndSessionErrorComponent implements OnInit {
	EventActions = EventActions;
	subtitle: string;
	title: string;
	button: string;
	url: string;
	backButtonOn: boolean;
	constructor(
		private readonly routingService: RoutingService,
		private readonly timeoutService: SessionTimeoutService,
		private readonly store: Store<IAppState>,
		private eventsService: EventsService
	) { }

	ngOnInit(): void {
		this.timeoutService.resetIdleTimer();
		this.eventsService.addEvent(this.EventActions.END_SESSION, { label: 'End Session' });
		this.store
			.select(selectActiveTheme)
			.pipe(untilDestroyed(this))
			.subscribe((theme) => {
				this.title = theme[GeneralPropsEnum.texts][StepsEnum.END_SESSION].title;
				this.subtitle = theme[GeneralPropsEnum.texts][StepsEnum.END_SESSION].subTitle;
				this.backButtonOn = theme[GeneralPropsEnum.texts][StepsEnum.END_SESSION].backButtonOn;
				this.url = theme[GeneralPropsEnum.texts][StepsEnum.END_SESSION].backButtonUrl;
			});
	}

	goHome(): void {
		if (this.url?.length) {
			this.routingService.navigateToExternal(this.url, true);
			return;
		}

		this.routingService.navigateToHomePage();
		this.timeoutService.startIdleTimer();
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, { label: 'AddAnotherCar', button: 'Add-another-car', module: 'vehicles-module' });

	}
}
