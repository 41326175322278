import { Component, Input, OnInit } from '@angular/core';

import { combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { selectFriendlyId } from '../../../store/selectors/interview-metadata.selector';
import { getSelectedRate } from '../../../store/selectors/result-data.selectors';
import { CoverageModel } from '../../../models/coverage.model';
import { IAppState } from '../../../store/states/app.state';
import { ActionApiService } from '../../../services/action-api.service';
import { RateService } from '../../layout/pages/shared/result/services/rate.service';
import { yearlyTermDisplayName } from '../../../utils/general.utils';

@UntilDestroy()
@Component({
	selector: 'app-coverages-details',
	templateUrl: './coverages-details.component.html',
	styleUrls: ['./coverages-details.component.scss'],
})
export class CoveragesDetailsComponent implements OnInit {
	@Input() public buttonDisclaimer: string;
	rates: CoverageModel[];
	friendlyId: string;
	selectedRate: any;

	constructor(
		protected store: Store<IAppState>,
		protected actionApiService: ActionApiService,
		private rateServices: RateService
	) {}

	get displayTerm(): string {
		return yearlyTermDisplayName(this.selectedRate.term, false);
	}

	get price(): number {
		return this.selectedRate.premium;
	}

	ngOnInit(): void {
		combineLatest([this.store.select(selectFriendlyId), this.store.select(getSelectedRate)])
			.pipe(untilDestroyed(this))
			.subscribe(([friendlyId, selectedRate]) => {
				this.rates = this.rateServices.transformRates([selectedRate]).map((rate) => ({
					...rate,
					isSelected: rate.selected,
				}));
				this.friendlyId = friendlyId;
				this.selectedRate = selectedRate;
			});
	}

	getLogo(rate: CoverageModel): string {
		return this.actionApiService.getLogo(rate.carrier, rate.lob);
	}
}
