import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { QuoteDataService } from 'src/app/services/quote-data.service';
import { selectFirstName } from 'src/app/store/selectors/quote-data.selectors';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../../../base-page.component';

@UntilDestroy()
@Component({
	templateUrl: './homesite.component.html',
	styleUrls: ['./homesite.component.scss'],
})
export class HomesiteComponent extends BasePageComponent implements OnInit, OnDestroy {
	firstName = '';
	isKickOut: boolean;

	constructor(injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>) {
		super(injector, store, quoteDataService);
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();
		this.patchData(this.form);
		this.registerOnChange(this.form);

		this.store
			.select(selectFirstName)
			.pipe(untilDestroyed(this))
			.subscribe((firstName) => {
				this.firstName = firstName;
			});
	}

	initForm(): void {
		this.form = new FormGroup({
			ViciousExoticAnimals: new FormControl({ value: null, disabled: false }, [Validators.required]),
			AnimalBites: new FormControl({ value: null, disabled: false }, [Validators.required]),
		});

		this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((changes) => {
			this.isKickOut = Object.values(changes).some((val) => val === true);
		});
	}
}
