<section *ngIf="isFooterVisible" class="footer {{ footer.name }}">
	<div class="content">
		<div *ngIf="nav?.length || footer.images['footerLogo']" class="links-logo  {{ logoPosition }}">
			<app-image alt="null" classNames="presentation-image" name="footerLogo" role="presentation"></app-image>

			<div *ngIf="nav?.length" class="nav">
				<div *ngFor="let navItem of nav" class="item">
					<h4>{{ getTitle(navItem) }}</h4>
					<ul>
						<li *ngFor="let link of navItem.links">
							<span *ngIf="!link.url">{{ getTitle(link) }}</span>
							<a *ngIf="link.url" [href]="link.url" target="_blank">{{ getTitle(link) }}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div appTranslation="footerSection.copyright" class="copyright"></div>
	</div>
</section>
