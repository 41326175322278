import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IAppState } from 'src/app/store/states/app.state';
import { selectInterviewTexts } from '../../../../../../store/selectors/interview-texts.selectors';
import { StepsEnum } from '../../../../../../enums/steps.enum';

@UntilDestroy()
@Component({
	selector: 'app-link-sent',
	templateUrl: './link-sent.component.html',
	styleUrls: ['./link-sent.component.scss'],
})
export class LinkSentComponent implements OnInit {
	title = '';
	subtitle = '';

	constructor(protected store: Store<IAppState>) {}

	ngOnInit(): void {
		this.store
			.select(selectInterviewTexts)
			.pipe(untilDestroyed(this))
			.subscribe((step) => {
				this.title = step[StepsEnum.LINK_SENT].title;
				this.subtitle = step[StepsEnum.LINK_SENT].subTitle;
			});
	}
}
