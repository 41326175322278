<div class="main-wrapper">
	<div class="question-selector-wrapper">
		<label (click)="updateAns('yes')" [ngClass]="{ selected: choosedAns === 'yes' }" class="option">
			<span class="inner-wrapper">
				<div class="circle">
					<div class="inner"></div>
				</div>
				<input
					[attr.checked]="choosedAns === 'yes'"
					class="hidden-radio"
					name="single-yes-no"
					type="radio"
					value="true"
				/>
				<span>{{ "labels.yes" | translate }}</span>
			</span>
		</label>
		<label (click)="updateAns('no')" [ngClass]="{ selected: choosedAns === 'no' }" class="option">
			<span class="inner-wrapper">
				<div class="circle">
					<div class="inner"></div>
				</div>
				<input
					[attr.checked]="choosedAns === 'yes'"
					class="hidden-radio"
					name="single-yes-no"
					type="radio"
					value="true"
				/>
				<span>{{ "labels.no" | translate }}</span>
			</span>
		</label>
	</div>
</div>
