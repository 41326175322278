import { Pipe, PipeTransform } from '@angular/core';
import { Fields } from '../constants/fields';

@Pipe({
	name: 'ssnMasking',
})
export class SsnMaskingPipe implements PipeTransform {
	transform(value: any, ...args: unknown[]): unknown {
		if (value && (args[0] === Fields.Ssn.name || args[0] === Fields.CoSSN.name)) {
			return value.replaceAll('X', '•');
		}
		return value;
	}
}
