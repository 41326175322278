import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';

import { QuoteDataService } from 'src/app/services/quote-data.service';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { IAppState } from 'src/app/store/states/app.state';
import { inOutAnimation } from 'src/app/animations';
import { BasePageComponent } from '../../base-page.component';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IQuoteDataState } from 'src/app/store/states/quote-data.state';
import { Validations } from 'src/app/utils/validation';
import { Fields } from 'src/app/constants/fields';
import { LoaderActions } from 'src/app/store/actions';
import { Router } from '@angular/router';
import { NextButtonComponent } from 'src/app/components/next-button/next-button.component';
import { selectAgentPhoneNumber } from 'src/app/store/selectors/active-theme.selector';

@UntilDestroy()
@Component({
	selector: 'retrieve quote-page',
	templateUrl: './retrieve quote.component.html',
	styleUrls: ['./retrieve quote.component.scss'],
	animations: [inOutAnimation],
})
export class RetrieveQuotePage extends BasePageComponent implements OnInit, OnDestroy {
	@Output() changePage = new EventEmitter();
	@ViewChild(NextButtonComponent) nextButtonComponent: NextButtonComponent;

	form;
	phoneNumber;
	showSprinklerSystem: boolean;
	public quoteData$: Observable<IQuoteDataState>;
	serverResponse;

	constructor(injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>, private router: Router) {
		super(injector, store, quoteDataService);
		this.quoteData$ = this.store.select(selectQuoteData);
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();

		this.store.select(selectAgentPhoneNumber).subscribe(phoneNumber => {
			this.phoneNumber = phoneNumber
		});
	}

	initForm(): void {
		this.form = new FormGroup({
			[Fields.FirstName.name]: new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(50),
				Validations.firstNameOneChar,
				Validations.alphabet,
			]),
			[Fields.LastName.name]: new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(30),
				Validations.lastNameOneChar,
				Validations.alphabet,
			]),
			[Fields.Email.name]: new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(60),
				Validations.emailValidAuto,
			]),
			[Fields.DateOfBirth.name]: new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validations.isDOBpastDate,
				Validations.dateFormat,
				Validations.isMoreThan16,
				Validations.isEqulesOrAfter1920,
				Validations.unmaskedDOB,
			]),
		});

	}


	handleContinue(): void {
		this.serverResponse = null;
		this.store.dispatch(LoaderActions.ShowLoader());

		const formValue: any = { ...this.form.value };

		this.actionService
			.retrieveQuote(formValue)
			.pipe(first())
			.subscribe((serverRes) => {
				this.store.dispatch(LoaderActions.Hideloader());

				if (serverRes.token) {

					this.router.navigate([], {
						queryParams: {
							token: serverRes.token,
							forcePageSkipping: true
						},
						queryParamsHandling: 'merge'
					});
					setTimeout(() => {
						this.retrieveQuoteService.extractToken(this.actionService.getSource());
					}, 0);
				} else {
					this.serverResponse = serverRes;
					this.nextButtonComponent.loading = false;
				}
			});
	}

}
