import { AfterViewInit, Component, HostBinding, Injector, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';

import { BaseFormControlComponent } from '../base-form-control.component';
import { IDropdownOption } from '../form-control-interfaces';
import { IAppState } from 'src/app/store/states/app.state';
import { ControlsType } from 'src/app/constants/controls-type';
import { EventsService } from 'src/app/services/events.service';
import { firstLetterToLowerCase } from 'src/app/utils/general.utils';

@UntilDestroy()
@Component({
	selector: 'app-radio-button',
	templateUrl: './radio-button.component.html',
	styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent extends BaseFormControlComponent implements OnInit, AfterViewInit {
	@Input() options: IDropdownOption[] = [];
	@Input() labelInsideField: string;
	@Input() withoutErrorMessage: boolean;
	@HostBinding('attr.name')
	invalid: boolean;
	disabled: boolean;

	constructor(injector: Injector, protected store: Store<IAppState>, eventsService: EventsService) {
		super(injector, store, eventsService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.setValue();
		this.controlType = ControlsType.dropdown;
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
	}

	setValue() {
		this.value = this.controlContainer.control.controls[this.name].value;
	}

	onBlur() {
		super.baseOnBlur();
		const field = this.controlContainer.control.controls[this.name];
		field.markAsTouched();

		setTimeout(() => {
			this.invalid = field.status === 'INVALID';
		}, 1);
	}

	onFocus() {
		super.baseOnFocus();
	}

	updateAnswer(item, index) {
		if (item.target.checked) {
			for (const option of this.options) {
				if (option.checked) {
					option.checked = false;
				}
			}

			this.options[index].checked = true;
			this.value = item.target.value;
			this.controlContainer.control.controls[this.name].setValue(this.value);
		}
	}

	getFileName(optionName: string, isChecked: boolean): string {
		const fileName = firstLetterToLowerCase(optionName);
		return isChecked ? fileName + 'Checked' : fileName;
	}
}
