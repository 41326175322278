import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { inOutAnimation } from 'src/app/animations';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { showPopupAction } from 'src/app/store/actions/popup.actions';
import { EditDiscoveredDriver, EditDrivers, EditManualDriver } from 'src/app/store/actions/quote-data.actions';
import { FormattedDateService } from 'src/app/services/formatted-date.service';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DriverTypeEnum } from '../model/driver-source.enum';

@Component({
	selector: 'app-driver',
	templateUrl: './driver.component.html',
	styleUrls: ['./driver.component.scss'],
	animations: [inOutAnimation],
})
export class DriverComponent implements OnInit {
	@Input() applicant;
	@Input() source: string;
	@Input() formDriver: FormGroup;
	@Input() Id: string;
	@Input() disabledToggle: boolean;

	@Output() editDriverFunc: EventEmitter<any> = new EventEmitter();

	public DriverTypeEnum = DriverTypeEnum;
	active: boolean;
	toggle: boolean;

	constructor(
		protected store: Store<IAppState>,
		private formattedDateService: FormattedDateService,
		private translateService: TranslateService,
		protected quoteDataService: QuoteDataService,
	) { }

	get driverType(): any {
		return this.formDriver.value.FirstName + ' ' + this.formDriver.value.LastName == this.applicant &&
			this.formDriver.value.SequenceNum === 0
			? 'Primary'
			: null;
	}

	ngOnInit(): void {
		this.formDriver.value.active || this.formDriver.value.SequenceNum == 0
			? (this.active = true)
			: (this.active = false);
	}

	handleToggle(value, source) {
		this.store
			.select(selectInterviewMetadata)
			.pipe(first())
			.subscribe((res) => {
				if (!res.driversOpened.includes(this.Id) && value) {
					this.editClick(true);
				}

				setTimeout(
					() => {
						this.active = value;
						if (source == DriverTypeEnum.Manual) {
							this.store.dispatch(EditManualDriver({ data: { ...this.formDriver.value, active: value } }));
						} else if (source == DriverTypeEnum.Discover) {
							this.store.dispatch(EditDiscoveredDriver({ data: { ...this.formDriver.value, active: value } }));
						} else {
							this.store.dispatch(EditDrivers({ data: { ...this.formDriver.value, active: value } }));
						}
						this.quoteDataService.updateDriver.next({
							driver: { data: { ...this.formDriver.value, active: value } },
							source: source,
						});
					},
					value && !res.driversOpened.includes(this.Id) ? 350 : 0
				);
			});
	}

	editClick(firstTimeClick) {
		this.store.dispatch(
			showPopupAction({
				componentName: 'driver-popup',
				payload: {
					text: `${this.translateService.instant('popups.titles.driver-popup-edit')} ${this.formDriver.value.FirstName
						} ${this.formDriver.value.LastName}`,
					type: 'edit',
					data: {
						...this.formDriver.value,
						active: true,
						...(!firstTimeClick && {
							formErrors: Object.entries(this.formDriver.controls).map(([name, control]) => ({
								name,
								errors: control.errors,
							})),
						}),
					},
					customClass: 'add-edit-vehicle',
					gtmLabel: 'CoveragePopUp',
					source: this.source,
					name: this.formDriver.value.FirstName + ' ' + this.formDriver.value.LastName,
					primaryDriverName: this.applicant,
				},
			})
		);
	}

	findYearOnDate(date) {
		return date ? ' • ' + this.formattedDateService.findYearOnDate(date) : '';
	}
}
