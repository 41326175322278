<section class="main-content">
	<app-page-heading
		[subtitle]="'titles.multipleOptions' | translate"
		[title]="'titles.discountAvailability' | translate"
	></app-page-heading>

	<form [formGroup]="form" class="all-questions-wrapper bigger">
		<div class="questions-in-row">
			<div class="question-container">
				<app-checkbox-image
					[value]="form.controls['SmokeDetector'].value"
					image="smokeAlarm"
					[label]="'safetyAlarms.smokeAlarm' | translate"
					name="SmokeDetector"
					selectedImage="smokeAlarmChecked"
				></app-checkbox-image>
			</div>

			<div class="question-container">
				<app-checkbox-image
					[value]="form.controls['DeadBoltLocks'].value"
					image="deadBolt"
					[label]="'safetyAlarms.deadBolt' | translate"
					name="DeadBoltLocks"
					selectedImage="deadBoltChecked"
				></app-checkbox-image>
			</div>

			<div class="question-container">
				<app-checkbox-image
					[value]="form.controls['FireExtinguisher'].value"
					image="fireExtinguisher"
					[label]="'safetyAlarms.fireExtinguisher' | translate"
					name="FireExtinguisher"
					selectedImage="fireExtinguisherChecked"
				></app-checkbox-image>
			</div>

			<div class="question-container">
				<app-checkbox-image
					[value]="form.controls['FireDetection'].value"
					image="fireDetectionDevice"
					[label]="'safetyAlarms.fireDetectionDevice' | translate"
					name="FireDetection"
					selectedImage="fireDetectionDeviceChecked"
				></app-checkbox-image>
			</div>

			<div class="question-container">
				<app-checkbox-image
					[value]="form.controls['BurglarAlarm'].value"
					image="burglarAlarm"
					[label]="'safetyAlarms.burglarAlarm' | translate"
					name="BurglarAlarm"
					selectedImage="burglarAlarmChecked"
				></app-checkbox-image>
			</div>
		</div>
	</form>

	<next-button
		(validationFailed)="handleValidation($event)"
		[disabled]="!form.valid"
		[myForm]="{ form: form }"
		[title]="'buttons.continue' | translate"
	>
	</next-button>
</section>
