import { Directive, ElementRef, Input, Output, HostListener, EventEmitter, OnInit } from '@angular/core';
import { getDeviceType, MOBILE } from '../utils/general.utils';

@Directive({
	selector: 'toggle-visible',
})
export class ToggleVisibleDirective implements OnInit {
	activeElement: HTMLElement;
	@Input() isOpen: boolean;
	@Input() ignoreElement: any;
	@Input() ignoreClass: any;
	@Output() focusLost: EventEmitter<any> = new EventEmitter();
	@Output() closeOnClick: EventEmitter<any> = new EventEmitter();
	isVisible = false;
	isTabPressed = false;
	documentClickHandler: any;

	constructor(private el: ElementRef) {}

	@HostListener('document:touchstart', ['$event.target'])
	@HostListener('document:touch', ['$event.target'])
	@HostListener('document:click', ['$event.target'])
	onClick(targetElement) {
		if (
			this.ignoreElement &&
			this.ignoreElement.nativeElement.children[0].className === targetElement.className &&
			!this.isVisible &&
			this.isOpen
		) {
			this.isVisible = true;
			return;
		}
		if (this.ignoreClass && this.ignoreClass === targetElement.className && !this.isVisible && this.isOpen) {
			this.isVisible = true;
			return;
		}

		if (this.isOpen && this.el.nativeElement !== targetElement && !this.el.nativeElement.contains(targetElement)) {
			this.activeElement = targetElement;
			this.closeOnClick.emit();
			this.isVisible = false;
		}
	}

	@HostListener('document:keydown', ['$event'])
	onKeyDown(event) {
		this.isTabPressed = event.keyCode === 9;
	}

	@HostListener('document:focusin', ['$event.target'])
	onFocus(targetElement: HTMLElement) {
		if (!this.isTabPressed) {
			return;
		}
		if (this.isOpen && !this.el.nativeElement.contains(targetElement)) {
			this.activeElement = targetElement;
			this.focusLost.emit();
			this.isVisible = false;
		}
		this.isTabPressed = false;
	}

	ngOnInit() {
		const documentElm = Object.assign({}, document);
		const mobileDevice = getDeviceType() === MOBILE;
		const eventTouch = 'touchstart';
		const eventClick = 'click';
		const eventBind = mobileDevice ? eventTouch : eventClick;
	}
}
