import { Component } from '@angular/core';
import { SectionBaseComponent } from '../section-base.component';

@Component({
	selector: 'app-plain-text',
	templateUrl: './plain-text.component.html',
	styleUrls: ['./plain-text.component.scss'],
	inputs: ['section'],
})
export class PlainTextComponent extends SectionBaseComponent {
	constructor() {
		super();
	}

	get textAlight(): string {
		return this.section.properties['--lp-pt-ta'];
	}
}
