import { Component, Input } from '@angular/core';

import { IProgressMeter } from 'src/app/store/states/progress-meter.state';
import { FlowType } from '../../../enums/step-groups.enum';

@Component({
	selector: 'app-progress-meter',
	templateUrl: './progress-meter.component.html',
	styleUrls: ['./progress-meter.component.scss'],
})
export class ProgressMeterComponent {
	@Input() pm: IProgressMeter;

	get width(): number {
		const steps = this.pm.flowOptions[this.pm.flowType];
		const activeStepName = steps[this.pm.activeStep].name;
		const activeStepIndex = steps
			.filter((step) => step?.stepProperties?.progressMeter.flowType === FlowType.qq)
			.map(({ name }) => name)
			.indexOf(activeStepName);

		return (activeStepIndex / steps.length) * 100;
	}
}
