import { Component, HostBinding, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';
import moment from 'moment';

import { IAppState } from 'src/app/store/states/app.state';
import { BaseFormControlComponent } from '../base-form-control.component';
import { EventsService } from 'src/app/services/events.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDatepickerProviders } from '../../../constants/mat-datepicker-constants';

@UntilDestroy()
@Component({
	selector: 'app-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss'],
	providers: MatDatepickerProviders,
})
export class DatepickerComponent extends BaseFormControlComponent implements OnInit {
	@Input() placeholder;
	@Input() minDate: Date;
	@Input() maxDate: Date;
	@Input() labelInsideField: string;
	@Input() theControl;
	@Input() format: string;
	@Input() notshowLabelWhenFilled: boolean;
	moment = moment;
	displayValue: string;

	maskedInputForm: FormGroup;

	constructor(
		injector: Injector,
		protected store: Store<IAppState>,
		eventsService: EventsService,
		private dateAdapter: DateAdapter<any>,
		private translateService: TranslateService
	) {
		super(injector, store, eventsService);
	}

	@HostBinding('class.error') get invalid() {
		return this.isInvalid();
	}

	ngOnInit(): void {
		super.ngOnInit();
		if (this.translateService.currentLang) {
			this.dateAdapter.setLocale(this.translateService.currentLang);
		}
		this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe((langChangeEvent) => {
			if (langChangeEvent.lang) {
				setTimeout(() => {
					this.dateAdapter.setLocale(langChangeEvent.lang);
				});
			}
		});

		this.value = this.theControl.value[this.name];
		this.displayAction();

		let value = this.moment(this.value).startOf('day').format('MM/DD/YYYY');

		this.maskedInputForm = new FormGroup({
			maskedInput: new FormControl(value === 'Invalid date' ? '' : value),
		});

		this.maskedInputForm.controls.maskedInput.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			value = this.format ? this.moment(value).format(this.format) : this.moment(value).toDate();

			if (value !== 'Invalid date') {
				this.theControl.patchValue({ [this.name]: value });
			}
		});
	}

	dateChange(event) {
		this.value = this.handleDateChangeValueForDatepicker(event);
		// used for date format
		setTimeout(() => {
			this.theControl.patchValue({ [this.name]: this.value });
			this.maskedInputForm.patchValue(
				{ maskedInput: this.moment(this.value).startOf('day').format('MM/DD/YYYY') },
				{
					emitEvent: false,
				}
			);
		}, 1);
	}

	handleDateChangeValueForDatepicker(event: any): string | null {
		const eventValue = event ? event.value || new Date(event.targetElement.value) : null;
		let targetValue = null;

		if (event && eventValue) {
			const momentTimezoneDate = this.moment(eventValue);

			if (momentTimezoneDate.isValid()) {
				targetValue = momentTimezoneDate.startOf('day').format('YYYY-MM-DD');
			}
		}

		return targetValue;
	}

	handleFocus() {
		const field: FormControl = this.controlContainer.control.controls[this.name];
		field.markAsUntouched();
	}

	isInvalid() {
		const field = this.controlContainer.control.controls[this.name];
		if (!field) {
			return false;
		}
		return field.invalid && field.touched;
	}
}
