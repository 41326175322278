import { Component, Input } from '@angular/core';

import { IProgressMeter } from '../../../store/states/progress-meter.state';
import { StepsGroup } from '../../../models/step-groups.model';

@Component({
	selector: 'app-progress-meter-header',
	templateUrl: './progress-meter-header.component.html',
	styleUrls: ['./progress-meter-header.component.scss'],
})
export class ProgressMeterHeaderComponent {
	@Input() pm: IProgressMeter;

	constructor() {}

	get groupedSteps(): StepsGroup[] {
		return this.pm.groupedSteps;
	}

	id(_, item): string {
		return item.name;
	}
}
