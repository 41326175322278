import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { RoutingService } from 'src/app/services/routing.service';
import { EventActions } from '../../../../../../models/event-data.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isMobile } from 'src/app/utils/general.utils';
import { selectActiveTheme } from '../../../../../../store/selectors/active-theme.selector';
import { StepsEnum } from '../../../../../../enums/steps.enum';
import { EventsService } from 'src/app/services/events.service';
import { selectLanguageSettings } from '../../../../../../store/selectors/languages.selector';
import { combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
	templateUrl: './technical-error.component.html',
})
export class TechnicalErrorComponent implements OnInit {
	EventActions = EventActions;
	title: string;
	subtitle: string;
	agentPhoneNumber: string;
	isMobile: boolean;
	callAgentButtonOn: any;
	backButtonOn: any;
	callAgentButtonUrl: string;
	backButtonUrl: string;
	callAgentButtonText: boolean;

	constructor(
		private readonly store: Store,
		private readonly routingService: RoutingService,
		private eventsService: EventsService
	) {}

	ngOnInit(): void {
		this.isMobile = isMobile();

		combineLatest([this.store.select(selectActiveTheme), this.store.select(selectLanguageSettings)])
			.pipe(untilDestroyed(this))
			.subscribe(([theme, { selected }]) => {
				this.title = theme.texts[StepsEnum.TECHNICAL_ERROR].title;
				this.agentPhoneNumber = theme.agentPhoneNumber;
				this.subtitle = theme.texts[StepsEnum.TECHNICAL_ERROR].subTitle;
				this.callAgentButtonOn = theme.texts[StepsEnum.TECHNICAL_ERROR].callAgentButtonOn;
				this.callAgentButtonUrl = theme.texts[StepsEnum.TECHNICAL_ERROR].callAgentButtonUrl;
				this.backButtonOn = theme.texts[StepsEnum.TECHNICAL_ERROR].backButtonOn;
				this.backButtonUrl = theme.texts[StepsEnum.TECHNICAL_ERROR].backButtonUrl;
				this.callAgentButtonText = !!theme.texts[StepsEnum.TECHNICAL_ERROR][`callAgentButtonText:${selected}`]?.length;
			});
	}

	callAgent(): void {
		if (this.callAgentButtonUrl) {
			this.routingService.navigateToExternal(this.callAgentButtonUrl, true);
		} else {
			this.routingService.callTo(this.agentPhoneNumber);
		}
	}

	goHome(): void {
		if (this.backButtonUrl) {
			this.routingService.navigateToExternal(this.backButtonUrl, true);
		} else {
			this.routingService.navigateToHomePage();
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'go-home',
			button: 'go-home',
			module: 'technical-error-module',
		});
	}
}
