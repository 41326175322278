<section class="main-content">
	<div class="container-success">
		<app-image class="payment-success-image" name="paymentSuccess" role="presentation"></app-image>

		<app-page-heading
			subtitleKey="payment-success-escrow.subTitle"
			titleKey="payment-success-escrow.title"
		></app-page-heading>

		<a
			*ngIf="backButtonOn"
			[eventData]="{
				action: eventActions.BUTTON_CLICK,
				label: 'go-home',
				button: 'go-home',
				module: 'payment-success-escrow'
			}"
			[href]="backButtonUrl"
			appClickEvent
			class="app-button go-home-button"
			tabindex="0"
		>
			<span class="title">
				<span appTranslation="payment-success-escrow.backButtonText"></span>
				<i appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
			</span>
		</a>
	</div>
</section>
