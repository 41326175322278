<section class="main-content">
	<app-page-heading [title]="title"></app-page-heading>
	<form #myForm="ngForm" [formGroup]="form" class="all-questions-wrapper">
		<div class="additional-questions-container">
			<div class="question-container">
				<div class="question-wrapper show">
					<label class="input-label" id="NumberOfMortgagees">
						{{ "titles.needForMortgage" | translate }}
					</label>
					<app-yes-no name="NumberOfMortgagees"></app-yes-no>
				</div>
			</div>

			<div class="question-container">
				<div class="question-wrapper show">
					<label class="input-label" id="PL_AdditionalStructures_Pool"
						>{{ "titles.swimmingPoolAvailability" | translate }}
					</label>
					<app-yes-no name="PL_AdditionalStructures_Pool"></app-yes-no>
				</div>
			</div>

			<div *ngIf="!!form.controls['PL_AdditionalStructures_Pool'].value" [@inOutAnimation] class="question-container">
				<div class="question-wrapper show">
					<label class="input-label" id="PL_PoolFeatures_4ftFence">{{
						"titles.swimmingPoolHaveFence" | translate
					}}</label>
					<app-yes-no name="PL_PoolFeatures_4ftFence"></app-yes-no>
				</div>
			</div>

			<div class="question-container">
				<div class="question-wrapper show">
					<label class="input-label" id="PL_AdditionalStructures_Trampoline"
						>{{ "titles.trampolineAvailability" | translate }}
					</label>
					<app-yes-no name="PL_AdditionalStructures_Trampoline"></app-yes-no>
				</div>
			</div>
		</div>
	</form>

	<next-button
		isFullQuote="true"
		(validationFailed)="handleValidation($event)"
		[disabled]="!form.valid"
		[myForm]="myForm"
		[title]="'buttons.continue' | translate"
	>
	</next-button>
</section>
