import { AfterViewInit, Component, Injector, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { untilDestroyed } from '@ngneat/until-destroy';
import { ActionApiService } from 'src/app/services/action-api.service';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../../base-page.component';

@Component({
  selector: 'app-vehicle-cover',
  templateUrl: './vehicle-cover.component.html',
  styleUrls: ['./vehicle-cover.component.scss']
})
export class VehicleCoverComponent extends BasePageComponent implements OnInit, AfterViewInit {

  @Input() formVehicle;
  actionApiService: ActionApiService;

  constructor(actionApiService: ActionApiService,
    injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>) {
    super(injector, store);
    this.actionApiService = actionApiService;

  }

  ngOnInit(): void {
    this.initform();
  }


  ngAfterViewInit(): void {
    this.baseNgAfterViewInit();
  }

  initform() {
    this.form = this.formVehicle;
    this.form.controls['CompDeductible'].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === 'NoCoverage') {
        this.formVehicle.get('CollDeductible').setValue('NoCoverage');
        this.formVehicle.get('CollDeductible').setValue('NoCoverage');
      }
    });
  }

  getVehicleLogoName(make: string): string {
    return make.split(' ').join('-').toLowerCase().replace('.', '');
  }
}
