<section class="main-content error-page">
	<img
		*ngIf="imageSrc"
		[ngClass]="{ auto: imageAutoDimension }"
		[src]="imageSrc"
		alt="null"
		class="error-logo"
		role="presentation"
	/>

	<app-image *ngIf="imageName" [name]="imageName" alt="null" role="presentation"></app-image>

	<app-page-heading
		[subtitleKey]="subtitleKey"
		[subtitle]="subtitle"
		[titleKey]="titleKey"
		[title]="title"
	></app-page-heading>

	<p *ngIf="friendlyId" class="text-bold quote-number">
		{{ "labels.quoteNumber" | translate }} {{ ": " }} {{ friendlyId }}
	</p>

	<div class="buttons-footer">
		<ng-content></ng-content>
	</div>
</section>
