import { Component } from '@angular/core';

import { Store } from '@ngrx/store';

import { RoutingService } from 'src/app/services/routing.service';
import { showPopupAction } from 'src/app/store/actions/popup.actions';
import { EventActions } from '../../../../../../models/event-data.model';

@Component({
	templateUrl: './page-not-found-error.component.html',
})
export class PageNotFoundErrorComponent {
	eventActions = EventActions;

	constructor(private readonly store: Store, private readonly routingService: RoutingService) {}

	callAgent(): void {
		this.store.dispatch(
			showPopupAction({
				componentName: 'contact-agent',
				payload: { text: 'popups.titles.contact-agent', gtmLabel: 'ContactAgentPopUp' },
			})
		);
	}

	goHome(): void {
		this.routingService.navigateToHomePage();
	}
}
