<app-page-heading subtitleKey="anchorCarrier.subTitle" titleKey="anchorCarrier.title"></app-page-heading>

<div class="anchor-content">
	<div class="carrier-header">
		<app-image *ngIf="image" [alt]="anchorCarrier.carrier + ' logo'" [name]="image" class="image"></app-image>

		<img
			*ngIf="!image"
			[attr.alt]="anchorCarrier.carrier + ' logo'"
			[src]="actionApiService.getLogo(anchorCarrier.carrier, anchorCarrier.lob)"
			class="image"
			role="presentation"
		/>

		<h5 class="effective-date-title">{{ "labels.dueToday" | translate | titlecase }}</h5>
		<p class="price">
			{{ price | zeroOrTwoDigitsFraction }}
		</p>
		<p class="price-info">
			{{ termNumber }} {{ displayTerm | translate | titlecase }}. {{ "labels.totalPremium" | translate | titlecase }}:
			{{ totalPremium | zeroOrTwoDigitsFraction }}
		</p>
		<button (click)="onClick()" appTranslation="anchorCarrier.button" class="bridge-btn"></button>
		<p appTranslation="anchorCarrier.callAgentTitle" class="call-to-agent"></p>
	</div>

	<div class="coverage-info">
		<h5 appTranslation="anchorCarrier.covMainTitle" class="header"></h5>

		<app-coverages-list [selectedRate]="anchorCarrier"></app-coverages-list>

		<p appTranslation="anchorCarrier.legalText" class="legal-text"></p>
	</div>
</div>
