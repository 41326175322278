import { Component, Input, OnInit } from '@angular/core';

import { combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CoverageModel } from '../../../models/coverage.model';
import { COVERAGES_TEXTS } from '../../layout/popup/all-coverages/texts.data';
import { selectQuoteData } from '../../../store/selectors/quote-data.selectors';
import { ADDITIONAL_COVERAGES_TEXTS } from '../../layout/popup/additional-coverages/texts.data';
import { NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES_POPUP } from '../../../utils/general.utils';
import { IAppState } from '../../../store/states/app.state';
import { IQuoteDataState } from '../../../store/states/quote-data.state';
import { LobsEnum } from '../../../enums/lobs.enum';
import { selectInterviewMetadata } from '../../../store/selectors/interview-metadata.selector';
import { RateService } from '../../layout/pages/shared/result/services/rate.service';

@UntilDestroy()
@Component({
	selector: 'app-coverages-list',
	templateUrl: './coverages-list.component.html',
	styleUrls: ['./coverages-list.component.scss'],
})
export class CoveragesListComponent implements OnInit {
	@Input() selectedRate: CoverageModel;

	rates: CoverageModel[];
	activeAdditionalCoverages = [];
	showAdditionalCoveragesSection = false;
	lobsAdditionalCoveragesMode = [
		[LobsEnum.PERSONAL_HOME],
		[LobsEnum.FLOOD, LobsEnum.PERSONAL_HOME],
		[LobsEnum.PERSONAL_AUTO, LobsEnum.PERSONAL_HOME],
	];
	quoteData: IQuoteDataState;
	coveragesTexts = COVERAGES_TEXTS;
	discounts: { name: string }[];
	cars = [];
	pets = [];

	constructor(protected store: Store<IAppState>, private rateServices: RateService) {}

	ngOnInit(): void {
		combineLatest([this.store.select(selectQuoteData), this.store.select(selectInterviewMetadata)])
			.pipe(untilDestroyed(this))
			.subscribe(([quoteData, interviewMetadata]) => {
				this.quoteData = quoteData;
				this.pets = quoteData.Pets;
				this.rates = this.rateServices.transformRates([this.selectedRate]).map((rate) => ({
					...rate,
					isSelected: rate.selected,
				}));

				this.lobsAdditionalCoveragesMode.forEach((lobs) => {
					if (lobs.every((value) => interviewMetadata.lobSelection.includes(value))) {
						this.showAdditionalCoveragesSection = true;
					}
				});

				ADDITIONAL_COVERAGES_TEXTS.forEach((r) => {
					if (quoteData[r.name] && !NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES_POPUP.includes(quoteData[r.name])) {
						this.activeAdditionalCoverages.push(r.name);
					}
				});

				this.getDiscounts();
				this.initLobSpecificRates();
			});
	}

	initLobSpecificRates() {
		const rateForAuto = this.rates.filter((rate) => rate.lob === LobsEnum.PERSONAL_AUTO);

		if (rateForAuto.length) {
			this.createCarsResponse(rateForAuto[0]);
		}
	}

	createCarsResponse(rate: CoverageModel): void {
		this.cars = [];

		this.store.select(selectQuoteData).subscribe((res) => {
			for (let i = 0; i < Object.keys(rate.coverages.entityCoverages['personalAuto']).length; i++) {
				const p = rate.coverages.entityCoverages['personalAuto'][i + 1];
				const personalVehicles = res.PersonalVehicles.concat(res.discoverVehicles)
					.concat(res.manualVehicles)
					.filter((r) => r.enabled); // enabled added when going through the vehicles page but when coming though page skipping don't have enabled

				personalVehicles.forEach((car) => {
					const carToAdd = {
						PLMake: car.PLMake,
						PLModel: car.PLModel,
						PLYear: car.PLYear,
						VIN: car.VIN,
						coverages: [
							{
								coverage: 'ComprehensiveDeductible',
								value: p.find((r) => r.coverage === 'ComprehensiveDeductible').value,
							},
							{ coverage: 'CollisionDeductible', value: p.find((r) => r.coverage === 'CollisionDeductible').value },
							{
								coverage: 'Transportation_Expense',
								value: p.find((r) => r.coverage === 'Transportation_Expense').value,
							},
							{ coverage: 'Towing_AND_Labor', value: p.find((r) => r.coverage === 'Towing_AND_Labor').value },
						],
					};

					this.cars.push(carToAdd);
				});
			}
		});
	}

	filterAdditionalCoverages(rateCoverages, lob) {
		// return only coverages that are additional
		// check if rateCoverages has additional coverages
		// and if user added them
		// and if they are not in NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES_POPUP
		let data = rateCoverages.filter((i) => {
			return (
				this.coveragesTexts[lob]?.[i.coverage]?.filter((i) => i.isAdditionalCoverage).length > 0 &&
				!NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES_POPUP.includes(i.value) &&
				this.activeAdditionalCoverages.includes(i.coverage)
			);
		});

		return data.length > 0 ? data : [];
	}

	getEntityCoveragesArray(lob: string, entityCoverages) {
		const entityCoveragesLobName = lob === LobsEnum.PERSONAL_AUTO ? 'personalAuto' : lob.toLowerCase();

		return Object.keys(entityCoverages[entityCoveragesLobName]).map(
			(key) => entityCoverages[entityCoveragesLobName][key]
		);
	}

	getEntityTitle(lob: string, index: number) {
		switch (lob) {
			case LobsEnum.PERSONAL_AUTO:
				if (this.cars && this.cars.length) {
					return `${this.cars[index].PLYear} ${this.cars[index].PLMake} ${this.cars[index].PLModel}`;
				} else {
					return '';
				}
			case LobsEnum.PETS:
				if (this.pets && this.pets.length) {
					return this.pets[index].PLPetName;
				} else {
					return '';
				}
		}
	}

	getVehicleLogoName(make: string): string {
		return make.split(' ').join('-').toLowerCase().replace('.', '');
	}

	getCoverageTexts(coverage: { coverage: string; value: any }, lob: string) {
		if (coverage.coverage === 'ReplaceMentCostContents' && coverage.value === 'Actual Cash Value') {
			return this.coveragesTexts[lob]['ReplaceMentCostContentsACV'].filter((cov) => cov.states === 'default')[0];
		}

		if (this.coveragesTexts[lob][coverage.coverage]) {
			return this.getConvergeTextByState(coverage, lob);
		}
	}

	getConvergeTextByState(coverage: { coverage: string; value: any }, lob: string) {
		let cov = this.coveragesTexts[lob][coverage.coverage].filter(
			(cov) => cov.states.indexOf(this.quoteData.PropertyAddress.State) > -1
		);
		if (cov.length > 0) {
			return cov[0];
		} else {
			return this.coveragesTexts[lob][coverage.coverage].filter((cov) => cov.states === 'default')[0];
		}
	}

	getCoveragePrice(coverage) {
		if (!coverage) {
			return '';
		}
		if (coverage.value) {
			if (coverage.value.includes('/')) {
				const [value1, value2] = coverage.value.split('/');
				let ret;
				if (!isNaN(value2)) {
					ret = `$${value1}K / $${value2}K`;
				} else {
					ret = `${value1} / ${value2}`;
				}
				if (coverage.coverage === 'Transportation_Expense') {
					ret += '';
				}
				return ret;
			} else {
				return coverage.value;
			}
		}
	}

	reArrangeArray(array) {
		let arr = [...array];
		//make 'AnimalLiabilityCoverage' last coverage
		if (arr.findIndex((i) => i.coverage == 'AnimalLiabilityCoverage') !== -1) {
			let AnimalLiabilityCoverageObject = arr.find((i) => i.coverage == 'AnimalLiabilityCoverage');
			arr.splice(
				arr.findIndex((i) => i.coverage == 'AnimalLiabilityCoverage'),
				1
			);
			arr.push(AnimalLiabilityCoverageObject);
		}
		const el = arr.find((item) => item.coverage === 'Dwelling');
		const l = arr.filter((item) => item.coverage !== 'Dwelling');

		return el ? [el, ...l] : l;
	}

	filterCoverages(rateCoverages, lob) {
		// return only coverages that are not additional
		return rateCoverages.filter((i) => {
			return this.coveragesTexts[lob]?.[i.coverage]?.filter((i) => !i.isAdditionalCoverage).length > 0;
		});
	}

	getDiscounts() {
		this.rates.forEach((i) => {
			if (i.discounts) {
				this.discounts = i.discounts;
			}
		});
	}

	isValueNaN(input) {
		return isNaN(input);
	}
}
