<error-message
	[fieldDisplayName]="postfix || name"
	[field]="controlContainer.control.controls[this.name]"
	[isFocus$]="isFocus$"
></error-message>
<app-label
	*ngIf="label"
	[label]="label"
	[name]="this.name"
	[popupLink]="popupLink"
	[subLabel]="subLabel"
	[tabindex]="tabindex ? tabindex : 0"
>
</app-label>
<div [id]="name" [ngClass]="{ disabled: disabled, error: isInvalid() }" class="numeric-stepper-container">
	<div class="icons-container">
		<button
			#minus
			(blur)="onBlur($event)"
			(click)="decrement()"
			(focus)="onFocus($event)"
			[attr.aria-label]="('labels.pressToDecrement' | translate) + ' ' + label"
			[attr.disabled]="value <= minValue ? true : null"
			[ngClass]="{ filled: value > minValue }"
			[tabindex]="tabindex ? tabindex : 0"
			class="minus-button"
			type="button"
		>
			<i class="material-icons">remove</i>
		</button>
		<div class="input-container">
			<input
				[attr.aria-describedby]="
					controlContainer.control.controls[this.name].invalid ? name.split('.').join('_') + '_error' : null
				"
				[attr.id]="name.split('.').join('_')"
				[attr.name]="name"
				[ngModel]="value"
				aria-live="polite"
				autocomplete="off"
				disabled
				readonly="true"
				type="hidden"
			/>
		</div>
		<span class="number-stepper">{{ value || 0 }}</span>
		<button
			#plus
			(blur)="onBlur($event)"
			(click)="increment()"
			(focus)="onFocus($event)"
			[attr.aria-label]="('labels.pressToIncrement' | translate) + ' ' + label"
			[attr.disabled]="this.maxValue <= this.value ? true : null"
			[ngClass]="{ filled: disablePlusButtom() }"
			[tabindex]="tabindex ? tabindex : 0"
			class="plus-button"
			type="button"
		>
			<i class="material-icons">add</i>
		</button>
	</div>
</div>

<form [formGroup]="controlContainer.control">
	<input (ngModelChange)="setValue()" [attr.name]="name" [formControlName]="name" type="hidden" />
</form>
