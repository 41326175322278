import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import { Subject, merge, fromEvent, timer } from 'rxjs';
class BnNgIdleService {
  constructor() {
    this.expired$ = new Subject();
  }
  startWatching(timeOutSeconds) {
    this.idle$ = merge(fromEvent(document, 'mousemove'), fromEvent(document, 'click'), fromEvent(document, 'mousedown'), fromEvent(document, 'keypress'), fromEvent(document, 'DOMMouseScroll'), fromEvent(document, 'mousewheel'), fromEvent(document, 'touchmove'), fromEvent(document, 'MSPointerMove'), fromEvent(window, 'mousemove'), fromEvent(window, 'resize'));
    this.timeOutMilliSeconds = timeOutSeconds * 1000;
    this.idleSubscription = this.idle$.subscribe(res => {
      this.resetTimer();
    });
    this.startTimer();
    return this.expired$;
  }
  startTimer() {
    this.timer$ = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe(res => {
      this.expired$.next(true);
    });
  }
  resetTimer(timeOutSeconds) {
    this.timer$.unsubscribe();
    if (timeOutSeconds) {
      this.timeOutMilliSeconds = timeOutSeconds * 1000;
    }
    this.startTimer();
  }
  stopTimer() {
    this.timer$.unsubscribe();
    this.idleSubscription.unsubscribe();
  }
}
BnNgIdleService.ɵfac = function BnNgIdleService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BnNgIdleService)();
};
BnNgIdleService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: BnNgIdleService,
  factory: BnNgIdleService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BnNgIdleService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class BnNgIdleModule {}
BnNgIdleModule.ɵfac = function BnNgIdleModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BnNgIdleModule)();
};
BnNgIdleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BnNgIdleModule
});
BnNgIdleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [BnNgIdleService],
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BnNgIdleModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [],
      providers: [BnNgIdleService],
      exports: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of bn-ng-idle
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BnNgIdleModule, BnNgIdleService };
