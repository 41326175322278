import { AfterViewInit, Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { first, pairwise } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BasePageComponent } from '../../../../base-page.component';
import { Validations } from 'src/app/utils/validation';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { LoaderActions } from 'src/app/store/actions';
import {
	selectApplicationId,
	selectLobsInApatite,
} from '../../../../../../../store/selectors/interview-metadata.selector';
import { setOriginTypeOfDwelling } from 'src/app/store/actions/result-data.actions';
import { selectAddress, selectLobQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { ActionApiService } from 'src/app/services/action-api.service';
import { UpdateQuoteData } from 'src/app/store/actions/quote-data.actions';
import { UpdateInterviewMetadata, UpdateLobsInitialized } from 'src/app/store/actions/interview-metadata.actions';
import { selectAgentPhoneNumber } from '../../../../../../../store/selectors/active-theme.selector';
import { LobsEnum } from '../../../../../../../enums/lobs.enum';
import { selectSecretSource } from 'src/app/store/selectors/result-data.selectors';
import { LandingSectionModel } from '../../../../../../../models/landing-section.model';
import { GeneralPropsEnum } from '../../../../../../../models/general-props.enum';
import { ThemeModel } from '../../../../../../../models/theme.model';
import { SectionTypesEnum } from '../../../../../../../models/section-types.enum';
import { handleIncompleteAddressInAddressForm } from '../../../../../../../utils/general.utils';

@UntilDestroy()
@Component({
	selector: 'app-your-address',
	templateUrl: './your-address.component.html',
	styleUrls: ['./your-address.component.scss'],
})
export class YourAddressComponent extends BasePageComponent implements OnInit, AfterViewInit {
	@Input() theme: ThemeModel;

	lobs;
	actionApiService: ActionApiService;
	isMainLegalTextVisible = false;
	oneLobInApatite = undefined;
	isD2CPage = false;
	showManualFields;
	applicationId: string;
	classes: string;

	fieldsNames = {
		AddressLine1: 'PropertyAddress.AddressLine1',
		AddressLine2: 'PropertyAddress.AddressLine2',
		City: 'PropertyAddress.City',
		State: 'PropertyAddress.State',
		ZipCode: 'PropertyAddress.ZipCode',
		County: 'PropertyAddress.County',
	};

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		actionApiService: ActionApiService
	) {
		super(injector, store, quoteDataService);
		this.actionApiService = actionApiService;
		this.loggerService.log('AppInitialized')
	}

	get landingPage(): LandingSectionModel {
		return this.theme[GeneralPropsEnum.landingPage].filter(({ type }) => type === SectionTypesEnum.sectionMain)[0];
	}

	get sectionTexts(): any {
		return this.theme[GeneralPropsEnum.texts][this.landingPage.name];
	}

	get isNoPads(): boolean {
		const bg = this.landingPage.properties['--start-page-form-bg'].toLowerCase();
		const mainBg = this.landingPage.properties['--start-page-bg'].toLowerCase();
		return (
			(bg === 'none' || bg === 'transparent' || bg === '' || bg === mainBg) &&
			!this.classes.includes('start-page-image-full')
		);
	}

	get isTitleInside(): boolean {
		return this.landingPage[GeneralPropsEnum.additionalClasses]?.startTitlePlace === 'inside';
	}

	get isSubtitleInside(): boolean {
		return this.landingPage[GeneralPropsEnum.additionalClasses]?.startSubtitlePlace === 'inside';
	}

	get addressForm(): FormGroup {
		return this.form.get('PropertyAddress') as FormGroup;
	}

	get hasNoMobImage(): boolean {
		return (
			this.landingPage.properties['--start-page-image-mob-display']?.toLowerCase() === 'none' ||
			!this.landingPage.images.startPageImageMob
		);
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.store
			.select(selectSecretSource)
			.pipe(untilDestroyed(this))
			.subscribe((secretStore) => {
				if (secretStore) {
					let typeOfBusiness = secretStore.split('-')[1];
					this.isD2CPage = typeOfBusiness == 'pl' || typeOfBusiness == 'cl';
				} else {
					this.isD2CPage = true;
				}
			});

		this.initForm();
		setTimeout(() => this.patchData(this.form));
		this.registerOnChange(this.form);
		this.store.dispatch(LoaderActions.Hideloader());

		this.store
			.select(selectAgentPhoneNumber)
			.pipe(untilDestroyed(this))
			.subscribe((phoneNumber) => {
				this.phoneNumber = phoneNumber;
			});

		// TODO: move to init app
		this.store
			.select(selectLobsInApatite)
			.pipe(untilDestroyed(this))
			.subscribe((lobsInApatite) => {
				if (lobsInApatite && lobsInApatite.length > 0) {
					this.store.dispatch(
						UpdateQuoteData({
							data: {
								lobBundle: lobsInApatite.filter((word) => word?.includes(',')),
							},
						})
					);
				} else {
					this.store.dispatch(
						UpdateQuoteData({
							data: {
								lobBundle: [LobsEnum.HOME_AUTO],
							},
						})
					);
				}

				if (lobsInApatite && lobsInApatite.length === 1) {
					this.oneLobInApatite = lobsInApatite[0];
				}
			});
		this.store.select(selectApplicationId).subscribe((appId) => {
			this.applicationId = appId;
		});

		this.store
			.select(selectAddress)
			.pipe(first())
			.subscribe(() => {
				this.patchData(this.form, false);
			});

		this.isMainLegalTextVisible = this.sectionTexts.mainLegalTextVisible;
		this.classes = Object.values(this.landingPage[GeneralPropsEnum.additionalClasses]).join(' ');

		// if google maps api failed
		window['gm_authFailure'] = () => {
			this.showManualFields = true;
		};
	}

	initForm(): void {
		this.form = new FormGroup(
			{
				PropertyAddress: new FormGroup({
					AddressLine1: new FormControl(
						{
							value: null,
							disabled: false,
						},
						[Validators.required, Validators.maxLength(50), Validations.isPoBox]
					),
					AddressLine2: new FormControl({ value: null, disabled: false }, [Validators.maxLength(50)]),
					City: new FormControl(
						{
							value: null,
							disabled: false,
						},
						[Validators.required, Validators.maxLength(25)]
					),
					State: new FormControl(
						{
							value: null,
							disabled: false,
						},
						[Validators.required, Validators.maxLength(2)]
					),
					ZipCode: new FormControl(
						{
							value: null,
							disabled: false,
						},
						[Validators.required, Validators.maxLength(5), Validations.zipStrictLength(5)]
					),
					County: new FormControl({ value: null, disabled: false }, []),
				}),
			},
			null,
			null
		);
		this.form.controls.PropertyAddress.valueChanges.pipe(pairwise()).subscribe(([prev, next]: [any, any]) => {
			if (!(JSON.stringify(prev) === JSON.stringify(next))) {
				this.store.dispatch(UpdateInterviewMetadata({ data: { prefillCall: true } }));
			}
		});
	}

	handleStartQuoteClick() {
		//the address has changed
		if (this.applicationId !== '') {
			this.updateAddressQuoteDataAndGoToNextStage();
		} else {
			this.createQuote();
		}
	}

	createQuote() {
		this.store
			.select(selectLobQuoteData)
			.pipe(first())
			.subscribe((lobsInPD) => {
				//check if one lob is return from apatite
				if (this.oneLobInApatite) {
					// retrieval quote
					if (lobsInPD) {
						/*
						 *	lobsInPD = there is lob in the policy data
						 *	if there is lob in PD and one lob in apatite they must be the same (otherwise we won't be in the d2c interview - (was true before we moved to multi-lob)) //TODO: need to update
						 */
						if (this.oneLobInApatite !== undefined && lobsInPD.length == 1) {
							this.updateAddressQuoteDataAndGoToNextStage();
						}
					} else {
						/*
						 * new empty quote
						 * create application once since we have one lob in apatite unless it's home where we need to create application in the next stages
						 */
						if (this.oneLobInApatite !== LobsEnum.PERSONAL_HOME) {
							this.actionApiService
								.initApplication([this.oneLobInApatite], this.addressForm.getRawValue())
								.subscribe((res) => {
									this.store.dispatch(UpdateLobsInitialized({ lobsInitialized: [this.oneLobInApatite] }));
									this.store.dispatch(UpdateInterviewMetadata({ data: { ...res.initial } }));

									// for renters only - save the origin type of dwelling
									this.store.dispatch(
										setOriginTypeOfDwelling({
											OriginTypeOfDwelling: res.initial['data']['data']['PLTypeOfDwelling']
												? res.initial['data']['data']['PLTypeOfDwelling']
												: '',
										})
									);
									// wait until update store is done
									setTimeout(() => {
										this.progressMeterService.goToNextPage();
									}, 0);
								});
						} else {
							this.progressMeterService.goToNextPage();
						}
					}
					//not one lob
				} else {
					this.progressMeterService.goToNextPage();
				}
			});
	}

	updateAddressQuoteDataAndGoToNextStage() {
		this.store
			.select(selectAddress)
			.pipe(first())
			.subscribe((data) => {
				this.actionApiService.updateApplication({ PropertyAddress: data }).subscribe((serverRes) => {
					// for renters only - save the origin type of dwelling
					this.store.dispatch(
						setOriginTypeOfDwelling({
							OriginTypeOfDwelling: serverRes['data']['PLTypeOfDwelling'] ? serverRes['data']['PLTypeOfDwelling'] : '',
						})
					);
					delete serverRes['data'].PersonalVehicles;
					delete serverRes['data'].Drivers;
					// TODO - DELETE PERSONAL VEHICLES AND DRIVERS INSIDE UpdateQuoteData
					this.store.dispatch(UpdateQuoteData({ data: serverRes['data'] }));
					// saving the real apatite because if user keep the same lob we won't go to update and we know if we should fail him on no apatite
					this.store.dispatch(
						UpdateInterviewMetadata({ data: { hasApplicationAppetite: serverRes['hasApplicationAppetite'] } })
					);
					// if one lob we can check the real apatite and show unavailable state, else user can change the lob in the next page and might have apatite
					if (!this.oneLobInApatite) {
						serverRes.hasApplicationAppetite = true;
					}

					this.progressMeterService.hasApatite.next(serverRes.hasApplicationAppetite);
					setTimeout(() => {
						this.progressMeterService.goToNextPage();
					});
				});
			});
	}

	handleAddressChange() {
		handleIncompleteAddressInAddressForm(this.addressForm);
	}
}
