import { Component } from '@angular/core';
import { GeneralPropsEnum } from '../../../../../../../models/general-props.enum';
import { CssPropertiesEnum } from '../../../../../../../models/css-properties.enum';
import { SectionBaseComponent } from '../section-base.component';

@Component({
	selector: 'app-testimonials',
	templateUrl: './testimonials.component.html',
	styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent extends SectionBaseComponent {
	constructor() {
		super();
	}

	get isSepVisible(): boolean {
		return !!this.section[GeneralPropsEnum.properties][CssPropertiesEnum.testimSep];
	}

	linkClick(url: string) {
		if (url) {
			window.open(url, '_blank').focus();

			return;
		}

		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
}
