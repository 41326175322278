import { Injectable } from '@angular/core';
import { CoverageModel } from '../models/coverage.model';
import { HttpClient } from '@angular/common/http';
import { LobService } from './lob.service';
import { LobsEnum } from '../enums/lobs.enum';

@Injectable({
	providedIn: 'root',
})
export class EscrowService {
	constructor(private httpClient: HttpClient, private lobService: LobService) {}

	getEscrowNote(selectedRate: CoverageModel, loanNumber, lender) {
		return {
			noteType: 'System Note',
			noteSubject: 'Escrow Bind Request',
			noteDescription: `Offline bind request received with the following details:
- Carrier: ${selectedRate.carrier}
- Product: ${this.lobService.getLobTitleForNote(selectedRate.lob as LobsEnum)}
- Package: ${selectedRate.packageType}
- Loan number: ${loanNumber}
- Lender: ${lender}`,
		};
	}
}
