import { Component, Input, OnInit } from '@angular/core';
import { LandingSectionModel } from '../../../../../../models/landing-section.model';
import { ThemeModel } from '../../../../../../models/theme.model';
import { Dictionary } from '../../../../../../../entities/key-value.interface';
import { LanguagesModel } from '../../../../../../models/languages.model';

@Component({
	template: '',
})
export class SectionBaseComponent implements OnInit {
	@Input() section: LandingSectionModel;
	@Input() theme: ThemeModel;
	@Input() langSettings: LanguagesModel;
	images: Dictionary<any>;

	button: string;
	url: string;
	lang: string;

	ngOnInit(): void {
		const selectedLang = this.langSettings.selected;
		this.images = this.theme.images;
		this.lang = selectedLang ? `:${selectedLang}` : '';
		this.button =
			this.theme.texts[this.section.name][`button${this.lang}`] || this.theme.texts[this.section.name][`button`];
		this.url = this.theme.texts[this.section.name].url;
	}
}
