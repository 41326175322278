import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';

import { BasePageComponent } from '../../base-page.component';
import { QuoteDataService } from '../../../../../services/quote-data.service';
import { IAppState } from '../../../../../store/states/app.state';
import { selectInterviewTexts } from '../../../../../store/selectors/interview-texts.selectors';
import { getSelectedCarrier, getSelectedRate } from '../../../../../store/selectors/result-data.selectors';
import { selectInterviewMetadata } from '../../../../../store/selectors/interview-metadata.selector';
import { ActionApiService } from '../../../../../services/action-api.service';
import { Validations } from '../../../../../utils/validation';
import { FormBuilder } from '@angular/forms';
import { DriverStateLicensed } from 'src/app/constants/field-options';
import { LobService } from '../../../../../services/lob.service';
import { CarriersEnum } from '../../../../../enums/carriers.enum';
import { StepsEnum } from '../../../../../enums/steps.enum';
import { SetFQReportsHandled } from '../../../../../store/actions/interview-metadata.actions';
import { LobsEnum } from '../../../../../enums/lobs.enum';

@Component({
	selector: 'app-payment-plan-escrow',
	templateUrl: './payment-plan-escrow.component.html',
	styleUrls: ['./payment-plan-escrow.component.scss'],
})
export class PaymentPlanEscrowComponent extends BasePageComponent implements OnInit, OnDestroy {
	totalPrice: number;
	applicationId: string;
	selectedCarrier: string;
	selectedLob: string;
	lobsInitialized: string[];
	apiInterval;
	isMortgageBillPayCheckboxOn = false;
	statesOptions = DriverStateLicensed;
	isPaymentVisible = false;
	isInitialVisible = false;
	noRates = false;
	isFQReportsHandled = false;

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private lobService: LobService,
		private fb: FormBuilder,
		private actionApiService: ActionApiService
	) {
		super(injector, store);
	}

	get customFields(): FormGroup {
		return this.form.get('CustomFields') as FormGroup;
	}

	get MortgageBillPayControl(): FormControl {
		return this.customFields.get(this.Fields.MortgageBillPay.name) as FormControl;
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.initForm();

		this.store
			.select(selectInterviewTexts)
			.pipe(untilDestroyed(this))
			.subscribe((stepTexts) => {
				this.isMortgageBillPayCheckboxOn = stepTexts['paymentEscrow']?.isMortgageBillPayCheckboxOn;

				if (this.isMortgageBillPayCheckboxOn) {
					this.Fields.MortgageBillPay = {
						...this.Fields.MortgageBillPay,
						labelKey: 'paymentEscrow.mortgageBillPayConsent',
					};

					this.form.addControl(
						'CustomFields',
						new FormGroup({
							[this.Fields.MortgageBillPay.name]: new FormControl({ value: null, disabled: false }, [
								Validators.requiredTrue,
							]),
						})
					);

					setTimeout(() => {
						if (
							this.quoteData.hasOwnProperty('CustomFields') &&
							this.quoteData.CustomFields.hasOwnProperty(this.Fields.MortgageBillPay.name)
						) {
							this.MortgageBillPayControl.setValue(
								this.quoteData.CustomFields[this.Fields.MortgageBillPay.name] === 'True' ||
								this.quoteData.CustomFields[this.Fields.MortgageBillPay.name]
							);
						}
					});
				}
			});

		combineLatest([
			this.store.select(getSelectedCarrier),
			this.store.select(selectInterviewMetadata),
			this.lobService.getLob$(),
			this.store.select(getSelectedRate),
		])
			.pipe(first())
			.subscribe(([selectedCarrier, { applicationId, FQReportsHandled, lobsInitialized }, lob, selectedRate]) => {
				this.selectedCarrier = selectedCarrier;
				this.lobsInitialized = lobsInitialized;
				this.applicationId = applicationId;
				this.selectedLob = lob;
				this.isPaymentVisible = this.selectedCarrier !== CarriersEnum.stillwater;
				this.isInitialVisible = true;
				this.totalPrice = selectedRate.premium;
				this.isFQReportsHandled = FQReportsHandled;

				// check if carrier is Stillwater
				if (!this.isPaymentVisible && lob === LobsEnum.PERSONAL_HOME) {
					const Context = {
						Carrier: selectedCarrier,
						Product: lob,
					};

					// make a submission call with FullQuoteReports type
					this.submissionFullQuoteReports({ ...Context });
				} else {
					this.isPaymentVisible = !!this.totalPrice;
					this.noRates = !this.totalPrice;
				}
			});
	}

	fireSingleSubmission(context: any): Observable<any> {
		return this.isFQReportsHandled
			? of(true)
			: this.actionService.singleSubmitQuote({
				Context: {
					...context,
					Scope: 'FullQuoteReports',
				},
			});
	}

	submissionFullQuoteReports(context: any): void {
		this.fireSingleSubmission(context).subscribe(() => {
			const intervalFunction = () => {
				this.actionApiService
					.post({
						action: 'api/submissionResults',
						endpoint: `applications`,
						applicationId: this.applicationId,
						Type: 'FullQuoteReports',
						...context,
					})
					.pipe(untilDestroyed(this))
					.subscribe((result: any) => {
						if (!result) {
							this.routingService.navigateToErrorPage(StepsEnum.TECHNICAL_ERROR);
							return;
						}

						if (result.status === 'Completed') {
							clearInterval(this.apiInterval);

							// check if FullQuoteReports response returns rates
							if (result.quotes?.length) {
								// show Escrow payment form
								this.isPaymentVisible = true;
							} else {
								// redirect to error page since rates is empty
								this.noRates = true;
							}

							this.store.dispatch(SetFQReportsHandled({ data: true }));
						}
					});
			};

			intervalFunction();

			this.apiInterval = setInterval(() => {
				intervalFunction();
			}, 5000);
		});
	}

	initForm(): void {
		this.form = this.fb.group({
			MortgageCompanyName: new FormControl(null, Validators.required),
			MortgageAccountNumber: new FormControl(null, Validators.required),
			MortgageBillPay: new FormControl(false, Validators.required),
			[this.Fields.MortgageCompanyAddress.name]: this.fb.group({
				AddressLine1: [null, [Validators.required, Validators.maxLength(50)]],
				AddressLine2: [null, [Validators.maxLength(50)]],
				City: [null, [Validators.required, Validators.maxLength(25)]],
				State: [null, [Validators.required, Validators.maxLength(2)]],
				ZipCode: [null, [Validators.required, Validations.zipStrictLength(5)]],
			}),
		});

		this.patchData(this.form);
		this.registerOnChange(this.form);
	}

	handleContinue() {
		const formValue: any = { ...this.form.value, ...this.customFields.value };

		delete formValue['CustomFields'];

		this.actionApiService
			.updateApplication(formValue, this.lobsInitialized, this.selectedCarrier)
			.pipe(first())
			.subscribe((serverRes) => {
				this.onUpdateApplicationResult(serverRes);
			});
	}
}
