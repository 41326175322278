import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

import { IAppState } from '../../../../../store/states/app.state';
import { selectActiveTheme } from '../../../../../store/selectors/active-theme.selector';
import { LandingSectionModel } from '../../../../../models/landing-section.model';
import { SectionTypesEnum } from '../../../../../models/section-types.enum';
import { GeneralPropsEnum } from '../../../../../models/general-props.enum';
import { Dictionary } from '../../../../../../entities/key-value.interface';
import { ThemeModel } from '../../../../../models/theme.model';
import { selectLanguageSettings } from '../../../../../store/selectors/languages.selector';
import { LanguagesModel } from '../../../../../models/languages.model';

@UntilDestroy()
@Component({
	selector: 'app-landing-page',
	templateUrl: './landing-page.component.html',
	styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
	sections: LandingSectionModel[];
	sectionTypes = SectionTypesEnum;
	images: Dictionary<any>;
	theme: ThemeModel;
	langSettings: LanguagesModel;

	constructor(protected store: Store<IAppState>) {}

	ngOnInit(): void {
		combineLatest([this.store.select(selectActiveTheme), this.store.select(selectLanguageSettings)])
			.pipe(untilDestroyed(this))
			.subscribe(([theme, langSettings]) => {
				this.theme = theme;
				this.sections = theme[GeneralPropsEnum.landingPage];
				this.images = theme[GeneralPropsEnum.images];
				this.langSettings = langSettings;
			});
	}
}
