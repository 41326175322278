import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActionApiService } from '../../../../../services/action-api.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../store/states/app.state';
import { FormArray, FormGroup } from '@angular/forms';
import { petBreedTypeOptions, petGenderOptions, petSpeciesOptions } from '../../../../../constants/field-options';
import { NextButtonComponent } from '../../../../next-button/next-button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, first } from 'rxjs/operators';
import { PetBreedModel } from '../../../../../models/pet-breed.model';
import { IField } from '../../../../../../entities/field.interface';
import { Fields } from '../../../../../constants/fields';
import { Actions, ofType } from '@ngrx/effects';
import { RoutingActions } from '../../../../../store/actions';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { pipe } from 'rxjs';
import { capitalize } from '../../../../../utils/general.utils';

@UntilDestroy()
@Component({
	selector: 'app-add-edit-pet',
	templateUrl: './add-edit-pet.component.html',
	styleUrls: ['./add-edit-pet.component.scss'],
})
export class AddEditPetComponent implements OnInit {
	@ViewChild(NextButtonComponent, { static: true }) nextButtonComponent: NextButtonComponent;
	Fields: IField = Fields;
	@Input() form: any;
	@Output() completed: EventEmitter<any> = new EventEmitter();
	@Output() cancelled: EventEmitter<any> = new EventEmitter();
	petSpeciesOptions = petSpeciesOptions;
	petBreedTypeOptions = petBreedTypeOptions;
	petGenderOptions = petGenderOptions;
	petBreedOptions = [];
	internalStep = 0;
	numberOfSteps = 3;
	controlNamesByInternalStep = [
		['PLPetSpecies', 'PLPetName'],
		['PLPetBreedType', 'PLPetBreed', 'PLPetGender', 'PetDOB'],
		['PLSpayedNeutered', 'PLHasDisease'],
	];

	constructor(
		private actionService: ActionApiService,
		protected store: Store<IAppState>,
		private actions$: Actions,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.form.controls.PLPetSpecies.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
			this.form.controls.PLPetBreed.reset();
			this.setPLPetBreedOptions();
		});

		this.form.controls.PLPetBreedType.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
			this.form.controls.PLPetBreed.reset();
			this.setPLPetBreedOptions();
		});

		this.setPLPetBreedOptions();

		this.actions$.pipe(ofType(RoutingActions.NavigateInternalStep), untilDestroyed(this)).subscribe((value) => {
			if (value && value.forward) {
				this.navigateInternal(value.forward);
			} else {
				this.goBack();
			}
		});
		this.setInternalStep(this.form.controls.internalStep.value);

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				pipe(untilDestroyed(this))
			)
			.subscribe(() => {
				this.onRouteChanged();
			});
	}

	onRouteChanged() {
		if (this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.step) {
			const stepQueryParamNumber = parseInt(this.activatedRoute.snapshot.queryParams.step, 10);
			if (isNaN(stepQueryParamNumber)) {
				this.handleCancel();
			} else {
				this.setInternalStep(stepQueryParamNumber - 1, false);
			}
		} else {
			this.handleCancel();
		}
	}

	initForm() {
		this.form = new FormGroup({
			petPagesFormArray: new FormArray([]),
		});
	}

	getProgressbarWidth(): string {
		return ((this.form.controls.internalStep.value + 1) / this.numberOfSteps) * 100 + '%';
	}

	handleSelectPetSpecies(value) {
		this.form.controls.PLPetSpecies.setValue(value, { onlySelf: true });
	}

	handleContinue() {
		if (!this.isLastInternalStep()) {
			this.nextButtonComponent.loading = false;
			this.navigateInternal(true);
		} else {
			this.form.controls.completed.setValue(true);
			this.setInternalStep(null);
			this.completed.emit();
		}
	}

	handleCancel() {
		this.setInternalStep(null);
		this.cancelled.emit();
	}

	isLastInternalStep() {
		return this.form.controls.internalStep.value + 1 === this.numberOfSteps;
	}

	updateStepNumberInRoute(queryStep: number | null) {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: {
				step: queryStep !== null ? queryStep.toString() : queryStep,
			},
			queryParamsHandling: 'merge',
		});
	}

	setInternalStep(stepNumber: number | null, shouldUpdateQueryParams = true) {
		if (stepNumber !== null) {
			this.form.controls.internalStep.setValue(stepNumber);
			this.updateStepNumberInRoute(stepNumber + 1);
		} else {
			this.form.controls.internalStep.setValue(0);
			if (shouldUpdateQueryParams) {
				this.updateStepNumberInRoute(null);
			}
		}
	}

	navigateInternal(forward: boolean) {
		const nextStep = this.form.controls.internalStep.value + 1 * (forward ? 1 : -1);
		this.setInternalStep(nextStep);
	}

	isCurrentStepValid(): boolean {
		return (
			this.controlNamesByInternalStep[this.form.controls.internalStep.value] &&
			!this.controlNamesByInternalStep[this.form.controls.internalStep.value].find(
				(controlName) => this.form.controls[controlName].invalid
			)
		);
	}

	goBack() {
		if (this.form.controls.internalStep.value > 0) {
			this.navigateInternal(false);
		} else {
			this.handleCancel();
		}
	}

	getNextButtonText(): string {
		if (!this.isLastInternalStep()) {
			return 'Continue';
		} else {
			return `${this.form.value.completed ? 'Save' : 'Add'} ${capitalize(this.form.controls.PLPetName.value)}`;
		}
	}

	private setPLPetBreedOptions() {
		if (this.form.value.PLPetSpecies && this.form.value.PLPetBreedType) {
			this.actionService
				.getPetBreed(this.form.value.PLPetSpecies, this.form.value.PLPetBreedType)
				.pipe(first())
				.subscribe((breedOptions: Array<PetBreedModel>) => {
					if (Array.isArray(breedOptions)) {
						this.petBreedOptions = breedOptions.map((option, index) => ({
							id: index,
							value: option.key,
							title: option.value,
						}));
					} else {
						this.petBreedOptions = [];
					}
				});
		}
	}
}
