import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { isEmpty } from 'src/app/utils/general.utils';
import { ISingleAddressClient } from 'src/entities/address-response.interface';
import { BasePageComponent } from '../../layout/pages/base-page.component';
import { UnMaskedInputComponent } from '../unmasked-input/unmasked-input.component';

@Component({
	selector: 'popover-address',
	templateUrl: './popover-address.component.html',
	styleUrls: ['./popover-address.component.scss'],
	providers: [UnMaskedInputComponent],
})
export class PopoverAddressComponent extends BasePageComponent implements OnInit {
	@Output() onAddressSelect: EventEmitter<any> = new EventEmitter();
	@Output() close: EventEmitter<any> = new EventEmitter();

	@Input() addresses: ISingleAddressClient[];
	@Input() changeTitle: boolean;
	@Input() isOpen: boolean;
	@Input() suggestedOnInit: boolean;

	constructor(injector: Injector, private unmaskedInput: UnMaskedInputComponent, protected store: Store<IAppState>) {
		super(injector, store);
	}

	ngOnInit() {
		// this.isOpen = false;
	}

	isEmpty(obj) {
		return isEmpty(obj);
	}

	getTitle() {
		if (this.changeTitle) return 'Is this your address?';
		else return 'Is This Your Address?';
	}

	onSelect(address: any) {
		// document
		//   .getElementsByClassName("address-line1-input")[0]
		//   .removeEventListener("blur", () => {});
		this.close.emit();
		this.onAddressSelect.emit({ address });
		document.getElementsByClassName('address-line1-input')[0].addEventListener('blur', this.unmaskedInput.onBlur);
	}

	onClose() {
		this.close.emit();
		//this.addresses = Object.assign({}, this.addresses);
	}

	eventHandler(event, address: any) {
		// if "Enter" key pressed
		if (event.keyCode === 13) {
			this.onSelect(address);
		}
	}

	closePopOver(event) {
		// if "Enter" or "Space" pressed
		if (event.keyCode === 13 || event.keyCode === 32) {
			this.onClose();
		}
	}
}
