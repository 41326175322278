import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { IAppState } from 'src/app/store/states/app.state';
import { IProgressMeter } from '../../../store/states/progress-meter.state';
import { getDeviceType, isMobile, TABLET } from 'src/app/utils/general.utils';
import { RoutingService } from 'src/app/services/routing.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { StepsEnum } from '../../../enums/steps.enum';
import { EventActions } from '../../../models/event-data.model';
import { selectSecretSource, selectSource } from 'src/app/store/selectors/result-data.selectors';
import {
	selectActiveTheme,
	selectAgentPhoneNumber,
	selectAlternativeLogo,
} from '../../../store/selectors/active-theme.selector';
import { NavigateInternalStep } from '../../../store/actions/routing.actions';
import { GeneralPropsEnum } from '../../../models/general-props.enum';
import { PhoneVisibilityEnum } from '../../../enums/phone-visibility.enum';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { LobService } from 'src/app/services/lob.service';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { selectLanguageSettings } from '../../../store/selectors/languages.selector';
import { IDropdownOption } from '../../form-controls/form-control-interfaces';
import { LanguagesModel } from '../../../models/languages.model';
import { LanguagesActions } from '../../../store/actions';
import { NavigationEnd } from '../../../../../node_modules/@angular/router';
import { ThemeModel } from '../../../models/theme.model';
import { showPopupAction } from '../../../store/actions/popup.actions';
import { IStep } from 'src/app/models/step-groups.model';

@UntilDestroy()
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@Input() pm: IProgressMeter;

	isTablet: boolean;
	phoneNumber: string;
	StepsEnum = StepsEnum;
	eventActions = EventActions;
	source: string;
	secretSource: string;
	logoUrl: string;
	isAddLogoVisible: boolean;
	isMainLogoVisible = true;
	activeStep: IStep;
	params: any;
	languageOptions: IDropdownOption[];
	languageForm: FormGroup;
	theme: ThemeModel;
	alternativeLogo: string;
	isMobile = isMobile();

	constructor(
		private store: Store<IAppState>,
		public routingService: RoutingService,
		private progressMeterService: ProgressMeterService,
		private route: ActivatedRoute,
		private router: Router,
		private lobService: LobService
	) {
		this.isTablet = getDeviceType() === TABLET;
	}

	get isBackBtnVisible(): boolean {
		return this.pm.activeStep > 0 && !this.checkIsPage([StepsEnum.SUCCESS_PAGE]) && !this.isConfirmationEmailSentPage();
	}

	get isFindQuoteIcon(): boolean {
		return !!this.theme.images?.findQuote;
	}

	get isFirstPage() {
		return window.location.pathname.includes(StepsEnum.YOUR_ADDRESS);
	}

	get isPhoneVisible(): boolean {
		return (
			this.theme.agentPhoneNumberVisibility === PhoneVisibilityEnum.allPages ||
			this.pm.flowOptions[this.pm.flowType][this.pm.activeStep].stepProperties?.phoneVisible ||
			this.isTechicalErrorPage() ||
			this.isPaymentSucessPage() ||
			(this.pm.activeStep > 0 && this.theme.agentPhoneNumberVisibility === PhoneVisibilityEnum.fromSecond)
		);
	}

	ngOnInit(): void {
		combineLatest([
			this.store.select(selectSource),
			this.store.select(selectSecretSource),
			this.store.select(selectAgentPhoneNumber),
			this.store.select(selectActiveTheme),
			this.store.select(selectLanguageSettings),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([source, secretSource, phoneNumber, theme, langSettings]) => {
				this.theme = theme;
				this.activeStep = this.pm.flowOptions[this.pm.flowType][this.pm.activeStep];
				this.source = source;
				this.secretSource = secretSource;
				this.phoneNumber = phoneNumber;
				this.logoUrl = theme[GeneralPropsEnum.logoUrl];

				this.setLogosVisibility();
				this.initAddLogoVisibilitySwitch();
				this.initLanguageDropdown(langSettings);
			});

		this.route.queryParams.subscribe((params) => {
			this.params = params;
		});

		this.store
			.select(selectAlternativeLogo)
			.pipe(untilDestroyed(this))
			.subscribe((logo) => {
				this.alternativeLogo = logo;
			});
	}

	initAddLogoVisibilitySwitch(): void {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				untilDestroyed(this)
			)
			.subscribe(() => {
				this.setLogosVisibility();
			});
	}

	setLogosVisibility() {
		if (!!this.theme.images.additionalLogo) {
			if (this.theme.additionalClasses.mainLogoVisibility === 'main-logo-show-only-on-lp') {
				this.isMainLogoVisible = this.checkIsPage([StepsEnum.YOUR_ADDRESS]);
			}

			if (this.theme.additionalClasses.addLogoVisibility === 'add-logo-hide-on-lp') {
				this.isAddLogoVisible = !this.checkIsPage([StepsEnum.YOUR_ADDRESS]);
			} else if (this.theme.additionalClasses.addLogoVisibility === 'add-logo-all-pages') {
				this.isAddLogoVisible = true;
			}
		}
	}

	showHeader(): boolean {
		return !this.checkIsPage([StepsEnum.PAGE_NOT_FOUND]);
	}

	onBackButtonClick() {
		if (
			this.routingService.currentRoute.includes(StepsEnum.PAYMENT_FAILED) ||
			this.routingService.currentRoute.includes(StepsEnum.CALL_AGENT)
		) {
			combineLatest([this.store.select(selectInterviewMetadata), this.lobService.getLob$()])
				.pipe(first())
				.subscribe(([interviewMetadata, lob]) => {
					if (interviewMetadata.isMultiLob) {
						lob = LobsEnum.HOME_AUTO;
					}

					if (this.routingService.currentRoute.includes(StepsEnum.PAYMENT_FAILED)) {
						this.routingService.navigateToRoute(lob + '/' + StepsEnum.PAYMENT_PLAN);
					}

					if (this.routingService.currentRoute.includes(StepsEnum.CALL_AGENT)) {
						this.routingService.navigateToRoute(this.progressMeterService.getRouteName(lob) + '/' + StepsEnum.RESULT);
					}
				});
		} else if (this.routingService.currentRoute.includes(StepsEnum.SUCCESS_PAGE)) {
			if (this.secretSource) {
				this.routingService.navigateToRoute(StepsEnum.YOUR_ADDRESS);
			} else {
				this.routingService.navigateToRoute(StepsEnum.YOUR_ADDRESS);
			}
		} else if (this.routingService.currentRoute.includes(StepsEnum.STATE_NOT_ALLOWED)) {
			window.history.go(-1);
			return;
		} else if (
			// if pet form, dispatch internal navigation action (handled in AddEditPetComponent)
			this.routingService.currentRoute.includes(StepsEnum.PET_DETAILS) &&
			this.routingService.currentRoute.includes('step') &&
			this.params.step !== '1'
		) {
			this.store.dispatch(NavigateInternalStep({ forward: false }));
		} else {
			this.progressMeterService.goToPreviousPage();
		}
	}

	backToHomepage() {
		if (!this.logoUrl.length) {
			return;
		}

		if (this.logoUrl === '/') {
			this.routingService.navigateToHomePage();
		} else {
			this.routingService.navigateToExternal(this.logoUrl);
		}
	}

	openPhonePopup() {
		this.store.dispatch(
			showPopupAction({
				componentName: 'phone',
				payload: {
					text: ``,
					data: { phoneNumber: this.phoneNumber },
					customClass: 'phone-popup',
					gtmLabel: 'CoveragePopUp',
				},
			})
		);
	}

	private isConfirmationEmailSentPage(): boolean {
		return this.checkIsPage([StepsEnum.CONFIRMATION_EMAIL_SENT]);
	}

	private isTechicalErrorPage(): boolean {
		return this.checkIsPage([StepsEnum.ERROR, StepsEnum.TECHNICAL_ERROR]);
	}

	private isPaymentSucessPage(): boolean {
		return this.checkIsPage([StepsEnum.SUCCESS_PAGE]);
	}

	private checkIsPage(steps: StepsEnum[]): boolean {
		return this.router.url.includes(steps.join('/'));
	}

	private initLanguageDropdown(langSettings: LanguagesModel) {
		if (langSettings.list && langSettings.list.length > 1) {
			this.languageOptions = langSettings.list.map((option, index) => ({
				id: index,
				value: option.code,
				title: option.code.toUpperCase(),
			}));

			this.languageForm = new FormGroup({
				languageControl: new FormControl(langSettings.selected),
			});

			this.languageForm.controls.languageControl.valueChanges
				.pipe(untilDestroyed(this))
				.subscribe((value) => this.reloadOnLanguageChange(value));
		}
	}

	private reloadOnLanguageChange(value: string) {
		this.store.dispatch(LanguagesActions.SetLanguage({ selected: value }));
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: {},
			queryParamsHandling: 'merge',
		});
	}
}
