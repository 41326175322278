import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { popups } from '.';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlModule } from '../../form-controls/form-control.module';
import { RequestCallbackComponent } from './request-callback/request-callback.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { ThankYouCallbackComponent } from './thank-you-callback/thank-you-callback.component';
import { AllCoveragesComponent } from './all-coverages/all-coverages.component';
import { ContactAgentComponent } from './contact-agent/contact-agent.component';
import { DirectivesModule } from '../../../directives/directives.module';
import { PaymentPopupComponent } from './payment/payment.component';
import { AlertComponent } from './alert/alert.component';
import { QuestionWrapperModule } from '../question-wrapper/question-wrapper.module';
import { HomsiteAgreementComponent } from './homesite-agreement/homesite-agreement.component';
import { NextButtonModule } from '../../next-button/next-button.module';
import { ImageModule } from '../../shared/image/image.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DriverDiscoveredPopupComponent } from './driver-discovered-popup/driver-discovered-popup.component';
import { MatIconModule } from '@angular/material/icon';
import { AdditionalCoveragesComponent } from './additional-coverages/additional-coverages.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AltCarriersComponent } from './alt-carriers/alt-carriers.component';
import { OemSelectionComponent } from './oem-selection/oem-selection.component';
import { NonOemSelectionComponent } from './non-oem-selection/non-oem-selection.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormatCoverageNamePipe } from 'src/app/pipes/format-coverage-name';
import { PhoneComponent } from './phone/phone.component';
import { DriverPopupComponent } from './driver-popup/driver-popup.component';
import { ToggledCoverageComponent } from '../../shared/toggled-coverage/toggled-coverage.component';
import { DiscountsComponent } from '../../shared/discounts/discounts.component';

@NgModule({
	declarations: [
		popups,
		RequestCallbackComponent,
		ThankYouCallbackComponent,
		AllCoveragesComponent,
		AdditionalCoveragesComponent,
		ContactAgentComponent,
		PaymentPopupComponent,
		AlertComponent,
		HomsiteAgreementComponent,
		DriverDiscoveredPopupComponent,
		AltCarriersComponent,
		OemSelectionComponent,
		NonOemSelectionComponent,
		FormatCoverageNamePipe,
		PhoneComponent,
		DriverPopupComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FormControlModule,
		MatDatepickerModule,
		MatCardModule,
		DirectivesModule,
		QuestionWrapperModule,
		NextButtonModule,
		ImageModule,
		MatTooltipModule,
		MatIconModule,
		MatSlideToggleModule,
		TranslateModule,
		ToggledCoverageComponent,
		DiscountsComponent,
	],
	exports: [popups],
})
export class PopupModule { }
