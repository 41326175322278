<section class="main-content">
	<div class="container-success">
		<app-image class="payment-success-image" name="paymentSuccess" role="presentation"></app-image>

		<app-page-heading
			[subtitleKey]="isEscrowFlow ? 'payment-success-escrow.subTitle' : 'payment-success.subTitle'"
			[titleKey]="isEscrowFlow ? 'payment-success-escrow.title' : 'payment-success.title'"
		></app-page-heading>

		<p *ngIf="message" class="message">
			{{ message }}
		</p>

		<div class="policy-info">
			<div>
				<app-image class="icon" name="policyNumber" role="presentation"></app-image>
				<span class="text">{{ "labels.policyNumber" | translate | uppercase }}</span>
				<span class="data">{{ policyNumber }}</span>
			</div>

			<div>
				<app-image class="icon" name="policyEffectiveDate" role="presentation"></app-image>
				<span class="text">{{ "labels.policyStartDate" | translate | uppercase }}</span>
				<span class="data">{{ policyStartDate }}</span>
			</div>
		</div>

		<!--      TODO: uncomment after implementing download pdf functionality-->
		<!--		<button class="app-button" (click)="downloadPDFPolicy()">-->
		<!--			<span class="title">Download PDF policy<i appHiddenWhenCssDisabled class="material-icons"  role="presentation">chevron_right</i></span>-->
		<!--		</button>-->
		<button (click)="goHomeClick()" *ngIf="backButtonOn" class="app-button go-home-button" tabindex="0">
			<span class="title">
				<span appTranslation="payment-success.backButtonText"></span>
				<i appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
			</span>
		</button>
	</div>
</section>
