<div
	[formGroup]="form"
	[ngClass]="{
		'additional-questions-page': isAdditionalQuestionsPage,
		'error-vehicle': !form.valid && form?.value?.enabled && !isAdditionalQuestionsPage
	}"
	class="accordion-block vehicle-accordion"
	data-status="open"
>
	<div class="accordion-top">
		<img
			*ngIf="form?.value?.PLMake"
			[class.car-disabled]="!form?.value?.enabled"
			alt="{{ form?.value?.PLMake }} logo"
			class="car-logo"
			src="assets/images/car-logos/thumb/{{ getVehicleLogoName(form?.value?.PLMake) }}.png"
		/>

		<div class="accordion-title-wrapper">
			<div *ngIf="title" class="car-name">
				{{ title }}
			</div>
		</div>

		<div class="accordion-actions-wrapper">
			<ng-container *ngIf="!form.valid && form?.value?.enabled && !isAdditionalQuestionsPage">
				<img
					alt="null"
					class="error-vehicle-mark"
					height="20"
					role="presentation"
					src="assets/images/error-driver.svg"
				/>
			</ng-container>
			<ng-container *ngIf="!form.value.primary">
				<!--        button is not displayed until the vehicle was toggled ON and opened for the first time, therefore vehicleOpened is always true-->
				<button
					(click)="handleEdit(true)"
					*ngIf="form?.value?.enabled && !isAdditionalQuestionsPage"
					class="edit"
					type="button"
				>
					<span class="edit-text">{{ "titles.editDetails" | translate }}</span>
					<i
						[ngClass]="{ error: !form.valid }"
						appHiddenWhenCssDisabled
						class="material-icons edit"
						role="presentation"
					>
						edit
					</i>
				</button>
				<ng-container *ngIf="!isAdditionalQuestionsPage">
					<mat-slide-toggle
						(change)="toggle($event)"
						[checked]="form.value.enabled"
						[disabled]="!form?.value?.enabled && cantAdd"
						aria-label="test"
						class="example-margin"
						hideIcon="true"
						[disableRipple]="true"
						>{{ "labels.toggleVehicle" | translate }}
					</mat-slide-toggle>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="form.value.primary">
				<button
					(click)="handleEditPrimary(form?.value)"
					*ngIf="form?.value?.enabled && !isAdditionalQuestionsPage"
					class="edit"
					type="button"
				>
					<span class="edit-text">{{ "titles.editDetails" | translate }}</span>
					<i
						[ngClass]="{ error: !form.valid }"
						appHiddenWhenCssDisabled
						class="material-icons edit"
						role="presentation"
					>
						edit
					</i>
				</button>
				{{ "labels.primary" | translate }}
			</ng-container>
		</div>
	</div>
</div>
