import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { selectFriendlyId } from 'src/app/store/selectors/interview-metadata.selector';
import { LoaderActions } from '../../../../../store/actions';

@UntilDestroy()
@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() titleKey: string;
	@Input() subtitleKey: string;
	@Input() showFriendlyId: boolean;
	@Input() imageSrc: string;
	@Input() imageName: string;
	@Input() imageAutoDimension: boolean;

	friendlyId?: string;

	constructor(private readonly store: Store) {}

	ngOnInit(): void {
		this.store.dispatch(LoaderActions.Hideloader());

		if (!this.showFriendlyId) {
			return;
		}

		this.store
			.select(selectFriendlyId)
			.pipe(untilDestroyed(this))
			.subscribe((friendlyId: string) => (this.friendlyId = friendlyId));
	}
}
