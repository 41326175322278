import { NgModule } from '@angular/core';
import { ErrorMessageComponent } from './error-message.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [ErrorMessageComponent],
	imports: [CommonModule, TranslateModule],
	exports: [ErrorMessageComponent],
})
export class ErrorMessageModule { }
