<div class="main">
	<div class="content">
		<div class="top">
			<ng-container *ngFor="let rate of selectedRates">
				<div class="carrier">
					<img [attr.alt]="rate.carrier + ' logo'" [src]="getLogo(rate)" role="presentation" />

					<div class="sr-only">{{ rate.carrier }}</div>
				</div>
			</ng-container>
		</div>

		<div class="details-carrier">
			<div class="wrapper-first-row">
				<div class="first-row">
					<div class="first-column">
						<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">policy</i>
						<div class="name">
							<b>{{ "labels.policySummary" | translate }}</b>
						</div>
					</div>

					<div class="details-policy-summary">
						{{ lobTitle | translate | titlecase }} {{ "labels.insuranceBy" | translate }} {{ data.carrier }}
					</div>
				</div>
				<button (click)="openAllCoveragesPopup()" class="button">
					{{ "labels.viewDetails" | translate }}
				</button>
			</div>

			<div class="row-border"></div>


			<div class="wrapper-first-row">
				<div class="first-row">
					<div class="first-column">
						<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">verified_user</i>
						<div class="name">
							<b>{{ "labels.lineOfBusiness" | translate }}</b>
						</div>
					</div>

					<div class="details-policy-summary">
						{{ lobTitle | translate | titlecase }}
					</div>
				</div>
				
			</div>

			<ng-container *ngIf="lob == lobsEnum.PERSONAL_AUTO || isEscrow">
				<div class="row-border"></div>

				<div class="wrapper-first-row">
					<div class="wrapper-first-row">
						<div class="first-row">
							<div class="first-column">
								<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">
									calendar_month
								</i>
								<div class="name">
									<b>{{ "labels.policyEffectiveDate" | translate }}</b>
								</div>
							</div>
							<div class="details-policy-summary">{{ this.formatDate(policyStartDate) }}</div>
						</div>
					</div>
				</div>
			</ng-container>

			<div class="row-border"></div>

			<div class="wrapper-first-row">
				<div class="wrapper-first-row">
					<div class="first-row">
						<div class="first-column">
							<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">
								description
							</i>
							<div class="name">
								<b>{{ "labels.policyTerm" | translate }}</b>
							</div>
						</div>
						<div class="details-policy-summary">{{ getTermNumber(data.term) }} {{ "labels.months" | translate }}</div>
					</div>
				</div>
			</div>

			<div class="row-border"></div>

			<div class="wrapper-first-row">
				<div class="first-row">
					<div class="first-column">
						<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">payments</i>
						<div class="name">
							<b>{{ "labels.paymentPlan" | translate }}</b>
						</div>
					</div>

					<div class="details-payment-plan">
						<p>{{ paymentText }}</p>

						<div class="body">
							<div *ngIf="!isEscrow" class="paynow">
								<span>{{ "labels.payNow" | translate }}</span>
								<span class="price">{{ selectedPaymentPlan.firstPaymentAmount | zeroOrTwoDigitsFraction }}</span>
							</div>
							<div class="totalamount">
								<span>{{ "labels.totalAmount" | translate }}</span>
								<span class="price">{{ selectedPaymentPlan.totalAmount | zeroOrTwoDigitsFraction }}</span>
							</div>
						</div>
					</div>
				</div>
				<button (click)="handleChangeClick()" *ngIf="!isEscrow" class="button">
					{{ "labels.change" | translate }}
				</button>
			</div>

			<div class="row-border"></div>

			<div class="wrapper-first-row">
				<div class="first-row">
					<div class="first-column">
						<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">person</i>
						<div class="name">
							<b>{{ "labels.policyHolder" | translate }}</b>
						</div>
					</div>

					<div class="details-policy-holder">
						{{ quoteData.FirstName }} {{ quoteData.LastName }}
						<br />
						{{ quoteData.PropertyAddress.AddressLine1 }}, {{ quoteData.PropertyAddress.City }},
						{{ quoteData.PropertyAddress.State }} {{ quoteData.PropertyAddress.ZipCode }}
						<br />
						{{ quoteData.PrimaryPhoneNumber | phone }}
					</div>
				</div>
			</div>
		</div>

		<div class="bottom">
			<button (click)="handleClick()" class="app-button" tabindex="0">
				<span class="title">
					<span appTranslation="{{ isEscrow ? 'summary.buttonEscrow' : 'summary.button' }}"></span>
					<i appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
				</span>
			</button>
		</div>
	</div>
</div>
